import CheckBox from 'devextreme/ui/check_box';
import * as events from 'devextreme/events';
import dxDataGrid from 'devextreme/ui/data_grid';

import { NotificationHandler } from '@app/components/ToastNotification';
import { ColumnFilterValue, DataGridColumnFieldType, GridState, IGridFilter } from '@app/components/DxDataGrid/DxDataGridTypes';

export default class DxGridHelper {
    public static applyFiltersToState(state: GridState, filters: IGridFilter[]) {
        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            const colIndex = state.columns.findIndex((c) => c.dataField === filter.field);
            if (colIndex === -1) continue;

            if (filter.fieldType === DataGridColumnFieldType.number) {
                const filterValues: ColumnFilterValue[] = [];
                filter.values.forEach((v) => {
                    const newFilterValue = [[filter.field, v.operation, v.value.toString()]] as ColumnFilterValue;
                    newFilterValue.columnIndex = colIndex;
                    filterValues.push(newFilterValue);
                });

                state.columns[colIndex].filterValues = filterValues;
            }

            if (filter.fieldType === DataGridColumnFieldType.string) {
                state.columns[colIndex].filterValues = filter.values.map((x) => x.value.toString());
            }

            state.columns[colIndex].filterType = filter.filterType;
        }
    }

    public static replaceSelectAllClickHandler(cellElement: Element, getGridInstance: () => dxDataGrid | undefined) {
        const instance = CheckBox.getInstance(cellElement.querySelector('.dx-select-checkbox') as Element);
        instance.option('onValueChanged', null);

        events.on(cellElement, 'dxclick', (clickEvent: Event) => {
            clickEvent.preventDefault();
            clickEvent.stopPropagation();

            const grid = getGridInstance();
            if (grid) {
                const dataSource = grid.getDataSource();
                const rowKey = dataSource.key() as string;
                const currentPageItems = dataSource.items();
                
                const totalCount = grid.totalCount();

                const selectedKeys = grid.getSelectedRowKeys();
                const isDeselect = selectedKeys.length > 0;

                if (currentPageItems.length < totalCount && !isDeselect) {
                    NotificationHandler.showWarning(`Only ${currentPageItems.length} items out of ${totalCount} were selected`);
                }
                
                if (isDeselect) {
                    void grid.deselectRows(selectedKeys);
                } else {
                    const currentPageKeys = rowKey ? currentPageItems.map((item) => item[rowKey]) : currentPageItems;
                    void grid.selectRows(currentPageKeys, true);
                }
            }
        });
    }
}
