import { FC, useState } from 'react';
import cls from './_list.module.scss'
import { TextControl } from '../UI/FormControls';

export type ListItem = {
    id: string,
    label: string
}
type ListProps = {
    items: ListItem[]
    onSelect?: (itemId: string) => void
}

export const List: FC<ListProps> = ({ items, onSelect }) => {
    const [search, setSearch] = useState<string>('')
    const [selected, setSelected] = useState<string>('')

    const _onSearch = (val: string) => {
        setSearch(val)
    }

    const onClick = (selectedId: string) => () => {
        setSelected(selectedId);
        onSelect && onSelect(selectedId)
    }

    const renderItem = (item: ListItem) => {
        return (
            <div className={`${cls.item} ${selected === item.id ? cls.active : ''}`} 
                 key={item.id}
                 onClick={onClick(item.id)}>
                {item.label}
            </div>
        )
    }
    return (
        <>
            <div className={cls.filter}>
                <TextControl
                    showIcon={true}
                    label="Search"
                    value={search}
                    onValueChange={_onSearch} />
            </div>
            <div className={cls.list}>
                {items.filter(i=> search ? i.label.toLowerCase().indexOf(search.toLowerCase()) !== -1 : true).map(e => renderItem(e))}
            </div>
        </>
    )
}
