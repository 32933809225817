import { action, makeAutoObservable, observable } from 'mobx';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';

export type GlobalProgressData = {
    total: number;
    current: number;
    title: string;
};

export class LoaderStore {
    @observable public globalProgress: GlobalProgressData | null = null;
    public globalLoader: PromiseCompletion = new PromiseCompletion();

    public appLoaderElement: HTMLElement | null = null;
    appLoader: PromiseCompletion = new PromiseCompletion();
    bodyLoader: PromiseCompletion = new PromiseCompletion();
    processLoader: PromiseCompletion = new PromiseCompletion();

    constructor () {
        makeAutoObservable(this);
    }

    @action
    public hideAppLoader () {
        const loaderNode = this.appLoaderElement;
        if (!loaderNode) return;
        if (!loaderNode.classList.contains('hide')) {
            loaderNode.classList.add('hide');
        }
    }

    @action
    public showAppLoader () {
        const loaderNode = this.appLoaderElement;
        if (!loaderNode) return;

        loaderNode.classList.remove('invisible');
        window.setTimeout(() => {
            loaderNode.classList.remove('hide');
        }, 0);
    }
}

export const loaderStore = new LoaderStore();