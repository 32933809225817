import React from 'react';
import { faMinus, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import ApiService from '@app/services/ApiService';
import { AirportDto, FlightDto, ProductDto, ProductsFilter } from '@app/models/WebApiModels';
import { BasketDTO } from '@app/models/AppModels';
import { ApiUrls } from '@app/AppConstants';
import { applyDecorators } from '@app/helpers/Decorator';
import { modalService } from '../../../../components/Modal/Modal';
import ConnexFlightDialog, { ConnexFlightDialogProps } from '../../../../components/Dialogs/ConnexFlight/ConnexFlightDialog';

import cls from './_body.module.scss';
import { NumberControl } from '../../../../components/UI/FormControls';
import { Button } from 'reactstrap';

type BodyProps = {
    airport: AirportDto;
    basket: BasketDTO[];
    onBasketChange: (basket: BasketDTO[]) => void;
    onConnexDestinationChange: (connexDestination?: string) => void;
    flight: FlightDto;
};

@observer
export default class Body extends React.Component<BodyProps, {}> {
    @observable
    private _products: ProductDto[] = [];

    @observable
    private _airports: AirportDto[] = [];

    @observable
    private _connexFlightDestination?: string;

    constructor(props: BodyProps) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount(): void {
        void this._loadData();
    }

    componentDidUpdate(prevProps: Readonly<BodyProps>): void {
        if (this.props.flight.id != prevProps.flight.id) void this._loadData();
    }

    render() {
        return (
            <>
                <div className={cls.root}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <div className={cls.row} style={{ justifyContent: 'center' }}>
                            <Button
                                style={{ width: '50%' }}
                                disabled={!this._connexFlightDestination}
                                color="danger"
                                onClick={() => {
                                    void this._onPtpClick();
                                }}
                            >
                                Point to Point Flight
                            </Button>
                        </div>
                        <div className={cls.row} style={{ justifyContent: 'center' }}>
                            <Button
                                style={{ width: '50%' }}
                                disabled={!!this._connexFlightDestination}
                                color="danger"
                                onClick={() => {
                                    void this._onConnexFlightClick();
                                }}
                            >
                                Connex Flight {this._connexFlightDestination ? `(${this._connexFlightDestination})` : ''}
                            </Button>
                        </div>
                    </div>
                    <div className={cls.row}>
                        <div className={cls.col}>{this._renderProducts('L1')}</div>
                        <div className={cls.col}>{this._renderProducts('L2')}</div>
                    </div>
                </div>
            </>
        );
    }

    private _renderProducts(regExp: string) {
        const _getPosition = (pos: string): number => +pos.split('|')[0].split(':')[1];
        return this._products
            .filter((e) => e.sortOrder)
            .filter((e) => new RegExp(`(${regExp})`).test(e.sortOrder!))
            .sort((a, b) => _getPosition(a.sortOrder!) - _getPosition(b.sortOrder!))
            .filter((e) => !this._connexFlightDestination || e.availableOnConnex)
            .map((s) => this._renderProduct(s));
    }

    @action.bound
    private _renderProduct(product: ProductDto) {
        const isInBasket = this.props.basket.findIndex((e) => e.service.code === product.code) !== -1;

        return (
            <div key={product.code} className={cls.item}>
                <div className={cls.text}>
                    <div className={cls.prev} data-icon={product.code?.toLowerCase()}></div>
                    <div className={cls.name}>{product.displayName}</div>
                    <div className={cls.price}>
                        {product.currency?.toUpperCase()} {product.price.toFixed(2)}
                    </div>
                </div>
                <div className={cls.controls}>
                    {isInBasket ? (
                        <>
                            <div className={cls.action} onClick={() => this._onRemoveClick(product)}>
                                <FontAwesomeIcon className={cls.icon} icon={faMinus} />
                            </div>
                            <div className={cls.action}>
                                <NumberControl onValueChange={(value) => this._onCountChange(value, product)} value={this.props.basket.find((e) => e.service.code === product.code)?.count || 0} className={cls.field} disabled />
                            </div>
                            <div className={cls.action} onClick={() => this._onAddClick(product)}>
                                <FontAwesomeIcon className={cls.icon} icon={faPlus} />
                            </div>
                        </>
                    ) : (
                        <div className={cls.new} onClick={() => this._onAddClick(product)}>
                            Add <FontAwesomeIcon className={cls.icon} icon={faPlusCircle} />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    @action.bound
    private async _loadData() {
        const { data: products } = await ApiService.postTypedData<ProductDto[]>(ApiUrls.ProductUrl, {
            connexDestination: this._connexFlightDestination,
            flightId: this.props.flight.id
        } as ProductsFilter);
        this._products = products;

        const { data: airports } = await ApiService.getTypedData<AirportDto[]>(ApiUrls.AirportUrl + '?withFlightsOnly=false', null);
        this._airports = airports;
    }

    @action.bound
    private _onCountChange(count: number, service: ProductDto) {
        const newBasket = [...this.props.basket];
        const index = newBasket.findIndex((e) => e.service.code === service.code);
        if (index === -1) {
            newBasket.push({ count: count, service: service });
        } else {
            newBasket[index].count = count;
        }
        this.props.onBasketChange(newBasket);
    }

    @action.bound
    private _onAddClick(service: ProductDto) {
        const newBasket = [...this.props.basket];
        const index = newBasket.findIndex((e) => e.service.code === service.code);
        if (index === -1) {
            newBasket.push({ count: 1, service: service });
        } else {
            newBasket[index].count = newBasket[index].count === 9 ? 9 : ++newBasket[index].count;
        }
        this.props.onBasketChange(newBasket);
    }

    @action.bound
    private _onRemoveClick(service: ProductDto) {
        let newBasket = [...this.props.basket];
        const index = newBasket.findIndex((e) => e.service.code === service.code);
        if (index !== -1) {
            if (newBasket[index].count <= 1) {
                newBasket = newBasket.filter((e) => e.service.code !== service.code);
            } else {
                newBasket[index].count = --newBasket[index].count;
            }
        }
        this.props.onBasketChange(newBasket);
    }

    @action.bound
    private async _onPtpClick() {
        this._connexFlightDestination = undefined;
        this.props.onConnexDestinationChange(this._connexFlightDestination);
        await this._loadData();
    }

    @action.bound
    private async _onConnexFlightClick() {
        const connexFlightResult = await modalService.show<ConnexFlightDialogProps, AirportDto>(ConnexFlightDialog, {
            airports: this._airports
        });

        if (connexFlightResult.result) {
            this._connexFlightDestination = connexFlightResult.result.code;
            this.props.onConnexDestinationChange(this._connexFlightDestination);

            await this._loadData();
        }
    }
}
