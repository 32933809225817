import React from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Col, Container, FormGroup } from 'reactstrap';
import { DataGrid } from 'devextreme-react';
import CustomStore, { Options } from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { CellInfo, DataGridColumnFieldType, DataGridSelectionMode, OnRowClickArgs, OnRowPreparedArgs } from '@app/components/DxDataGrid/DxDataGridTypes';
import { DataGridColumn } from '@app/components/DxDataGrid/DxDataGridColumn';
import { EmailAttachmentDto, EmailDto, MailQueueStatus } from '@app/models/WebApiModels';
import { EmailPreview } from '@app/components/EmailPreview/EmailPreview';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { Report } from '@app/components/Report';
import DxDataGrid from '@app/components/DxDataGrid/DxDataGrid';
import { ApiUrls } from '@app/AppConstants';
import { BaseFormModel } from '@app/components/BaseFormModel';
import { DatePickerValidatedControl } from '@app/components/DateTimeControls/DateTimeControls';
import { EmptyProps, applyDecorators } from '@app/helpers/Decorator';
import { Icon } from '@app/components/Icon';
import { displayName, isDateTime, isDateTimeAfter, isDateTimeBefore, isRequired } from '@app/appConstants/Validation';

import ApiService from '@app/services/ApiService';
import DateTimeService from '@app/services/DateTimeService';

import './_email-viewer.scss';

const REPORT_TITLE = 'Email Viewer';

class EmailGroupingModel extends EmailDto {
    groupDate?: Date;
}

class FormModel extends BaseFormModel {
    @observable
    @displayName('Period From')
    @isRequired()
    @isDateTime()
    @isDateTimeBefore('periodTo')
    periodFrom: string = '';

    @observable
    @displayName('Period To')
    @isDateTime()
    @isDateTimeAfter('periodFrom')
    periodTo: string = '';

    @computed
    get parsedPeriodFrom() {
        return this.periodFrom ? DateTimeService.parseUiDateTime(this.periodFrom) : null;
    }

    @computed
    get parsedPeriodTo() {
        return this.periodTo ? DateTimeService.parseUiDateTime(this.periodTo) : null;
    }

    constructor() {
        super();
        applyDecorators(this);
        const now = DateTimeService.now();
        this.setPeriodFromDateTime(DateTimeService.addDays(now, -5));
        this.setPeriodToDateTime(now);
    }

    public setPeriodFromDateTime(date: Date) {
        this.periodFrom = DateTimeService.toUiDateTime(date);
    }

    public setPeriodToDateTime(date: Date) {
        this.periodTo = DateTimeService.toUiDateTime(date);
    }
}

@observer
export default class EmailViewer extends React.Component {
    @observable private _selectedEmail?: EmailDto | null;
    @observable private _selectedEmailBody?: string;
    @observable private _selectedEmailAttachments?: EmailAttachmentDto[] | null;

    @observable private _form: FormModel;
    @observable.ref private _gridRef: React.RefObject<DataGrid> = React.createRef();
    private _dataSource: DataSource;
    private _loader: PromiseCompletion = new PromiseCompletion();

    constructor(props: EmptyProps) {
        super(props);
        applyDecorators(this);
        this._form = new FormModel();

        const storeOptions: Options<EmailGroupingModel, string> = {
            key: 'id',
            load: this._loadData
        };
        const customStore = new CustomStore<EmailGroupingModel, string>(storeOptions);
        this._dataSource = new DataSource({ store: customStore });
    }

    render() {
        return (
            <Report title={REPORT_TITLE}>
                <div className="d-flex justify-content-between mt-4">
                    <div style={{ width: '430px', gap: '12px' }} className="d-flex">
                        <FormGroup>
                            <Col>
                                <div className="label-title">Period from</div>
                                <DatePickerValidatedControl name="periodFrom" formModel={this._form} pickerMode="datetime" pickerSize="sm" onChange={this._onAnyFieldChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col>
                                <div className="label-title">Period to</div>
                                <DatePickerValidatedControl name="periodTo" formModel={this._form} pickerMode="datetime" pickerSize="sm" onChange={this._onAnyFieldChange} />
                            </Col>
                        </FormGroup>
                    </div>
                </div>
                <div className="d-flex">
                    <div>
                        <Container className="emails-container">
                            <DxDataGrid
                                dataGridRef={this._gridRef}
                                columns={this._columns}
                                dataSource={this._dataSource}
                                onRowClick={this._onRowClick}
                                onRowPrepared={this._addCssClassToRow}
                                isPrintMode={false}
                                selectionMode={DataGridSelectionMode.single}
                                highlightFocusedRow
                                repaintChangesOnly
                                highlightChanges
                                enableHoverState
                                disableGrouping
                                disableVirtualization
                                disableColumnChooser
                                disableExporting
                                disablePrinting
                                disableSorting
                                customToolBarRender={this._renderRefreshButton}
                            />
                        </Container>
                    </div>
                    <div className="d-flex flex-column" style={{ gap: '10px' }}>
                        {this._selectedEmail && <EmailPreview 
                            from={this._selectedEmail.from ?? ''}
                            to={this._selectedEmail.to}
                            body={this._selectedEmailBody ?? ''} 
                            subject={this._selectedEmail.subject} 
                            cc={this._selectedEmail.cc} 
                            sentDate={this._selectedEmail.sentDate} 
                            createdDate={this._selectedEmail.createdDate}
                            attachments={this._selectedEmailAttachments} 
                            status={this._selectedEmail.status}
                            errorMessage={this._selectedEmail.errorMessage}
                            loader={this._loader}
                        />}
                    </div>
                </div>
            </Report>
        );
    }

    private _renderGroupingDate = (e: HTMLElement, t: CellInfo<EmailDto>) => {
        const value = t.value ? DateTimeService.toUiDate(t.value as Date) : undefined;
        value && e.appendChild(document.createTextNode(value));
    };

    private _renderEmailSubject = (e: CellInfo<EmailDto>) => {
        if (!e.data?.subject) return null;

        const { subject, to, hasAttachments, sentDate } = e.data;

        const emailsTo = to?.split(';') ?? [];

        return (
            <div className="email-subject-cell">
                <div className="email-subject-cell-info d-flex">
                    <div className="email-subject-cell-title">
                        {subject}
                    </div>
                    <div className="email-subject-cell-to">
                        To: {emailsTo.join('; ')}
                    </div> 
                </div>

                {hasAttachments && <Icon className="email-subject-cell-attachment" name="paperclip" />}

                <div className="email-subject-cell-time">{sentDate ? DateTimeService.format(sentDate, 'HH:mm') : '~'}</div>
            </div>
        );
    };

    @action.bound
    private _renderRefreshButton() {
        return (
            <Button className="dx-toolbar-item" size="sm" title="Refresh emails" color="primary" onClick={this._loadNewMessages}>
                <Icon name="sync-alt" />
            </Button>
        );
    }

    @action.bound
    private _loadNewMessages() {
        const now = DateTimeService.now();
        this._form.setPeriodToDateTime(now);

        this._refreshGrid();
    }

    @action.bound
    private _refreshGrid() {
        const instance = this._gridRef.current?.instance;
        void instance?.refresh();
    }

    @action.bound
    private async _onRowClick(args: OnRowClickArgs<EmailDto>) {
        if (!args.data || args.rowType === 'group') {
            this._selectedEmail = null;    
            return;
        }

        this._selectedEmail = args.data;

        const params = { emailId: this._selectedEmail.id };
        const { data } = await ApiService.getTypedData<string>(ApiUrls.EmailBodyUrl, params, { completion: this._loader, cancelDuplicatedRequests: true });
        this._selectedEmailBody = data;
        this._selectedEmailAttachments = null;

        if (this._selectedEmail.hasAttachments) {
            const { data: attachments } = await ApiService.getTypedData<EmailAttachmentDto[]>(ApiUrls.EmailAttachmentsUrl, params, { completion: this._loader, cancelDuplicatedRequests: true });
            this._selectedEmailAttachments = attachments;
        }
    }

    @action.bound
    private _addCssClassToRow(e: OnRowPreparedArgs<EmailDto>) {
        if (!e.data) return;

        e.data.status === MailQueueStatus.Failed && e.rowElement?.classList.add('email-status-error');
        e.data.status === MailQueueStatus.NotSent && e.rowElement?.classList.add('email-status-warning');
        e.data.status === MailQueueStatus.Cancelled && e.rowElement?.classList.add('email-status-info');
    }

    @action.bound
    private async _loadData() {
        const periodFrom = this._form.parsedPeriodFrom;
        const periodTo = this._form.parsedPeriodTo;
        const params = {
            periodFrom: periodFrom,
            periodTo: periodTo
        };
        return await ApiService.getTypedData<EmailDto[]>(ApiUrls.EmailsUrl, params, { completion: this._loader })
            .then((response) => {
                const model = response.data;
                return model.map(e => ({ ...e, groupDate: DateTimeService.trimTime(e.createdDate) }));
            });
    }

    @action.bound
    private _onAnyFieldChange() {
        if (this._form.validate()) {
            this._refreshGrid();
        }
    }

    @computed
    private get _columns() {
        const columns: DataGridColumn<EmailGroupingModel>[] = [
            new DataGridColumn<EmailGroupingModel>({ dataFieldName: 'type', caption: 'Type', width: 145 }),
            new DataGridColumn<EmailGroupingModel>({ dataFieldName: 'subject', caption: 'Subject', cellRenderTemplate: this._renderEmailSubject }),
            new DataGridColumn<EmailGroupingModel>({
                dataFieldName: 'groupDate',
                caption: 'Created date',
                groupIndex: 0,
                groupCellTemplate: this._renderGroupingDate,
                dataFieldType: DataGridColumnFieldType.date,
                sortOrder: 'desc'
            })
        ];

        return columns;
    }
}
