import React from 'react';

import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { CurrencyDto } from '@app/models/WebApiModels';
import { applyDecorators } from '@app/helpers/Decorator';
import { AdminLayout } from '@app/components/AdminLayout/AdminLayout';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';

import { Conversions } from './components/Conversions';
import { Currencies } from './components/Currencies';

@observer
export class GeneralConfigurationPage extends React.Component<{}, {}> {
    private _loader: PromiseCompletion = new PromiseCompletion();

    @observable.ref
    private _currencies: CurrencyDto[] = [];

    constructor(props: {}) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        void this.loadData();
    }

    render() {
        return (
            <AdminLayout>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px' }}>
                    <Conversions currencies={this._currencies} loader={this._loader} onRateChange={() => this._loadCurrencies()} />
                    <Currencies currencies={this._currencies} loader={this._loader} onCurrenciesUpdated={() => this._loadCurrencies()}/>
                </div>
            </AdminLayout>
        );
    }

    @action.bound
    public async loadData() {
        await this._loadCurrencies();
    }

    @action.bound
    private async _loadCurrencies() {
        const { data } = await ApiService.getTypedData<CurrencyDto[]>(ApiUrls.CurrencyUrl, null, { completion: this._loader });
        runInAction(() => {
            this._currencies = data;
        });
    }
}
