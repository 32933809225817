import { applyDecorators } from '@app/helpers/Decorator';
import { ConversionRateDto, CurrencyDto } from '@app/models/WebApiModels';
import { action, observable, runInAction } from 'mobx';
import React from 'react';

import cls from '../_general.module.scss';
import { CurrencyConversionCell } from './CurrencyConversionCell';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { observer } from 'mobx-react';

export type ConversionsProps = {
    currencies: CurrencyDto[];
    loader: PromiseCompletion;
    onRateChange: () => void;
};

@observer
export class Conversions extends React.Component<ConversionsProps, {}> {
    @observable.ref
    private _conversionRates: ConversionRateDto[] = [];

    constructor(props: ConversionsProps) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        void this._loadConversionRates();
    }

    render() {
        return (
            <div className={cls.root} style={{ flexDirection: 'column', width: 'fit-content' }}>
                <div style={{ marginBottom: '12px', fontSize: '18px' }}>Conversion Rates</div>
                <div className={cls.group}>
                    <table className={cls.table}>
                        <thead>
                            <tr>
                                <th />
                                {this.props.currencies
                                    .filter((c) => c.isBase)
                                    .map((currency) => (
                                        <React.Fragment key={'col' + currency.code}>
                                            <th>{currency.code}</th>
                                        </React.Fragment>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.currencies.map((currency) => (
                                <React.Fragment key={'row' + currency.code}>{this._renderConversionsRow(currency.code!, this.props.currencies, this._conversionRates)}</React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    private _renderConversionsRow(fromCurrency: string, currencies: CurrencyDto[], conversionRates: ConversionRateDto[]) {
        return (
            <tr>
                <td>
                    <strong>{fromCurrency}</strong>
                </td>
                {currencies
                    .filter((c) => c.isBase)
                    .map((toCurrency) => {
                        if (fromCurrency === toCurrency.code) return <td key={'cell' + fromCurrency + toCurrency.code}>1</td>;

                        const rate = conversionRates.find((r) => r.fromCurrency === fromCurrency && r.toCurrency == toCurrency.code);
                        return (
                            <React.Fragment key={'cell' + fromCurrency + toCurrency.code}>
                                <CurrencyConversionCell fromCurrency={fromCurrency} toCurrency={toCurrency.code!} rate={rate?.rate} onChange={this._loadConversionRates} />
                            </React.Fragment>
                        );
                    })}
            </tr>
        );
    }

    @action.bound
    private async _loadConversionRates() {
        const { data } = await ApiService.getTypedData<ConversionRateDto[]>(ApiUrls.ConfigurationConversionsUrl, null, { completion: this.props.loader });
        runInAction(() => {
            this._conversionRates = data;
        });

        this.props.onRateChange();
    }
}
