import * as React from 'react';
import { ButtonColor, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '../Modal/Modal';

type YesNoDialogProps = {
    title?: string;
    message?: string | string[] | JSX.Element;
    color?: ButtonColor;
    onConfirm?: () => void | Promise<void>;
};

export class YesNoDialog extends React.PureComponent<YesNoDialogProps> implements IModalDialogContent<void> {
    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const { title, color, onConfirm } = this.props;
        return {
            title: title ?? 'Confirmation',
            buttons: [
                {
                    color: 'warning',
                    type: ModalButtonType.No,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    color: color ?? 'secondary',
                    type: ModalButtonType.Yes,
                    onClick: async () => {
                        if (onConfirm) {
                            await onConfirm();
                        }
                        window.close(ModalButtonType.Yes);
                    }
                }
            ],
            width: '640px'
        };
    }

    render() {
        const { message } = this.props;

        if (typeof message === 'string' || Array.isArray(message)) {
            const messages = typeof message === 'string' ? [message] : message;

            return (
                <>
                    {messages && messages.length > 0 && (
                        <ul className="list-unstyled white-space-pre">
                            {messages.map((m, i) => (
                                <li key={m + i}>{m}</li>
                            ))}
                        </ul>
                    )}
                </>
            );
        }

        return message;
    }
}
