import React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';

import { FilePreviewDialog } from '../Dialogs/FilePreviewDialog';
import DateTimeService from '@app/services/DateTimeService';
import { DownloadService } from '@app/services/DownloadService';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { service } from '@app/services/_ServicesRegister';
import { applyDecorators } from '@app/helpers/Decorator';
import { Icon } from '../Icon';
import { Loading } from '../Loading';
import { modalService } from '../Modal/Modal';
import { ApiUrls } from '@app/AppConstants';
import ShadowDom from '../ShadowDom';
import { EmailAttachmentDto, EmailContentType, MailQueueStatus } from '@app/models/WebApiModels';

import './_email-preview.scss';

type EmailPreviewProps = {
    subject?: string;
    body: string;
    from: string;
    to?: string;
    cc?: string;
    sentDate?: Date;
    createdDate?: Date;
    attachments?: EmailAttachmentDto[] | null;
    status?: MailQueueStatus;
    errorMessage?: string;
    loader?: PromiseCompletion;
};

@observer
export class EmailPreview extends React.Component<EmailPreviewProps> {
    private _downloadService: DownloadService = service.resolve(DownloadService);

    constructor(props: EmailPreviewProps) {
        super(props);
        applyDecorators(this);
    }

    render() {
        const now = DateTimeService.now();
        const isPlainText = !/<[a-z/][\s\S]*>/i.test(this.props.body);
        return (
            <div className="d-flex flex-column" style={{ gap: '10px' }}>
                <div className="email-container">
                    {this.props.loader && <Loading className="email-loader" small loading={this.props.loader.isPending} />}

                    <div className="email-header">
                        <span className="email-subject">{this.props.subject}</span>

                        <div className="email-info">
                            <div className="email-user">
                                <Icon name="user" />
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center">
                                    <span>{this._formatEmailAddress(this.props.from)}</span>
                                    {this._renderMailStatusIcon(this.props.status, this.props.errorMessage)}
                                </div>
                                <span className="email-timestamp" title={this.props.createdDate ? DateTimeService.toCustomUiFormat(this.props.createdDate, "Created: YYYY.MM.DD HH:mm:ss") : ''}>
                                    {DateTimeService.toCustomUiFormat(this.props.sentDate ?? this.props.createdDate ?? now, "dd MMM'DD, HH:mm:ss")}
                                </span>
                                {this.props.to && (
                                    <span className="email-to">
                                        <span className="email-timestamp mr-1">To: </span>
                                        {this._formatEmailAddress(this.props.to)}
                                    </span>
                                )}
                                {this.props.cc && (
                                    <span className="email-to">
                                        <span className="email-timestamp mr-1">Copy: </span>
                                        {this._formatEmailAddress(this.props.cc)}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="email-attachments">{this.props.attachments?.map((x) => this._renderAttachment(x))}</div>
                    <div className="email-content">
                        <ShadowDom className="email-preview">
                            <div className="mb-2" style={{ overflowX: 'auto', wordBreak: isPlainText ? 'break-word' : undefined, whiteSpace: isPlainText ? 'pre-wrap' : undefined }}>
                                <div dangerouslySetInnerHTML={{ __html: this.props.body ?? '' }}></div>
                            </div>
                        </ShadowDom>
                    </div>
                </div>
            </div>
        );
    }

    private _formatEmailAddress(address?: string) {
        const parts = address?.split(';');
        return (
            <>
                {parts?.map((p, index) => {
                    return (
                        <React.Fragment key={index}>
                            {index > 0 ? (
                                <>
                                    <span className="email-separator">;</span>
                                </>
                            ) : null}
                            <a className="email-address" href={'mailto:' + p} key={index}>
                                {p}
                            </a>
                        </React.Fragment>
                    );
                })}
            </>
        );
    }

    private _renderMailStatusIcon(status?: MailQueueStatus, errorMessage?: string) {
        if (!status) return;
        if (status === MailQueueStatus.NotSent) return <Icon title="Pending" name="info-circle" className="ml-2" />;
        if (status === MailQueueStatus.Cancelled) return <Icon title="Cancelled" name="info-circle" className="ml-2" />;
        if (status === MailQueueStatus.Sent) return <Icon title="Sent" name="chevron-circle-down" className="ml-2" style={{ color: 'green' }} />;
        if (status === MailQueueStatus.Failed) return <Icon title={errorMessage} name="times-circle" className="ml-2" style={{ color: 'red' }} />;
    }

    private _formatFileSize(sizInBytes?: number) {
        if (typeof sizInBytes !== 'number') return '0';
        if (sizInBytes >= 1024 * 1024 * 1024) return (sizInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        if (sizInBytes >= 1024 * 1024) return (sizInBytes / (1024 * 1024)).toFixed(2) + ' MB';
        return (sizInBytes / 1024).toFixed(2) + ' KB';
    }

    private _renderAttachment(attachment: EmailAttachmentDto) {
        return (
            <div key={`attachment_${attachment.id}`} className="email-attachment-item">
                <div className="email-attachment-item-info" onClick={() => this._downloadAttachment(attachment)}>
                    <div className="email-attachment-file">
                        <Icon name="file" />
                    </div>
                    <div className="d-flex flex-column">
                        <span>{attachment.name}</span>
                    </div>
                    <div className="d-flex email-attachment-size">
                        <span>{this._formatFileSize(attachment.size)}</span>
                    </div>
                </div>

                {this._getIsAttachmentPreviewEnabled(attachment) && (
                    <div
                        className="preview-button"
                        title="Preview Attachment"
                        onClick={() => this._previewButtonClickHandler(attachment)}
                    >
                        <i className='fa fa-eye' />
                    </div>
                )}
            </div>
        );
    }

    @action.bound
    private _getIsAttachmentPreviewEnabled(attachment: EmailAttachmentDto) {
        const { name, type } = attachment;
        if (!name) return false;

        const nameParts = name.split('.');
        const fileExtension = nameParts[nameParts.length - 1].toLowerCase();
        const textBasedExtensions = ['txt', 'csv'];

        if (textBasedExtensions.includes(fileExtension) || type === EmailContentType.Pdf) return true;

        return false;
    }

    @action.bound
    private _previewButtonClickHandler(attachment: EmailAttachmentDto) {
        const parameters = { id: attachment.id };

        void modalService.show(FilePreviewDialog, { url: ApiUrls.EmailAttachmentDownloadUrl, parameters });
    }

    @action.bound
    private _downloadAttachment(attachment: EmailAttachmentDto) {
        const parameters = { id: attachment.id };
        void this._downloadService.downloadFile(ApiUrls.EmailAttachmentDownloadUrl, parameters, attachment.name);
    }
}
