import { ProductDto } from "./WebApiModels"

export type BasketDTO = {
    service: ProductDto
    count: number
}

export enum ReportPeriod {
    DailyReports = 'dailyReports',
    WeeklyReports = 'weeklyReports',
    MonthlyReports = 'monthlyReports'
}

export enum ReportType {
    Pdf = "Pdf",
    Csv = "Csv",
    Xls = "Xls",
}

export enum UserRole {
    Station = '0',
    Ground = '1',
    Commercial = '2',
    Admin = '3'
}

// export type AirportDto = {
//     code: string
//     name: string
//     countryTwoLetterCode?: string;
//     dailyReports: ReportType[]
//     weeklyReports: ReportType[]
//     monthlyReports: ReportType[]
//     emailsTo: string[]
//     emailsCc?: string[]
//     localCurrency?: string;
//     baseCurrency: string;
//     level: number;
// }

// export type GroupProductPrice = {
//     productCode: string;
//     displayName: string;
//     sortOrder: string;
//     prices: ExtendedPrice[]
// }
// export type AirportGroupProductPrice = GroupProductPrice & {
//     commission?: number;
// }

// export type ExtendedPrice = {
//     amount: number;
//     currency: string;
//     calculated: boolean;
// }

// export type FlightDTO = {
//     airlineRcd: string
//     flightNumber: string
//     departureAirport: string
//     arrivalAirport: string
//     id: string
//     scheduledTimeDepartureLt: Date
// }

// export type ProductDto = {
//     code: string
//     displayName: string
//     quantity: number
//     price: number
//     sortOrder: string
//     commission: number
//     totalPrice: number
//     currency: string
// }
// export type ProductsFilter = {
//     flightId: string;
//     connexDestination: string;
// }
// export type ServiceOverruleDTO = {
//     id?: string
//     airportCode?: string
//     productCode?: string
//     dateFrom?: string
//     dateTo?: string
//     amount: number
// }

// export type PaymentDTO = {
//     id: string
//     datatransTransactionId: string
//     totalPrice: number
//     currency: string
//     callUrl: string
// }
// export type ShortProductInfo = {
//     code: string
//     displayName: string
//     sortOrder: string
//     commission?: number
// }

// 
// 
// 
// 
// 
// 

// export type PaymentStatusDTO = {
//     transactionId: string
//     totalPrice: number
//     status: PaymentStatus
// }


// export type PaymentConfirmationDTO = {
//     transactionId?: number
//     state: PaymentStatus
//     flightNumber?: string
//     departureDateLt?: Date
//     airport?: string
//     message?: string
//     customerName?: string
//     customerSurname?: string
//     totalPrice?: number
//     currency?: string
//     products?: ProductDto[]
// }

// 
// 
// 
// 
// 
// 

// export type UserDTO = {
//     name?: string
//     role: UserRole
// }



// export type ConversionRateDTO = {
//     fromCurrency: string;
//     toCurrency: string;
//     rate: number;
// } 



// export enum ReportType {
//     PDF = "Pdf",
//     CSV = "Csv",
//     XLS = "Xls"
// }