import { applyDecorators } from '@app/helpers/Decorator';
import { observable } from 'mobx';

export enum DevExtremeCssStatuses {
    Loaded,
    Error,
}

export const DXLinkId = 'devextreme-link';

class DevExtremeCssService {
    private _itemCount: number = 0;
    private _itemSet: WeakSet<object> = new WeakSet();
    @observable public status: DevExtremeCssStatuses | null = null;
    
    constructor () {
        applyDecorators(this);
    }
    
    // use in constructor
    public addItem (item: object) {
        this._itemSet.add(item);
        this._itemCount++;

        if (this._itemCount === 1) {
            this._loadCssFile();
        }
    }

    // use in componentWillUnmount
    public removeItem (item: object) {
        if (this._itemSet.has(item)) {
            this._itemCount--;

            if (!this._itemCount) {
                this._unloadCssFile();
            }
        }
    }

    private _loadCssFile () {
        if (!document.getElementById(DXLinkId)) {
            const link = document.createElement('link');

            link.id = DXLinkId;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = document.body.classList.contains('dark') ? '/css/devextreme_dark.css' : '/css/devextreme.css';

            document.head.append(link);

            link.onload = this._onload;
            link.onerror = this._onerror;
        }
    }

    private _unloadCssFile () {
        const link = document.getElementById(DXLinkId);

        if (link) {
            link.remove();

            this._setStatus(null);
        }
    }

    private _setStatus (status: DevExtremeCssStatuses | null) {
        if (this.status !== status) {
            this.status = status;
        }
    }

    private _onload = () => {
        if (document.getElementById(DXLinkId)) {
            this._setStatus(DevExtremeCssStatuses.Loaded);
        }
    };

    private _onerror = () => {
        this._setStatus(DevExtremeCssStatuses.Error);
    };
}

export const dxCssService = new DevExtremeCssService();