export class KeyboardKeys {
    public static readonly ENTER: number = 13;
    public static readonly COMMA: number = 188;
    public static readonly ESCAPE: number = 27;
    public static readonly BACKSPACE: number = 8;
    public static readonly C: number = 67;
    public static readonly H: number = 72;
    public static readonly V: number = 86;
    public static readonly Z: number = 90;
}

export class KeyboardCodes {
    public static readonly ENTER: string = 'Enter';
    public static readonly ESCAPE: string = 'Escape';
    public static readonly BACKSPACE: string = 'Backspace';
}
