import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import cls from './_result.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import ApiService from '@app/services/ApiService';
import { Order, TransactionStatus } from '@app/models/WebApiModels';
import { AppLoader } from '@app/components';
import DateTimeService from '@app/services/DateTimeService';
import { ApiUrls } from '@app/AppConstants';

export const OrderResultPage = () => {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId') || '';
    const [payment, setPayment] = useState<Order | null>(null);

    const getPayment = useCallback(async () => {
        try {
            const { data: payment } = await ApiService.getTypedData<Order>(`${ApiUrls.PaymentUrl}/${orderId}`);
            setPayment(payment);
        } catch (e) {
            setPayment({
                status: TransactionStatus.Failed,
                message: (e as Error)?.message
            } as Order);
        }
    }, [orderId]);

    useEffect(() => {
        if (orderId) {
            void getPayment();
        }
    }, [getPayment, orderId]);

    if (!payment) return <AppLoader showLoader={true} />;

    if (payment.status === TransactionStatus.Failed) {
        return (
            <div className={cls.root}>
                <div className={cls.error}>
                    <FontAwesomeIcon className={cls.icon} icon={faXmark} />
                </div>
                <div className={cls.title}>
                    Oh no!
                    <br />
                    Payment was unsuccessful.
                </div>
                <div className={cls.text}>Payment provider was not able to process your payment ({payment.message}). Please try again.</div>
            </div>
        );
    }

    if (payment.status === TransactionStatus.Canceled) {
        return (
            <div className={cls.root}>
                <div className={cls.cancel}>
                    <FontAwesomeIcon className={cls.icon} icon={faTriangleExclamation} />
                </div>
                <div className={cls.title}>Payment was cancelled.</div>
                <div className={cls.text}>We aren't able to process your payment. Please try again.</div>
            </div>
        );
    }

    if (payment.status === TransactionStatus.Ordered) {
        return (
            <div className={cls.root}>
                <div className={cls.cancel}>
                    <FontAwesomeIcon className={cls.icon} icon={faCircleStop} />
                </div>
                <div className={cls.title}>Payment was suspended.</div>
                <div className={cls.text}>Please try again.</div>
            </div>
        );
    }

    return (
        <div className={cls.wrapper}>
            <div className={cls.header}></div>
            <table className={cls.rootTable}>
                <thead>
                    <tr>
                        <td>
                            <div className={cls.headerSpace}>&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className={cls.confirmation}>
                                <div className={cls.h1}>Payment Confirmation{payment.airport && payment.destination ? (payment.connexDestination ? `for ${payment.airport}-${payment.connexDestination}` : `for ${payment.airport}-${payment.destination}`) : ''}</div>
                                <h5>No. {payment.referenceNumber}</h5>
                                <div className={cls.p}>
                                    Dear Mr./Mrs.{' '}
                                    <b>
                                        {payment.customerName || ''} {payment.customerSurname || ''}
                                    </b>
                                </div>
                                <div className={cls.p}>
                                    We are pleased to confirm your payment dated <b>{DateTimeService.format(payment.departureDateUtc ? new Date(payment.departureDateUtc) : new Date())}</b> as follows:
                                </div>
                                <table className={cls.table}>
                                    <tbody>
                                        <tr>
                                            <th>Flight</th>
                                            <th>Date</th>
                                            <th>
                                                <span className={cls.desc}>Quantity</span>
                                                <span className={cls.mobi}>Qty</span>
                                            </th>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Total Price</th>
                                        </tr>
                                        {payment.products?.map((p) => {
                                            return (
                                                <tr key={p.code}>
                                                    <td>{payment.flightNumber}</td>
                                                    <td>{payment.departureDateUtc ? DateTimeService.format(new Date(payment.departureDateUtc), 'DDMMMYY') : ''}</td>
                                                    <td>{p.quantity}</td>
                                                    <td>{p.displayName}</td>
                                                    <td>
                                                        {payment.currency} {p.price}
                                                    </td>
                                                    <td>
                                                        {payment.currency} {p.totalPrice}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        <tr className={cls.total}>
                                            <td colSpan={5}>Total</td>
                                            <td>
                                                {payment.currency} {payment.totalPrice}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className={cls.footerSpace}>&nbsp;</div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div className={cls.footer}>
                <b>Edelweiss Air AG</b>
                <br />
                The Circle 32
                <br />
                P. O. Box
                <br />
                8058 Zurich Airport
                <br />
                Switzerland
            </div>
        </div>
    );
};
