import * as React from 'react';
import { Container, Card, CardHeader, ListGroup } from 'reactstrap';
import { observer } from 'mobx-react';
import { makeObservable } from 'mobx';

import JobListStore from './Stores/JobListStore';
import JobListTitle from './Components/JobListTitle/JobListTitle';
import JobListFilter from './Components/JobListFilter/JobListFilter';
import JobItem from './Components/JobItem/JobItem';
import { CardBodyLoading } from '@app/components/Loading';
import './job-list.scss';
import { stringSearchService } from '@app/services/StringSearchService';

@observer
export default class JobList extends React.Component<{}> {
    private _store: JobListStore;

    constructor(props: {}) {
        super(props);
        makeObservable(this);
        this._store = new JobListStore();
    }

    componentWillUnmount() {
        this._store.unload();
    }


    public render() {
        const isAnyJobExecution = !!this._store.topExecutions.length;
        const showExecutions = !!this._store.jobsFilter && isAnyJobExecution;

        return (
            <Container className="p-0">
                <Card className="my-4">
                    <CardHeader tag="h3" className="d-flex">
                        <JobListTitle showExecutions={showExecutions} executions={this._store.topExecutions} failedJobs={this._store.topFailedJobs} />
                        {this._store.dataLoaded && <JobListFilter value={this._store.jobsFilter} onChange={(e) => this._store.setFilter(e.target.value)} />}
                    </CardHeader>
                    {this._renderJobs()}
                </Card>
            </Container>
        );
    }

    private _renderJobs() {
        if (!this._store.dataLoaded) {
            return <CardBodyLoading className="job-loader" loading={!this._store.dataLoaded} />;
        }

        if (!this._store.jobs.length) {
            if (this._store.exceptionMessage) {
                return (
                    <div className="py-3 text-center">
                        <span className="job-list-placeholder job-list-placeholder-error">{this._store.exceptionMessage}</span>
                    </div>
                );
            }
            return (
                <div className="py-3 text-center">
                    <span className="job-list-placeholder">No jobs yet</span>
                </div>
            );
        }

        return (
            <ListGroup flush>
                {this._store.jobs
                    .filter((jobItem) => this._store.filterJob(jobItem))
                    .map(jobItem => (
                        <JobItem key={jobItem.id}
                            job={jobItem}
                            renderText={(text) => this._renderText(text)}
                            onParametersSaved={(e) => this._store.updateJob(e)}
                            onTriggerCreated={(e) => this._store.updateJob(e)}
                            onTriggerDeleted={(e) => this._store.updateJob(e)}
                            onJobStarted={(e) => this._store.updateJob(e)}
                        />
                    ))}
            </ListGroup>
        );
    }

    private _renderText(text: string) {
        const { searchTerms, textComparer } = this._store;
        const parts = stringSearchService.markMatches(text, searchTerms, textComparer);

        return (
            <>
                {parts.map((p, index) =>
                    p.isMatch ? (
                        <span className="text-match" key={'t' + index}>
                            {`${p.text}`}
                        </span>
                    ) : (
                        p.text
                    )
                )}
            </>
        );
    }
}
