import { observable, action } from 'mobx';
import dxButton from 'devextreme/ui/button';
import dxDataGrid from 'devextreme/ui/data_grid';

import { OnInitializedParams, DataGridColumnDisplayMode, IGridFilter, GridState } from './DxDataGridTypes';
import { DataGridColumn } from './DxDataGridColumn';
import { applyDecorators } from '@app/helpers/Decorator';
import DxGridHelper from '@app/helpers/DxGridHelper';
import { extensions } from '@app/services/Extensions';


export type DxDataGridToolbarProps<T extends {}> = {
    onCustomToolBarRender?: () => JSX.Element;
    onConfigReset: () => void;
    onFiltersReset: (gridState: GridState) => void;
    customColumns: DataGridColumn<T>[];
    isHistorizedMode?: boolean;
    customToolBarLocation?: 'before' | 'center' | 'after';
};

export type Toolbar = {
    component?: dxDataGrid;
    element?: HTMLElement;
    toolbarOptions?: ToolbarOptions;
};

type ToolbarOptions = {
    items?: Array<ToolbarItem>;
};

type ToolbarItem = {
    location?: 'after' | 'before' | 'center';
    options?: ToolbarItemOptions;
    widget?: 'dxAutocomplete' | 'dxButton' | 'dxCheckBox' | 'dxDateBox' | 'dxMenu' | 'dxSelectBox' | 'dxTabs' | 'dxTextBox' | 'dxButtonGroup' | 'dxDropDownButton';
    template?: string;
};

type ToolbarItemOptions = {
    icon?: string;
    hint?: string;
    disabled?: boolean;
    visible?: boolean;
    elementAttr?: unknown;
    onClick?: Function;
    onInitialized?: Function;
};

export default class DxDataGridToolbar<T extends {}> {
    @observable.ref private _clearFiltersButton?: dxButton;
    @observable.ref private _resetConfigurationButton?: dxButton;
    @observable.ref private _groupingButton?: dxButton;
    @observable.ref private _collapseExpandGroupButton?: dxButton;
    @observable.ref private _dataGrid?: dxDataGrid;
    private _props: DxDataGridToolbarProps<T>;

    constructor(props: DxDataGridToolbarProps<T>) {
        applyDecorators(this);
        this._props = props;
    }

    public updateProps(props: DxDataGridToolbarProps<T>) {
        this._props = props;
        this.updateButtonsState(this._props.customColumns, this._dataGrid?.state());
    }

    public createToolbar<T>(e: Toolbar, isPrintMode: boolean) {
        this._dataGrid = e.component;
        if (!isPrintMode) {
            const hasGrouping = this._props.customColumns.some((x) => Number(x.groupIndex) >= 0);
            e.toolbarOptions?.items?.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'clearformat',
                        hint: 'Clear all filters',
                        disabled: true,
                        onClick: () => this._clearFiltersState(this._dataGrid),
                        onInitialized: (args: OnInitializedParams<dxButton>) => {
                            this._clearFiltersButton = args.component;
                        }
                    }
                },
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        disabled: true,
                        elementAttr: { class: 'dx-clear-btn' },
                        icon: 'fa fa-cog',
                        hint: 'Reset configuration',
                        onClick: () => this._resetStateToDefault(this._dataGrid),
                        onInitialized: (args: OnInitializedParams<dxButton>) => {
                            this._resetConfigurationButton = args.component;
                        }
                    }
                },
                {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                        visible: hasGrouping,
                        icon: 'fas fa-times',
                        hint: 'Reset grouping',
                        onClick: () => this._onResetGrouping(),
                        onInitialized: (args: OnInitializedParams<dxButton>) => {
                            this._groupingButton = args.component;
                        }
                    }
                },
                {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                        visible: hasGrouping,
                        icon: 'fas fa-angle-double-up',
                        hint: 'Collapse all groups',
                        onClick: () => this._onCollapseGroups(),
                        onInitialized: (args: OnInitializedParams<dxButton>) => {
                            this._collapseExpandGroupButton = args.component;
                        }
                    }
                }
            );
        }

        if (this._props.onCustomToolBarRender) {
            e.toolbarOptions?.items?.unshift({
                location: this._props.customToolBarLocation ?? 'after',
                template: 'customToolBar',
            });
        }
    }

    @action.bound
    private _onExpandGroups() {
        this._dataGrid?.expandAll();
        this._collapseExpandGroupButton?.option({
            icon: 'fas fa-angle-double-up',
            hint: 'Collapse all groups',
            onClick: this._onCollapseGroups
        });
    }

    @action.bound
    private _onCollapseGroups() {
        this._dataGrid?.collapseAll();
        this._collapseExpandGroupButton?.option({
            icon: 'fas fa-angle-double-down',
            hint: 'Expand all groups',
            onClick: this._onExpandGroups
        });
    }

    @action.bound
    private _onGroupColumns() {
        this._props.customColumns.forEach(column => {
            this._dataGrid?.columnOption(
                column.dataFieldName as string | number,
                { groupIndex: column.groupIndex, sortOrder: column.sortOrder }
            );
        });
        this._collapseExpandGroupButton?.option('visible', true);
        this._groupingButton?.option({
            icon: 'fas fa-times',
            hint: 'Reset grouping',
            onClick: this._onResetGrouping
        });
    }

    @action.bound
    private _onResetGrouping() {
        this._dataGrid?.clearGrouping();
        this._collapseExpandGroupButton?.option('visible', false);
        this._groupingButton?.option({
            icon: 'fas fa-layer-group',
            hint: 'Group columns',
            onClick: this._onGroupColumns
        });
    }

    private _clearFiltersState(dataGrid?: dxDataGrid) {
        dataGrid?.clearFilter();
        dataGrid?.clearSorting();

        const state = dataGrid?.state() as GridState;
        DxGridHelper.applyFiltersToState(state, this._getDefaultFilters(this._props.customColumns));
        state.searchText = '';
        dataGrid?.state(state);

        if (this._props.onFiltersReset) {
            this._props.onFiltersReset(state);
        }
    }

    @action
    private async _resetStateToDefault(dataGrid?: dxDataGrid) {
        dataGrid?.clearFilter();
        dataGrid?.clearSorting();
        dataGrid?.state(null);

        const state = dataGrid?.state() as GridState;
        DxGridHelper.applyFiltersToState(state, this._getDefaultFilters(this._props.customColumns));

        if (this._props.onConfigReset) {
            this._props.onConfigReset();
        }

        dataGrid?.state(state);
    }

    private _getDefaultFilters(columns: DataGridColumn<T>[]): IGridFilter[] {
        const result: IGridFilter[] = [];

        for (const column of columns) {
            if (column.dataFieldType && column.defaultHeaderFilterOption) {
                const filter: IGridFilter = {
                    field: column.dataFieldName.toString(),
                    fieldType: column.dataFieldType,
                    filterType: column.defaultHeaderFilterOption.filterType,
                    values: column.defaultHeaderFilterOption.values
                };
                result.push(filter);
            }

            if (column.columns && column.columns.length > 0) {
                result.push(...this._getDefaultFilters(column.columns));
            }
        }

        return result;
    }

    @action.bound
    public updateButtonsState(gridColumns: DataGridColumn<T>[], gridState: GridState) {
        if (!gridState?.columns) return;
        const hasSorting = gridState?.columns.some((x) => x.hasOwnProperty('sortIndex'));

        const cloneGridState = extensions.deepCopy(gridState) as GridState;
        for (const column of cloneGridState.columns) {
            column.filterValues = undefined;
        }
        DxGridHelper.applyFiltersToState(cloneGridState, this._getDefaultFilters(this._props.customColumns));

        const hasFiltering = gridState?.columns.some((x) => {
            if (x.hasOwnProperty('filterValue') && x['filterValue']) {
                return true;
            }

            if (x.hasOwnProperty('filterValues') && x['filterValues']) {
                const defaultFilter = cloneGridState.columns.find((c) => c.dataField === x.dataField);
                if (defaultFilter) {
                    const colFiltersStr = JSON.stringify(x.filterValues);
                    const defFilterStr = JSON.stringify(defaultFilter.filterValues);
                    return colFiltersStr !== defFilterStr;
                }
            }

            return false;
        });

        const hasSearching = gridState?.searchText ? true : false;
        const hasGrouping = gridState.columns.some((x) => x.hasOwnProperty('groupIndex'));

        const hiddenByDisplayMode = new Set(gridColumns.filter((x) => x.displayMode && !x.displayMode.has(DataGridColumnDisplayMode.UI)).map((x) => x.dataFieldName));
        const hidden = gridState.columns.filter((x) => x.visible === false);
        const hasHidden = hidden.some((x) => !hiddenByDisplayMode.has(x.dataField as keyof T));

        const getDefaultColumnsOrderArray = (columns: DataGridColumn<T>[]): { visibleIndex: number; dataField: keyof T | '' }[] => {
            const array: { visibleIndex: number; dataField: keyof T | '' }[] = [];
            for (let i = 0; i < columns.length; i++) {
                array.push({ visibleIndex: i, dataField: columns[i].dataFieldName || '' });
                if (columns[i].columns) {
                    array.push(...getDefaultColumnsOrderArray((columns[i].columns && columns[i].columns) || []));
                }
            }
            return array;
        };

        const currentColumnArray: { visibleIndex: number; dataField: keyof T }[] = [];
        for (let i = 0; i < gridState.columns.length; i++) {
            currentColumnArray.push({ visibleIndex: gridState.columns[i].visibleIndex, dataField: gridState.columns[i].dataField as keyof T });
        }

        const defaultColumnOrder = JSON.stringify(getDefaultColumnsOrderArray(gridColumns));
        const currentColumnOrder = JSON.stringify(currentColumnArray);

        const hasChangedOrdering = defaultColumnOrder !== currentColumnOrder;

        const isClearButtonDisabled = !hasSorting && !hasFiltering && !hasSearching;
        const isResetButtonEnabled = hasGrouping || hasSorting || hasFiltering || hasSearching || hasChangedOrdering || hasHidden;

        this._resetConfigurationButton?.option('disabled', !isResetButtonEnabled);
        this._clearFiltersButton?.option('disabled', isClearButtonDisabled);
    }
}
