import React from 'react';

import { ApiUrls } from '@app/AppConstants';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { BaseFormModel } from '@app/components/BaseFormModel';
import { modalService } from '@app/components/Modal/Modal';
import { applyDecorators } from '@app/helpers/Decorator';
import { AirportGroupProductPrice } from '@app/models/WebApiModels';
import ApiService from '@app/services/ApiService';
import { faBan, faFloppyDisk, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import cls from '../../_configuration.module.scss';
import { FormInput } from '@app/components/FormControls';
import { isFloatNumber } from '@app/appConstants/Validation';
import { NotificationHandler } from '@app/components/ToastNotification';

type ProductItemProps = {
    targetCurrency: string;
    service: AirportGroupProductPrice;
    priceGroup: number;
    loader: PromiseCompletion;
    onChange: () => void;
};

@observer
export default class ProductItem extends React.Component<ProductItemProps, {}> {
    @observable private _form: FormModel;

    @observable private _edit: boolean = false;
    @observable private _valueBeforeEdit?: number;

    constructor(props: ProductItemProps) {
        super(props);
        applyDecorators(this);

        const price = this.props.service.prices?.find((x) => x.currency === this.props.targetCurrency);

        this._form = new FormModel();
        this._form.price = price?.amount;
    }

    render() {
        const { service, targetCurrency } = this.props;
        const { displayName } = service;

        const price = this.props.service.prices?.find((x) => x.currency === this.props.targetCurrency);

        return (
            <div className={cls.root}>
                <div className={cls.name}>{displayName}</div>
                <div className={cls.price}>
                    {this._edit ? <FormInput formModel={this._form} name="price" autoFocus className={cls.input} invalid={!this._form.isFormValid} onEnter={() => this._saveEdit()} onEscape={() => this._cancelEdit()} hideValidationError /> : <span className={price?.calculated && price.amount ? 'text-warning' : ''}>{price?.amount ?? '~'}</span>}
                    <span>{price?.currency ?? targetCurrency}</span>
                </div>
                <div className={cls.field}></div>
                <div className={cls.actions}>
                    {this._edit ? (
                        <>
                            <>
                                <span className={cls.btnRed} onClick={() => this._cancelEdit()}>
                                    <FontAwesomeIcon icon={faBan} />
                                </span>
                                <span className={cls.btnGreen} style={{ color: this._form.isFormValid ? '' : 'gray' }} onClick={this._form.isFormValid ? this._saveEdit : undefined}>
                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                </span>
                            </>
                        </>
                    ) : (
                        <>
                            <span className={cls.btn} onClick={this._startEdit}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </span>
                            <span className={cls.btnTrash} style={{ color: price?.calculated || !price?.amount ? 'gray' : '' }} onClick={!price?.calculated && price?.amount ? () => this._showDeleteDialog() : () => {}}>
                                <FontAwesomeIcon icon={faTrash} />
                            </span>
                        </>
                    )}
                </div>
            </div>
        );
    }

    @action
    private async _showDeleteDialog() {
        const shouldDelete = await modalService.showConfirmation('', 'Delete Service From Group');
        if (shouldDelete) this._deleteProduct();
    }

    @action.bound
    private _startEdit() {
        this._edit = true;
        this._valueBeforeEdit = this._form.price;
    }

    @action
    private _cancelEdit() {
        this._edit = false;
        this._form.price = this._valueBeforeEdit;
    }

    @action.bound
    private async _saveEdit() {
        const config = {
            priceGroup: this.props.priceGroup,
            productCode: this.props.service.productCode,
            amount: this._form.price,
            currency: this.props.targetCurrency
        };
        await ApiService.postData(`${ApiUrls.PriceUrl}/group`, config, { completion: this.props.loader });
        NotificationHandler.showSuccess('Saved!');
        this._edit = false;
        this.props.onChange();
    }

    @action.bound
    private async _deleteProduct() {
        await ApiService.deleteData(`${ApiUrls.PriceUrl}/group/${this.props.priceGroup}/${this.props.service.productCode}/${this.props.targetCurrency}`, { completion: this.props.loader });
        this.props.onChange?.();
    }
}

class FormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @observable
    @isFloatNumber()
    price?: number;
}
