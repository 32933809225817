import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { ButtonColor, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@app/components/Modal/Modal';
import { TextControl } from '@app/components/UI/FormControls';
import { BasketDTO } from '@app/models/AppModels';
import { applyDecorators } from '@app/helpers/Decorator';
import { FlightDto, Order, OrderCreateModel, OrderProductModel, TransactionType } from '@app/models/WebApiModels';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';
import { loaderStore } from '@app/stores/LoaderStore';

import 'react-phone-input-2/lib/style.css';
import cls from './_payerInformation.module.scss';
import { BaseFormModel } from '@app/components/BaseFormModel';
import { isRequired } from '@app/appConstants/Validation';
import { FormInput, PhoneControl } from '@app/components/FormControls';
import { FormGroup, Label } from 'reactstrap';

export type PayerInformationDialogProps = {
    basket: BasketDTO[];
    flight: FlightDto;
    connexDestination?: string;
};

@observer
export default class PayerInformationDialog extends React.Component<PayerInformationDialogProps, {}> implements IModalDialogContent<Order> {
    private _window: ModalWindow<Order>;
    private _form: FormModel = new FormModel();

    constructor(props: PayerInformationDialogProps) {
        super(props);
        applyDecorators(this);
    }

    getModalOptions = (window: ModalWindow<Order>) => {
        this._window = window;

        return {
            title: 'Payer Information',
            window: '620px',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    color: 'danger' as ButtonColor,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Ok,
                    color: 'danger' as ButtonColor,
                    title: 'Pay',
                    isDisabled: !this._form.isFormValid,
                    onClick: () => {
                        void this._pay();
                    }
                }
            ]
        } as ModalDialogOptions<Order>;
    };

    render() {
        return (
            <>
                <div className={cls.basket}>
                    {this.props.basket.map((b) => {
                        return (
                            <div className={cls.basketItem} key={b.service.code}>
                                <div className={cls.basketName}>
                                    <b>{b.count}x</b> {b.service.displayName}
                                </div>
                                <div className={cls.basketPrice}>
                                    {(b.service.price * b.count).toFixed(2)} {b.service.currency}
                                </div>
                            </div>
                        );
                    })}
                    <div className={cls.basketTotal}>
                        Total Price:{' '}
                        {this.props.basket
                            .map((b) => b.service.price * b.count)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toFixed(2)}{' '}
                        {this.props.basket[0]?.service.currency}
                    </div>
                </div>
                <FormGroup>
                    <FormInput formModel={this._form} name="firstName" placeholder='First Name *' className={cls.field} />
                </FormGroup>
                <FormGroup>
                    <FormInput formModel={this._form} name="lastName" placeholder='Last Name *' className={cls.field} />
                </FormGroup>
                <FormGroup>
                    <PhoneControl formModel={this._form} name="phoneNumber" country="ch" enableSearch placeholder="+41 XX XXX XX XX" disableSearchIcon searchPlaceholder="Search..." containerClass={cls.phoneContainer} inputClass={cls.phoneInput} buttonClass={cls.phoneCountryBtn} searchClass={cls.phoneSearch} />
                </FormGroup>
                <FormGroup>
                    <FormInput formModel={this._form} name="pnr" placeholder='Passenger Name Record' transformValueHandler={(v) => v.toUpperCase()} className={cls.field}/>
                </FormGroup>
            </>
        );
    }

    @action.bound
    private async _pay() {
        const { flight, basket } = this.props;
        const params = {
            flightId: flight.id,
            customerName: this._form.firstName,
            customerSurname: this._form.lastName,
            connexDestination: this.props.connexDestination,
            pnr: this._form.pnr,
            phone: this._form.phoneNumber,
            type: TransactionType.Datatrans,
            products: basket.map((b) => {
                return { code: b.service.code, quantity: b.count, departureDateLt: flight?.departureDateTimeUtc } as OrderProductModel;
            })
        } as OrderCreateModel;
        const { data } = await ApiService.postTypedData<Order>(ApiUrls.PaymentUrl, params, { completion: loaderStore.processLoader });
        this._window.close(ModalButtonType.Ok, data);
    }
}

class FormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @observable
    @isRequired()
    firstName: string;

    @observable
    @isRequired()
    lastName: string;

    @observable
    @isRequired()
    phoneNumber: string;

    @observable
    pnr?: string;
}
