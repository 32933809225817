import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { applyDecorators } from '@app/helpers/Decorator';
import { ShortProductInfo } from '@app/models/WebApiModels';
import { observer } from 'mobx-react';
import React from 'react';

import cls from '../../_configuration.module.scss';
import { action } from 'mobx';
import { modalService } from '@app/components/Modal/Modal';
import EditProductDialog from '@app/components/Dialogs/EditProductDialog';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';
import { Icon } from '@app/components/Icon';

type ProductItemProps = {
    service: ShortProductInfo;
    loader: PromiseCompletion;
    onChange: () => void;
};

@observer
export default class ProductItem extends React.Component<ProductItemProps, {}> {
    constructor(props: ProductItemProps) {
        super(props);
        applyDecorators(this);
    }

    render() {
        return (
            <>
                <div className={`${cls.item}`} style={{ cursor: 'pointer' }} onClick={() => this._openEditDialog()}>
                    <div className={cls.name}>{this.props.service?.displayName}</div>
                    <div className={cls.order}>{this.props.service?.sortOrder}</div>
                    {this.props.service?.availableOnConnex && (
                        <div style={{ marginLeft: '10px' }}>
                            <Icon name="check" title="Available on Connex" style={{ color: 'green' }} />
                        </div>
                    )}
                </div>
            </>
        );
    }

    @action.bound
    private async _openEditDialog() {
        await modalService.show(EditProductDialog, {
            name: this.props.service.displayName,
            sortOrder: this.props.service.sortOrder,
            availableOnConnex: this.props.service.availableOnConnex,
            onSave: (name, sortOrder, availableOnConnex) => void this._saveEdit(name, sortOrder, availableOnConnex)
        });
    }

    @action.bound
    private async _saveEdit(name: string, sortOrder: string, availableOnConnex: boolean) {
        const params = {
            code: this.props.service.code,
            displayName: name,
            sortOrder: sortOrder,
            availableOnConnex: availableOnConnex
        } as ShortProductInfo;
        await ApiService.putTypedData<ShortProductInfo>(ApiUrls.ProductUrl, params, { completion: this.props.loader });
        this.props.onChange();
    }
}
