import * as React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-free/css/all.css';

interface IIconProps extends React.HTMLAttributes<HTMLSpanElement> {
    name: IconName;
    title?: string;
    className?: string;
    noSolid?: boolean;
    solid?: boolean;
    isBrand?: boolean;
}

export class Icon extends React.PureComponent<IIconProps> {
    render() {
        const { name, className, noSolid, isBrand, solid, ...other } = this.props;
        const classList: string[] = [];
        if (noSolid) classList.push('far');
        if (isBrand) classList.push('fab');
        if (solid) classList.push('fas');
        if (!classList.length) classList.push('fa');
        classList.push('fa-' + name);
        if (className) classList.push(className);
        return <span className={classList.join(' ')} {...other} />;
    }
}
