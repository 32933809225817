import { applyDecorators } from '@app/helpers/Decorator';
import { observer } from 'mobx-react';
import React from 'react';
import { ButtonColor, IModalDialogContent, ModalButtonType, ModalWindow } from '../Modal/Modal';
import { BaseFormModel } from '../BaseFormModel';
import { observable } from 'mobx';
import { Col, Container, Label, Row } from 'reactstrap';
import { FormCheckbox, FormInput } from '../FormControls';
import { isRegEx, isRequired } from '@app/appConstants/Validation';

export type EditProductDialogProps = {
    name?: string;
    sortOrder?: string;
    availableOnConnex: boolean;
    onSave: (name: string, sortOrder: string, availableOnConnex: boolean) => void;
};

@observer
export default class EditProductDialog extends React.Component<EditProductDialogProps, {}> implements IModalDialogContent<void> {
    private _form: FormModel;

    constructor(props: EditProductDialogProps) {
        super(props);
        applyDecorators(this);

        this._form = new FormModel();
        this._form.name = props.name;
        this._form.sortOrder = props.sortOrder;
        this._form.availableOnConnex = props.availableOnConnex;
    }

    getModalOptions(window: ModalWindow<void>) {
        return {
            title: 'Edit Product',
            width: '640px',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Save,
                    color: 'danger' as ButtonColor,
                    isDisabled: !this._form.isFormValid,
                    onClick: () => {
                        this.props.onSave(this._form.name!, this._form.sortOrder!, this._form.availableOnConnex);
                        window.close();
                    }
                }
            ]
        };
    }

    render() {
        return (
            <Container>
                <Row className='mb-2'>
                    <Label>
                        <strong>Service Name:</strong>
                    </Label>
                    <FormInput formModel={this._form} name="name" />
                </Row>
                <Row className='mb-2'>
                    <Label>
                        <strong>Sort Order (L#:#|P#:#):</strong>
                    </Label>
                    <FormInput formModel={this._form} name="sortOrder" />
                </Row>
                <Row>
                    <FormCheckbox formModel={this._form} name="availableOnConnex" checked={this._form.availableOnConnex} label="Available on Connex" />
                </Row>
            </Container>
        );
    }
}

class FormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @observable
    @isRequired()
    name?: string;

    @observable
    @isRequired()
    @isRegEx(/^L[0-9]+:[0-9]+\|P[0-9]+:[0-9]+$/)
    sortOrder?: string;

    @observable
    availableOnConnex: boolean;
}
