import { observable, action, computed } from 'mobx';
import { applyDecorators } from './helpers/Decorator';
import { UserProfileDTO } from './models/Models';
import UrlService from './services/UrlService';
import { ComponentUrls } from './appConstants/SignalR';
import { GlobalQueryParams } from './appConstants/QueryParams';
import { getEncodedToken } from './helpers/JWTHelper';

type JwtToken = {
    aio: string;
    amr: string[];
    aud: string;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    ipaddr: string;
    iss: string;
    name: string;
    nbf: number;
    nonce: string;
    oid: string;
    rh: string;
    roles: string[];
    sub: string;
    tid: string;
    unique_name: string;
    upn: string;
    uti: string;
    ver: string;
};

class AppStore {
    public readonly isPrintMode: boolean = this.printMode;
    @observable isLoading: boolean = false;
    @observable isHistorized: boolean = false;
    @observable isAppReady: boolean = false;
    @observable isChangeLogOff: boolean = true;
    @observable isCtrlKey: boolean = false;
    @observable isShiftKey: boolean = false;
    @observable releasedDate: Date | null;
    @observable isContrastMode: boolean = true;
    @observable isRosterWriteUnlocked: boolean = false;
    @observable public isShortcutsEnabled: boolean = true;
    @observable isAdaptive: boolean = false;
    @observable bodyClassList: Set<string> = new Set([].slice.apply(document.body.classList));
    signalRConnectionId?: string;

    notificationBar: HTMLElement | null = null;
    notificationBarMessage: HTMLElement | null = null;

    constructor () {
        applyDecorators(this);
        document.body.addEventListener('keydown', this._keydownHandler);
        document.body.addEventListener('mouseenter', this._appMouseInHandler);
        const mode: boolean | null = this.getValue('contrastMode');
        if (mode !== null) this.isContrastMode = mode;
    }

    public get crewPortalUrl(){
        if (this.impersonatedUser && !this.isCrewPortalDns) {
            return '/crew-portal';
        }

        const crewPortalUrlConfig = (window as {crewPortalUrl?: string}).crewPortalUrl;
        const isCrewPortalConfigured = !!crewPortalUrlConfig;
        const crewPortalUrl = isCrewPortalConfigured ? (window.location.protocol + '//' + crewPortalUrlConfig) : '/crew-portal';
        return crewPortalUrl;
    }

    public get isCrewPortalDns(){
        return window.location.host.toLowerCase() == (window as {crewPortalUrl?: string}).crewPortalUrl?.toLowerCase();
    }

    public get isMobileView (): boolean {
        return window.innerWidth <= 1000;
    }

    public get isMacOS (): boolean {
        return window.navigator.platform.includes('Mac');
    }

    public get currentToken (): string {
        return this.getValue<string>('currentToken') || '';
    }

    private _currentUser: UserProfileDTO | null = null;
    public get currentUser (): UserProfileDTO | null {
        if (!this._currentUser) {
            this._currentUser = this.getValue<UserProfileDTO>('currentUser');
        }

        return this._currentUser;
    }

    public get impersonatedUser (): IImpersonationModel | null {
        return this.impersonatedUsersStack.pop() || null;
    }

    public get impersonatedUsersStack () {
        return this.getValue<IImpersonationModel[]>('impersonatedUsers') || [];
    }

    public setImpersonatedUserValue (value: IImpersonationModel[]) {
        this.setValue('impersonatedUsers', value);
    }

    setImpersonatedUser (value: IImpersonationModel) {
        const stack = this.impersonatedUsersStack;
        stack.push(value);
        this.setImpersonatedUserValue(stack);
    }

    exitImpersonation () {
        const stack = this.impersonatedUsersStack;
        stack.pop();
        if (stack.length) {
            this.setImpersonatedUserValue(stack);
        } else {
            localStorage.removeItem('impersonatedUsers');
        }
        window.location.href = '/';
    }

    public get currentUserRoles (): string[] {
        return this.impersonatedUser?.roles ?? [];
    }

    public get realUserRoles (): string[] {
        return this.currentUser?.roles || [];
    }

    get currentUserName () {
        if (this.currentUser) {
            return this.currentUser.name ?? this.currentUser?.unique_name ?? 'guest';
        }

        return 'guest';
    }

    isAuthorized (): boolean {
        return !!(this.currentToken && this.currentUser);
    }

    clearUserData () {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
        localStorage.removeItem('impersonatedUser');
    }

    setValue<T> (key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getValue<T> (key: string): T | null {
        const value = localStorage.getItem(key);
        return value ? (JSON.parse(value) as T) : null;
    }

    removeItem (key: string) {
        localStorage.removeItem(key);
    }

    @action.bound
    setAppReady () {
        this.isAppReady = true;
    }

    @action
    switchMode (value: boolean) {
        this.isContrastMode = value;
        this.setValue('contrastMode', value);
    }

    @action.bound
    _keydownHandler (event: KeyboardEvent) {
        /* Windows Ctrl key is MacOS Command key.  Keep in mind to check isMacOS.  event.metaKey in Windows is WINDOWS key.*/
        this.isCtrlKey = event.ctrlKey || (this.isMacOS && event.metaKey);
        this.isShiftKey = event.shiftKey;
    }

    @action.bound
    _appMouseInHandler (event: MouseEvent) {
        this.isCtrlKey = event.ctrlKey || (this.isMacOS && event.metaKey);
        this.isShiftKey = event.shiftKey;
    }

    @computed get printMode () {
        return UrlService.getParam('printMode') === 'enabled';
    }

    @computed get historizedMode () {
        return UrlService.getParam('historizedMode') === 'enabled';
    }

    @action.bound
    switchChangeLogCheckBox () {
        this.isChangeLogOff = !this.isChangeLogOff;
    }

    @action.bound
    setChangeLogCheckBoxValue (value: boolean) {
        if (this.isChangeLogOff !== value) this.switchChangeLogCheckBox();
    }

    public get currentTokenObj () {
        return this._parseJwtToken(this.currentToken);
    }

    private _parseJwtToken (token: string): JwtToken | null {
        if (!token) return null;

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64).split('').map((c: string) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')
        );

        return JSON.parse(jsonPayload);
    }

    public getQueryStringAuthParams (opts?: { single?: boolean, token?: boolean }) {
        const parameters: string[] = [];

        const impersonateAsPersonId = UrlService.getParam(GlobalQueryParams.impersonateAsPerson);
        if (impersonateAsPersonId) {
            parameters.push(ComponentUrls.QsImpersonatedPersonId + '=' + impersonateAsPersonId);
        }

        const viewPersonId = UrlService.getParam(GlobalQueryParams.viewPersonId);
        if (viewPersonId) {
            parameters.push(ComponentUrls.QsViewPersonId + '=' + viewPersonId);
        }

        if (!impersonateAsPersonId && this.impersonatedUser) {
            if (this.impersonatedUser.personId) {
                parameters.push(ComponentUrls.QsImpersonatedPersonId + '=' + this.impersonatedUser.personId);
            }
            if (this.impersonatedUser.roles) {
                parameters.push(ComponentUrls.QsImpersonatedRoles + '=' + this.impersonatedUser.roles.join(','));
            }
        }

        if (opts?.token) {
            const encodedToken = getEncodedToken(appStore.currentToken);
            parameters.push(ComponentUrls.QsToken + '=' + encodeURIComponent(encodedToken));
        }

        if (!parameters.length) {
            return '';
        }

        return (opts?.single ? '?' : '&') + parameters.join('&');
    }
}

export interface IImpersonationModel {
    personId?: string;
    name?: string;
    roles?: Array<string>;
    impersonateAsPrincipal?: boolean;
    email?: string;
}

export const appStore = new AppStore();
