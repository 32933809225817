import * as React from 'react';
import { appStore } from '../AppStore';
import { Roles } from '@app/AppConstants';

export interface IWindowAdal extends Window {
    adal?: {
        redirectToLogin (): void;
        redirectToLogout (): void;
    };
}

export class AzureAuth extends React.Component<{}, {}> {
    static logOut () {
        appStore.clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogout();
    }

    static login () {
        appStore.clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogin();
    }

    private static _hasRole(role: string) {
        const isAdmin = !!appStore.realUserRoles.find(x => x === "3");
        return isAdmin || !!appStore.realUserRoles.find(x => x === role);
    }

    
    static get isStation() {
        return this._hasRole(Roles.Station);
    }

    static get isGround() {
        return this._hasRole(Roles.Ground);
    }

    static get isCommercial() {
        return this._hasRole(Roles.Commercial);
    }

    static get isAdmin() {
        return this._hasRole(Roles.Admin);
    }
}