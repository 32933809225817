import React from 'react';

import { AirportDto, AirportGroupProductPrice, CurrencyDto } from '@app/models/WebApiModels';
import { ApiUrls } from '@app/AppConstants';
import ApiService from '@app/services/ApiService';
import { AdminLayout } from '@app/components/AdminLayout/AdminLayout';
import ReactSelect from '@app/components/UI/ReactSelect';
import { ListItem } from '@app/components/List/List';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { observer } from 'mobx-react';
import { applyDecorators } from '@app/helpers/Decorator';
import { action, computed, observable, runInAction } from 'mobx';

import ProductItem from './components/ProductItem';
import Badge from '@app/components/UI/Badge';
import { Icon } from '@app/components/Icon';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { TextControl } from '@app/components/UI/FormControls';

import cls from '../_configuration.module.scss';

enum Tabs {
    From = 'From',
    To = 'To'
}

@observer
export default class StationsConfigurationPage extends React.Component<{}, {}> {
    private _dataLoader: PromiseCompletion = new PromiseCompletion();
    private _pageLoader: PromiseCompletion = new PromiseCompletion();

    @observable private _airports: AirportDto[] = [];
    @observable private _products: AirportGroupProductPrice[] = [];
    @observable private _currencies: CurrencyDto[] = [];
    @observable private _selected?: AirportDto;

    @observable private _selectedTab: Tabs = Tabs.From;

    constructor(props: {}) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        void this._loadData();
    }

    render() {
        const title = this._selected ? (
            <>
                {this._selected.code} &mdash; {this._selected.name}
            </>
        ) : (
            ``
        );

        return (
            <AdminLayout list={this.listItems} onItemSelect={(code) => void this._onAirportSelect(code)} title={title} dataLoader={this._dataLoader} pageLoader={this._pageLoader}>
                {this._selected && (
                    <>
                        <div className={cls.list}>
                            <div className={cls.col}>
                                <div className={cls.item}>
                                    {this._renderEmails(
                                        this._selected!.emailsTo ?? [],
                                        'To:',
                                        (val) => {
                                            this._selected!.emailsTo = this._selected?.emailsTo?.filter((x) => x !== val);
                                            void ApiService.postData(ApiUrls.AirportUrl, this._selected, {});
                                        },
                                        (val) => {
                                            if (!val.trim()) return;
                                            const value = [...(this._selected?.emailsTo ?? []), val];
                                            this._selected!.emailsTo = value.filter((x, index) => value.indexOf(x) == index);
                                            void ApiService.postData(ApiUrls.AirportUrl, this._selected, {});
                                        }
                                    )}
                                </div>
                                <div className={cls.item}>
                                    {this._renderEmails(
                                        this._selected!.emailsCc ?? [],
                                        'Cc:',
                                        (val) => {
                                            this._selected!.emailsCc = this._selected?.emailsCc?.filter((x) => x !== val);
                                            void ApiService.postData(ApiUrls.AirportUrl, this._selected, {});
                                        },
                                        (val) => {
                                            if (!val.trim()) return;
                                            const value = [...(this._selected?.emailsCc ?? []), val];
                                            this._selected!.emailsCc = value.filter((x, index) => value.indexOf(x) == index);
                                            void ApiService.postData(ApiUrls.AirportUrl, this._selected, {});
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        {this._selectedTab === Tabs.From && (
                            <div className={cls.list} style={{ display: 'block' }}>
                                <div className={cls.col}>
                                    <div className={cls.item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '20px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', gap: '20px' }}>
                                            <div>Local currency:</div>
                                            <ReactSelect
                                                value={this._selected.localCurrency}
                                                onSelect={(val) => void this._onAirportCurrencyChanged('local', val)}
                                                isClearable
                                                options={this._currencies.map((x) => ({
                                                    label: x.code!,
                                                    value: x.code!
                                                }))}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                                            <div>Base currency:</div>
                                            <ReactSelect
                                                value={this._selected.baseCurrency}
                                                onSelect={(val) => void this._onAirportCurrencyChanged('base', val)}
                                                options={this._currencies
                                                    .filter((x) => x.isBase)
                                                    .map((x) => ({
                                                        label: x.code!,
                                                        value: x.code!
                                                    }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <Nav className={cls.nav}>
                            <NavItem className={[cls.navItem, this._selectedTab === Tabs.From ? cls.active : ''].join(' ')}>
                                <NavLink onClick={() => this._onTabsSwitch(Tabs.From)} className={cls.navLink}>
                                    From
                                </NavLink>
                            </NavItem>
                            <NavItem className={[cls.navItem, this._selectedTab === Tabs.To ? cls.active : ''].join(' ')}>
                                <NavLink onClick={() => this._onTabsSwitch(Tabs.To)} className={cls.navLink}>
                                    To
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this._selectedTab}>
                            <TabPane tabId={Tabs.From}>{this._renderProducts(Tabs.From)}</TabPane>
                            <TabPane tabId={Tabs.To}>{this._renderProducts(Tabs.To)}</TabPane>
                        </TabContent>
                    </>
                )}
            </AdminLayout>
        );
    }

    private _renderProducts(tab: Tabs) {
        const zrhRelated = tab === Tabs.To;

        return (
            <div className={cls.list}>
                <div className={cls.col}>{this._getItems(this._products, 'L1', zrhRelated)}</div>
                <div className={cls.col}>{this._getItems(this._products, 'L2', zrhRelated)}</div>
            </div>
        );
    }

    private _renderEmails(list: string[], title: string, onClick: (email: string) => void, onAdd: (email: string) => void) {
        return (
            <Col>
                <Row className="mb-2">
                    <strong>{title}</strong>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', flexWrap: 'wrap' }}>
                        {list.map((x) => (
                            <Badge>
                                <div style={{ display: 'flex', padding: '0 8px', gap: '8px' }}>
                                    {x} <Icon name="close" style={{ cursor: 'pointer', color: 'red' }} onClick={() => onClick(x)} />
                                </div>
                            </Badge>
                        ))}
                    </div>
                </Row>
                <TextControl
                    maxLength={512}
                    clearOnEnter
                    onEnter={(val) => {
                        onAdd(val);
                    }}
                />
            </Col>
        );
    }

    @action.bound
    private _getItems(products: AirportGroupProductPrice[], regExp: string, zrhRelated: boolean) {
        const _getPosition = (pos: string): number => +pos.split('|')[0].split(':')[1];
        if (!this._selected) return null;

        const currency = products.every((p) => p.prices?.find((x) => x.currency === this._selected?.localCurrency && x.amount)) ? this._selected.localCurrency : this._selected.baseCurrency;

        return products
            .filter((e) => e.sortOrder)
            .filter((e) => new RegExp(`(${regExp})`).test(e.sortOrder!))
            .sort((a, b) => _getPosition(a.sortOrder!) - _getPosition(b.sortOrder!))
            .map((s) => {
                return <ProductItem key={s.productCode} product={s} airport={this._selected!} loader={this._dataLoader} onCommissionChange={this._onProductChange} onChange={this._onProductChange} localCurrency={this._selected?.localCurrency} baseCurrency={this._selected?.baseCurrency} currency={currency} zrhRelated={zrhRelated} />;
            });
    }

    @action.bound
    private async _onTabsSwitch(tabId: Tabs) {
        this._selectedTab = tabId;
        await this._loadProducts();
    }

    @action.bound
    private async _onAirportSelect(code: string) {
        this._selected = this._airports.find((a) => a.code === code);
        await this._loadProducts();
    }

    @action.bound
    private _onProductChange() {
        void this._loadProducts();
    }

    @action.bound
    private async _onAirportCurrencyChanged(type: 'local' | 'base', value: string | null) {
        if (type === 'local') this._selected!.localCurrency = value ?? undefined;
        if (type === 'base' && value) this._selected!.baseCurrency = value;

        await ApiService.postData(ApiUrls.AirportUrl, this._selected, {});
        await this._loadProducts();
    }

    @action.bound
    private async _loadData() {
        await this._loadAirports();
        await this._loadCurrencies();
    }

    @action.bound
    private async _loadAirports() {
        const { data } = await ApiService.getTypedData<AirportDto[]>(ApiUrls.AirportUrl + `?configurableOnly=true`, {}, { completion: this._pageLoader });
        this._airports = data;
    }

    @action.bound
    private async _loadCurrencies() {
        const { data } = await ApiService.getTypedData<CurrencyDto[]>(ApiUrls.CurrencyUrl, null, { completion: this._dataLoader });
        this._currencies = data;
    }

    @action.bound
    private async _loadProducts() {
        if (this._selected) {
            const { data } = await ApiService.getTypedData<AirportGroupProductPrice[]>(`${ApiUrls.AirportUrl}/${this._selected.code}/products?zrhRelatedOverwrites=${this._selectedTab == Tabs.To}`, null, { completion: this._dataLoader });
            this._products = data;
        }
    }

    @computed
    get listItems() {
        return this._airports.map((a) => {
            return { id: a.code, label: `(${a.level}) ${a.code} - ${a.name}` } as ListItem;
        });
    }
}
