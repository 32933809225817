import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Col, FormGroup, Row } from 'reactstrap';

import { CurrencyDto } from '@app/models/WebApiModels';
import { applyDecorators } from '@app/helpers/Decorator';
import { Icon } from '@app/components/Icon';
import { BaseFormModel } from '@app/components/BaseFormModel';
import { displayName, hasMaxLength, hasMinLength, isRequired } from '@app/appConstants/Validation';
import { FormCheckbox, FormInput } from '@app/components/FormControls';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';

import cls from '../_general.module.scss';

export type CurrenciesProps = {
    currencies: CurrencyDto[];
    loader: PromiseCompletion;
    onCurrenciesUpdated: () => void;
};

@observer
export class Currencies extends React.Component<CurrenciesProps, {}> {
    @observable
    private _form: FormModel;

    constructor(props: CurrenciesProps) {
        super(props);
        applyDecorators(this);

        this._form = new FormModel();
    }

    render() {
        return (
            <div className={cls.root} style={{ flexDirection: 'column', width: 'fit-content', maxWidth: '30%' }}>
                <div style={{ fontSize: '18px' }}>Currencies</div>
                <Col>
                    <div className={cls.currencies}>Base: {this.props.currencies.filter((c) => c.isBase).map((c) => this._renderCurrencyBadge(c))}</div>
                    <div className={cls.currencies}>Local: {this.props.currencies.filter((c) => !c.isBase).map((c) => this._renderCurrencyBadge(c))}</div>
                    <FormGroup>
                        <Row className="mt-4 mb-2" style={{ alignItems: 'center' }}>
                            <Col sm={6}>
                                <FormInput formModel={this._form} name="currencyCode" placeholder="Currency code" transformValueHandler={(v) => v.toUpperCase()} smallValidationError />
                            </Col>
                            <Col sm={3}>
                                <FormCheckbox label="Is Base" formModel={this._form} name="isBase" checked={!!this._form.isBase} />
                            </Col>
                            <Col sm={1}>
                                <Button color="danger" onClick={() => this._addCurrencyClick()} disabled={!this._form.isFormValid || this.props.currencies.findIndex((c) => c.code === this._form.currencyCode) !== -1}>
                                    <Icon name="plus" />
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </div>
        );
    }

    private _renderCurrencyBadge(currency: CurrencyDto) {
        return (
            <React.Fragment key={'badge' + currency.code}>
                <div className={[cls.badge, currency.isBase ? cls.badgePrimary : cls.badgeInfo].join(' ')}>
                    {currency.code} {currency.removable && <Icon style={{ cursor: 'pointer' }} name="close" onClick={() => this._deleteCurrencyClick(currency.code!)} />}
                </div>
            </React.Fragment>
        );
    }

    @action
    private async _addCurrencyClick() {
        await ApiService.postData(
            ApiUrls.CurrencyUrl,
            {
                code: this._form.currencyCode,
                isBase: this._form.isBase
            },
            {
                completion: this.props.loader
            }
        );

        this._form.clear();
        this.props.onCurrenciesUpdated();
    }

    @action
    private async _deleteCurrencyClick(code: string) {
        await ApiService.deleteData(ApiUrls.CurrencyUrl + `?code=${code}`, null, {
            completion: this.props.loader
        });

        this.props.onCurrenciesUpdated();
    }
}

class FormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @isRequired()
    @hasMaxLength(3)
    @hasMinLength(3)
    @displayName('Currency Code')
    @observable
    currencyCode?: string;

    @displayName('Is base')
    @observable
    isBase: boolean = false;

    @action
    public clear() {
        this.currencyCode = '';
        this.isBase = false;
    }
}
