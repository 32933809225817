import React from "react";

import cls from './_badge.module.scss';

type BadgeProps = {
    children: JSX.Element | string;
    color?: "primary" | "info";
};

export default class Badge extends React.Component<BadgeProps, {}> {
    render() {
        const classNames: string[] = [];
        classNames.push(cls.badge);
    
        if (this.props.color)
            classNames.push(cls[this.props.color]);
        else
            classNames.push(cls.default);
    
        return (
            <div className={classNames.join(" ")}>
                {this.props.children}
            </div>
        );
    }
}
