import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { applyDecorators } from '@app/helpers/Decorator';
import { AirportDto, AirportGroupProductPrice } from '@app/models/WebApiModels';
import { modalService } from '@app/components/Modal/Modal';

import cls from '../../_configuration.module.scss';
import DateTimeService from '@app/services/DateTimeService';
import { Icon } from '@app/components/Icon';
import { PriceConfigurationDialog, PriceConfigurationTab } from '@app/components/Dialogs/PriceConfigurationDialog/PriceConfigurationDialog';
import { Currency } from '@app/AppConstants';

type StationItemProps = {
    product: AirportGroupProductPrice;
    airport: AirportDto;
    loader: PromiseCompletion;
    localCurrency?: string;
    baseCurrency?: string;
    currency?: string;
    onChange: () => void;
    onCommissionChange: () => void;
    zrhRelated?: boolean;
};

@observer
export default class ProductItem extends React.Component<StationItemProps, {}> {
    constructor(props: StationItemProps) {
        super(props);
        applyDecorators(this);
    }

    render() {
        const { product: service, currency, zrhRelated } = this.props;

        let targetCurrency = currency;
        if (zrhRelated) {
            targetCurrency = Currency.ZRH;
        }

        let price = service.prices?.find((x) => x.currency === targetCurrency);
        let hasError = false;

        const now = DateTimeService.today();
        const ruleCandidates = service.overwrites?.filter((x) => DateTimeService.isBeforeDateOrEqual(now, x.dateToUtc ?? now) && DateTimeService.isAfterDateOrEqual(now, x.dateFromUtc ?? now) && x.currency === targetCurrency);
        const activeRule = ruleCandidates?.find((x) => !x.calculated) ?? ruleCandidates?.[0];

        if (!price?.amount) {
            hasError = true;
            price = service.prices?.[0];
        }

        return (
            <>
                <div className={cls.item}>
                    <div className={cls.name}>{service.displayName}</div>
                    <div className={cls.info + ' ' + (hasError ? 'text-danger' : price?.calculated ? 'text-warning' : '')} onClick={() => this._onEdit(PriceConfigurationTab.PriceOverrules)}>
                        {activeRule ? <Icon name="lightbulb" /> : !service.prices?.length ? <Icon name="bars-staggered" /> : null}
                        {activeRule ? `${activeRule.amount} ${price?.currency}` : `${price?.amount ? Number(price.amount.toFixed(2)) : '~'} ${price?.currency}`}
                    </div>
                    <div className={cls.actions}>
                        <span className={`${cls.btnText} ${cls.changed}`} onClick={() => this._onEdit(PriceConfigurationTab.CommissionOverrules)}>
                            +{(service.commission ?? 0) * 100}
                            <small>%</small>
                        </span>
                    </div>
                </div>
            </>
        );
    }

    @action.bound
    private async _onEdit(tab: PriceConfigurationTab) {
        await modalService.show(PriceConfigurationDialog, {
            productName: this.props.product.displayName,
            localCurrency: this.props.airport.localCurrency,
            baseCurrency: this.props.airport.baseCurrency!,
            airportCode: this.props.airport.code!,
            productCode: this.props.product.productCode!,
            onChanged: () => this.props.onChange(),
            onCommissionChange: () => this.props.onCommissionChange(),
            zrhRelated: this.props.zrhRelated ?? false,
            tab: tab
        });
    }
}
