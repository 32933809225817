import { FC, useCallback, useEffect, useState } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '@app/components';
import { loaderStore } from '@app/stores/LoaderStore';
import { AirportDto } from '@app/models/WebApiModels';
import { ApiUrls } from '@app/AppConstants';
import ApiService from '@services/ApiService';

import cls from './_airportsList.module.scss';
import { TextControl } from '@app/components/UI/FormControls';

type AirportsListPageProps = {
    onSelect: (pos: AirportDto) => void;
};

export const AirportsListPage: FC<AirportsListPageProps> = ({ onSelect }) => {
    const [airports, setAirports] = useState<AirportDto[]>([]);
    const [search, setSearch] = useState<string>('');
    const [pos, setPos] = useState<AirportDto | null>(null);

    const getAirportsList = useCallback(async () => {
        const { data } = await ApiService.getTypedData<AirportDto[]>(ApiUrls.AirportUrl + '?withFlightsOnly=true', null, { completion: loaderStore.appLoader });
        setAirports(data);
    }, []);

    useEffect(() => {
        void getAirportsList();
    }, [getAirportsList]);

    const onClick = (pos: AirportDto) => () => {
        setPos(pos);
    };

    const renderItem = (item: AirportDto) => {
        return (
            <div className={cls.item} key={item.code} onClick={onClick(item)}>
                {pos?.code === item.code && <FontAwesomeIcon className={cls.icon} icon={faCheck} />}
                {`${item.code} - ${item.name}`}
            </div>
        );
    };

    const _onSearch = (val: string) => {
        setSearch(val);
    };

    const _onPositionSelect = () => {
        pos && onSelect(pos);
    };

    return (
        <>
            <div className={cls.root}>
                <div className={cls.header}>
                    <TextControl showIcon={true} inpClass={cls.search} wrapClass={cls.searchWrap} label="Search" value={search} onValueChange={_onSearch} />
                </div>
                <div className={cls.body}>{airports.filter((e) => (search ? e.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1 || e.code?.toLowerCase().indexOf(search.toLowerCase()) !== -1 : !search)).map((e) => renderItem(e))}</div>
                <div className={cls.footer}>
                    <Button label="Ok" disabled={!pos} onClick={_onPositionSelect} />
                </div>
            </div>
        </>
    );
};
