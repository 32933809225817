import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import Axios from 'axios';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '../Modal/Modal';
import { applyDecorators } from '@app/helpers/Decorator';
import ApiService from '@app/services/ApiService';
import PdfViewer from '../PdfViewer';

type FilePreviewDialogProps = {
    url: string;
    parameters?: unknown;
};

@observer
export class FilePreviewDialog extends React.Component<FilePreviewDialogProps> implements IModalDialogContent<void> {
    @observable private _content: string | JSX.Element | null = null;
    private _loader = new PromiseCompletion();
    private _cancellationSource = Axios.CancelToken.source();

    constructor (props:FilePreviewDialogProps) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        void this._loader.add(this._loadFile);
    }

    componentWillUnmount() {
        this.cancel();
    }
    
    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'File Preview',
            loader: this._loader,
            buttons: [
                {
                    type: ModalButtonType.Close,
                    onClick: () => {
                        window.close();
                    }
                }
            ],
            width: '800px'
        };
    }

    render() {
        return this._content;
    }

    public cancel() {
        this._cancellationSource.cancel('Clear File Preview');
        this._cancellationSource = Axios.CancelToken.source();
    }

    @action.bound
    private async _loadFile() {
        const { url, parameters } = this.props;
        const { data } = await ApiService.getTypedData<Blob>(url, parameters, { responseType: 'blob', cancellationToken: this._cancellationSource.token });

        switch (data.type) {
            case 'text/plain': {
                const text = await data.text();
                this._content = <pre>{text}</pre>;

                break;
            }
            case 'application/pdf': {
                const pdfData = await data.arrayBuffer();
                this._content = (
                    <PdfViewer
                        pdfData={pdfData}
                        onLoadError={() => this._content = null}
                    />
                );
                break;
            }
            default:
                this._content = 'This file is not supported';
        }
    }
}
