import React, { ClassType, FunctionComponent } from 'react';
import { observable, action, computed, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, ModalHeader, ModalFooter, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Draggable from 'react-draggable';
import { AxiosError } from 'axios';

import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { EmptyProps, applyDecorators } from '@app/helpers/Decorator';
import { KeyboardCodes } from '@app/appConstants/KeyboardKeys';
import ModalBodyWithBoundary from './ModalBodyWithBoundary';
import { Loading } from '../Loading';
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import { YesNoDialog } from '../Dialogs/YesNoDialog';
import { InformationDialog } from '../Dialogs/InformationDialog';
import { ErrorDialog } from '../Dialogs/ErrorDialog';

import './modal.scss';

export type ButtonColor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link';

type ClickHandler<T> = (window: ModalWindow<T>) => void | Promise<void>;

export type ModalButtonOptions<T> = {
    type: ModalAction;
    color?: ButtonColor;
    title?: string;
    outline?: boolean;
    isDisabled?: boolean;
    setFocus?: boolean;
    alignLeft?: boolean;
    children?: ModalButtonOptions<T>[];
    onClick: ClickHandler<T>;
};

export type ModalOptions<T> = {
    title?: string;
    width?: number | string;
    closeByEscape?: boolean;
    dialog: (window: ModalWindow<T>) => JSX.Element;
    buttons?: (window: ModalWindow<T>) => ModalButtonOptions<T>[];
    onAppear?: (window: ModalWindow<T>) => void;
    onHide?: (window: ModalWindow<T>) => void;
};

export enum ModalButtonType {
    Ok = 'Ok',
    Cancel = 'Cancel',
    Close = 'Close',
    Save = 'Save',
    Update = 'Update',
    Remove = 'Remove',
    Confirm = 'Confirm',
    Reset = 'Reset',
    Add = 'Add',
    CreateNew = 'CreateNew',
    Send = 'Send',
    Delete = 'Delete',
    Yes = 'Yes',
    No = 'No',
    Submit = 'Submit',
    Apply = 'Apply',
    Download = 'Download',
    RunNow = 'RunNow',
    SaveAndRunNow = 'SaveAndRunNow'
}

type ConfirmationOptions = {
    title?: string;
    color?: ButtonColor;
    onConfirm?: () => void | Promise<void>;
};

type ModalAction = ModalButtonType | string;

export type ModalResult<T> = {
    button: ModalAction;
    result: T | null;
};

type ModalWindowProps<T> = ModalOptions<T> & {
    onClose: (action: ModalAction, result?: T) => void;
};

type ModalPromise<T> = Promise<ModalResult<T>> & {
    close: (action?: ModalAction, result?: T) => void;
};

export type ModalDialogOptions<T> = {
    buttons?: ModalButtonOptions<T>[];
    loader?: PromiseCompletion;
    title?: string | JSX.Element;
    width?: number | string;
    bodyClassName?: string;
    disableAutoFocus?: boolean;
    footerPrefix?: JSX.Element;
    closeByEscape?: boolean;
    hideCloseButton?: boolean;
    confirmClose?: () => Promise<boolean>;
};

export interface IModalDialogContent<T> {
    getModalOptions: (window: ModalWindow<T>) => ModalDialogOptions<T>;
}

@observer
export class Modals extends React.Component {
    constructor(props: EmptyProps) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._onDocumentKeyDown);
    }

    @action.bound
    private _onDocumentKeyDown(event: KeyboardEvent) {
        const modal = modalService.getUpperModal();
        if (modal && event.code === KeyboardCodes.ESCAPE && modal.closeByEscape !== false) {
            event.stopImmediatePropagation();
            modal.onClose(ModalButtonType.Cancel, undefined);
        }
    }

    render() {
        return <>{modalService.renderModals()}</>;
    }
}

type ModalButtonProps<T> = ModalButtonOptions<T> & {
    window: ModalWindow<T>;
    innerRef?: React.Ref<HTMLButtonElement>;
};

@observer
class ModalButton<T> extends React.Component<ModalButtonProps<T>> {
    @observable private _isWorking: boolean = false;
    @observable private _isDropdownOpen: boolean = false;

    constructor(props: ModalButtonProps<T>) {
        super(props);
        applyDecorators(this);
    }

    render() {
        const { innerRef, title, isDisabled, type, color, outline, children, onClick } = this.props;

        let buttonColor: ButtonColor = 'secondary';
        if (type === ModalButtonType.Cancel) {
            buttonColor = 'light';
        }
        if (type === ModalButtonType.Add || type === ModalButtonType.Save || type === ModalButtonType.Update || type === ModalButtonType.Download) {
            buttonColor = 'success';
        }
        if (type === ModalButtonType.Yes || type === ModalButtonType.Apply) {
            buttonColor = 'primary';
        }
        if (type === ModalButtonType.Remove || type === ModalButtonType.No || type === ModalButtonType.Reset) {
            buttonColor = 'danger';
        }
        if (color) {
            buttonColor = color;
        }

        const disabled = isDisabled || this._isWorking;

        if (children?.length) {
            return (
                <ButtonDropdown isOpen={this._isDropdownOpen} toggle={this._onToggleDropdown} className="mod-seft-test mod-seft-test-dropdown" size="md">
                    <Button id="caret" onClick={() => this._onClick(onClick)} disabled={disabled} className={this._isWorking ? 'btn-modal-loader' : ''}>
                        {title ?? type}
                    </Button>
                    <DropdownToggle caret size="md" />
                    <DropdownMenu>
                        {children.map((c) => (
                            <DropdownItem key={c.type} onClick={() => this._onClick(c.onClick)} color={buttonColor} outline={outline} disabled={c.isDisabled}>
                                {c.title ?? c.type}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
            );
        }

        return (
            <Button className={this._isWorking ? 'btn-modal-loader' : ''} innerRef={innerRef} color={buttonColor} outline={outline} disabled={isDisabled || this._isWorking} onClick={() => this._onClick(onClick)}>
                {title ?? type}
            </Button>
        );
    }

    @action.bound
    private async _onToggleDropdown() {
        this._isDropdownOpen = !this._isDropdownOpen;
    }

    private async _onClick(onClick: ClickHandler<T>) {
        const { window } = this.props;
        const result = onClick(window);
        if (result) {
            this._isWorking = true;
            try {
                await result;
            } finally {
                this._isWorking = false;
            }
        }
    }
}

@observer
export class ModalWindow<T> extends React.Component<ModalWindowProps<T>> {
    private _payload: T | null = null;
    private _focusButton: React.RefObject<HTMLButtonElement> = React.createRef();
    @observable public contentRef: React.RefObject<IModalDialogContent<T>> = React.createRef();

    constructor(props: ModalWindowProps<T>) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        this.props.onAppear?.(this);
        window.setTimeout(() => this._focusButton.current && this._focusButton.current.focus(), 0);
    }

    componentWillUnmount() {
        this.props.onHide?.(this);
    }

    render() {
        const { title, dialog, buttons, width } = this.props;
        const content = this.contentRef.current as IModalDialogContent<T>;

        const modalOptions = content?.getModalOptions?.call(content, this);

        const titleToRender = title ?? modalOptions?.title;
        const maxWidth = width ?? modalOptions?.width;
        const widthToRender = typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;
        const contentButtons = modalOptions?.buttons ?? [];
        const loader = modalOptions?.loader;
        const buttonsToRender = (buttons?.(this) ?? []).concat(contentButtons);
        const leftButtons = buttonsToRender.filter((b) => !!b.alignLeft);
        const normalButtons = buttonsToRender.filter((b) => !b.alignLeft);
        const footerPrefix = modalOptions?.footerPrefix;
        const hideCloseButton = modalOptions?.hideCloseButton;

        return (
            <Draggable handle=".modal-header">
                <Modal isOpen={true} backdrop="static" toggle={hideCloseButton ? undefined : () => this._onCloseClick(modalOptions)} keyboard={false} style={{ maxWidth: widthToRender }}>
                    <ModalHeader toggle={hideCloseButton ? undefined : () => this._onCloseClick(modalOptions)}>{titleToRender}</ModalHeader>
                    <ModalBodyWithBoundary className={modalOptions?.bodyClassName}>
                        <React.Suspense fallback={<Loading isSuspense />}>{dialog(this)}</React.Suspense>
                    </ModalBodyWithBoundary>
                    <Loading loading={loader?.isPending} />
                    {buttonsToRender.length || footerPrefix ? (
                        <ModalFooter>
                            {footerPrefix}
                            {leftButtons.length ? <div className="modal-footer-left">{this._renderButtons(leftButtons, modalOptions)}</div> : null}
                            {this._renderButtons(normalButtons, modalOptions)}
                        </ModalFooter>
                    ) : null}
                </Modal>
            </Draggable>
        );
    }

    private _renderButtons(buttons: ModalButtonOptions<T>[], options?: ModalDialogOptions<T>) {
        return buttons.map((b, index) => {
            let isFocused = buttons.length === 1 && !options?.disableAutoFocus;
            if (typeof b.setFocus === 'boolean') {
                isFocused = b.setFocus;
            } else if (!isFocused && !options?.disableAutoFocus) {
                const activeButtons = buttons.filter((b) => b.type !== ModalButtonType.Cancel && b.type !== ModalButtonType.Close);
                if (activeButtons.length === 1) {
                    isFocused = activeButtons[0].type === b.type;
                }
            }
            return <ModalButton<T> innerRef={isFocused ? this._focusButton : void 0} key={`${b.type}${index}`} {...b} window={this} />;
        });
    }

    @action.bound
    private async _onCloseClick(options?: ModalDialogOptions<T>) {
        if (!options?.confirmClose || (await options?.confirmClose())) this.props.onClose(ModalButtonType.Cancel, undefined);
    }

    public close(button: ModalAction = ModalButtonType.Close, result?: T) {
        this.props.onClose(button, result || this._payload || void 0);
    }

    public getPayload(): T | null {
        return this._payload;
    }

    public setPayload(payload: T | null) {
        this._payload = payload;
    }
}

class ModalService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @observable private _modalData: ModalWindowProps<any>[] = [];

    constructor() {
        applyDecorators(this);
    }

    @computed
    public get hasActiveModal() {
        return !!this._modalData.length;
    }

    public renderModals() {
        return this._modalData.map((m, index) => <ModalWindow key={'m' + index} {...m} />);
    }

    public show<P extends {}, T>(dialog: ClassType<P, React.Component<P>, React.ComponentClass<P>> | FunctionComponent<P>, props?: P, options?: Omit<ModalDialogOptions<T>, 'dialog'>): ModalPromise<T> {
        return this.showModal(
            Object.assign(
                {},
                {
                    dialog: (window: ModalWindow<T>) => {
                        const content = React.createElement(dialog, Object.assign({}, props, { ref: window.contentRef }));
                        return content;
                    }
                },
                options
            ) as ModalOptions<T>
        );
    }

    public showModal<T>(options: ModalOptions<T>): ModalPromise<T> {
        let data: ModalWindowProps<T> | null = null;
        const result = new Promise<ModalResult<T>>((resolve) => {
            data = Object.assign(options, {
                onClose: (button: ModalAction, result?: T) => {
                    resolve({
                        button: button,
                        result: result || null
                    });

                    const modalIndex = this._modalData.findIndex((d) => d === data);
                    if (modalIndex !== -1) {
                        runInAction(() => {
                            this._modalData.splice(modalIndex, 1);
                        });
                    }
                    if (!this._modalData.length) {
                        document.body.classList.remove('modal-open');
                    }
                }
            });
            if (!this._modalData.length) {
                document.body.classList.add('modal-open');
            }
            this._modalData.push(data);
            data = this._modalData[this._modalData.length - 1];
        });

        return Object.assign(result, {
            close: (action?: ModalAction, result?: T) => data?.onClose(action || ModalButtonType.Ok, result)
        });
    }

    public async showConfirmation(message: string | string[] | JSX.Element, titleOrOptions?: string | ConfirmationOptions, options?: ConfirmationOptions) {
        const confirmationOptions = typeof titleOrOptions === 'object' ? titleOrOptions : options;
        const result = await this.show(ConfirmationDialog, {
            message: message,
            title: typeof titleOrOptions === 'string' ? titleOrOptions : titleOrOptions?.title,
            color: confirmationOptions?.color || 'danger',
            onConfirm: confirmationOptions?.onConfirm
        });

        return result.button === ModalButtonType.Confirm;
    }

    public async showYesNo(message: string | string[] | JSX.Element, titleOrOptions?: string | ConfirmationOptions, options?: ConfirmationOptions) {
        const yesNoOptions = typeof titleOrOptions === 'object' ? titleOrOptions : options;
        const result = await this.show(YesNoDialog, {
            message: message,
            title: typeof titleOrOptions === 'string' ? titleOrOptions : titleOrOptions?.title,
            color: yesNoOptions?.color || 'primary',
            onConfirm: yesNoOptions?.onConfirm
        });

        return result.button === ModalButtonType.Yes;
    }

    public async showInformation(message: string | string[] | JSX.Element, title?: string, options?: { className?: string }) {
        await this.show(InformationDialog, {
            message: message,
            title: title,
            className: options?.className
        });
    }

    public showError = async (errorContent: string | string[] | JSX.Element | AxiosError) => {
        await this.show(ErrorDialog, {
            error: errorContent
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getUpperModal(): ModalWindowProps<any> {
        return this._modalData[this._modalData.length - 1];
    }
}

export const modalService = new ModalService();
