/* eslint-disable max-lines */
import React from 'react';
import {action, computed, IReactionDisposer, observable, when} from 'mobx';
import {observer} from 'mobx-react';
import DataGrid, {Column, ColumnChooser, Export, FilterRow, Format, Grouping, GroupPanel, HeaderFilter, Paging, Scrolling, SearchPanel, Selection, Sorting} from 'devextreme-react/data-grid';
import {Template} from 'devextreme-react/core/template';
import DataSource from 'devextreme/data/data_source';
import {DataGridBandColumn, DataGridColumn, DataGridColumnType} from './DxDataGridColumn';
import {DataGridColumnFieldType, OnRowPreparedHandler, DataGridColumnDisplayMode, DataGridSelectionMode, OnSelectionChangedHandler, OnCellPreparedHandler, OnFocusedRowChangedHandler, IGridFilter, ColumnData, GridState, OnCellPreparedArgs, HeaderCell, OnExportingHandler, OnRowClickHandler} from './DxDataGridTypes';
import DxDataGridToolbar, { DxDataGridToolbarProps, Toolbar } from './DxDataGridToolbar';
import { applyDecorators } from '@app/helpers/Decorator';
import DxGridHelper from '@app/helpers/DxGridHelper';
import DateTimeService from '@app/services/DateTimeService';
import { DateTime } from '@app/appConstants/DateTime';
import { DevExtremeCssStatuses, dxCssService } from '@app/services/DevExtremeCssService';
import { Loading } from '../Loading';

type RowData = {};

type CellData<T> = {
    column: ColumnData;
    columnIndex: number;
    rowType: 'data' | 'header';
    isAltRow: boolean;
    cellElement: React.ReactNode;
    data: T;
    displayValue: string | Date;
    isEditing: boolean;
    row: RowData;
    rowIndex: number;
    text: string;
    value: string | Date;
};

type DataGridStyle = {
    height?: string;
    maxHeight?: string;
};

type StateStoringOptions = {
    customLoad?: () => Promise<unknown>;
    customSave?: (gridState: GridState) => Promise<unknown> | void;
    enabled?: boolean;
    savingTimeout?: number;
    storageKey?: string;
    type?: 'custom' | 'localStorage' | 'sessionStorage';
};

type IDxDataGridProps<T extends {}> = {
    dataSource?: DataSource;
    columns: DataGridColumn<T>[];
    userConfigGroup?: string | null;
    isPrintMode: boolean | false;
    isHistorizedMode?: boolean;
    exportBaseFileName?: string;
    onCellPrepared?: OnCellPreparedHandler<T>;
    allowServerSideFiltering?: boolean;
    onRowClick?: OnRowClickHandler<T>;
    onRowPrepared?: OnRowPreparedHandler<T>;
    onSelectionChanged?: OnSelectionChangedHandler<T>;
    onFocusedRowChanged?: OnFocusedRowChangedHandler<T>;
    onExporting?: OnExportingHandler<T>;
    selectionMode?: DataGridSelectionMode;
    disableExporting?: boolean;
    disablePrinting?: boolean;
    disableGrouping?: boolean;
    disableSorting?: boolean;
    disableColumnChooser?: boolean;
    disableFilterRow?: boolean;
    disableHeaderFilter?: boolean;
    fillRestHeight?: boolean;
    maxHeight?: string;
    customToolBarRender?: () => JSX.Element;
    customToolBarLocation?: 'before' | 'center' | 'after';
    repaintChangesOnly?: boolean;
    highlightChanges?: boolean;
    dataGridRef?: React.RefObject<DataGrid>;
    highlightFocusedRow?: boolean;
    enableHoverState?: boolean;
    defaultFilters?: IGridFilter[];
    disableVirtualization?: boolean;
    headerTextClassName?: string;
};

@observer
export default class DxDataGrid<T extends {}> extends React.Component<IDxDataGridProps<T>> {
    private readonly _stateStoringOptions: StateStoringOptions = {
        enabled: true,
        type: 'custom',
        savingTimeout: 1000,
    };
    private _gridRef: React.RefObject<DataGrid> = React.createRef();
    private _whenDisposer: IReactionDisposer | null = null;

    @observable private _gridWrapper: React.RefObject<HTMLDivElement> = React.createRef();
    @observable private _gridHeight: number;
    @observable.ref private _customColumns: DataGridColumn<T>[] = [];
    @observable.ref private _toolbar: DxDataGridToolbar<T>;
    @observable private _maxHeight;
    @observable private _fillRestHeight;

    constructor(props: IDxDataGridProps<T>) {
        super(props);
        applyDecorators(this);
        this._maxHeight = props.maxHeight;
        this._fillRestHeight = props.fillRestHeight;

        this._customColumns = this.props.columns;
        this._setColumnNames(this._customColumns);

        const toolbarProps: DxDataGridToolbarProps<T> = {
            onCustomToolBarRender: this.props.customToolBarRender,
            customToolBarLocation: this.props.customToolBarLocation,
            customColumns: this._customColumns,
            onConfigReset: this._onConfigReset,
            onFiltersReset: this._onFiltersReset,
            isHistorizedMode: this.props.isHistorizedMode
        };
        this._toolbar = new DxDataGridToolbar<T>(toolbarProps);
        //this._onCellPrepared = this._onCellPrepared.bind(this);
        dxCssService.addItem(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this._calcGridHeight);

        this._whenDisposer = when(
            () => !!this._gridWrapper.current,
            () => {
                if (!this.props.isPrintMode) this._calcGridHeight();
            },
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._calcGridHeight);
        dxCssService.removeItem(this);
        if (this._whenDisposer) {
            this._whenDisposer();
        }
    }

    componentDidUpdate(prevProps: IDxDataGridProps<T>) {
        this._maxHeight = this.props.maxHeight;
        this._fillRestHeight = this.props.fillRestHeight;
        if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)) {
            this._customColumns = this.props.columns;
            this._setColumnNames(this._customColumns);
            this._updateToolbarProps();
        }

        if (this.props.isHistorizedMode !== prevProps.isHistorizedMode) {
            this._updateToolbarProps();
        }
    }

    private _updateToolbarProps() {
        const toolbarProps: DxDataGridToolbarProps<T> = {
            onCustomToolBarRender: this.props.customToolBarRender,
            customToolBarLocation: this.props.customToolBarLocation,
            customColumns: this._customColumns,
            onConfigReset: this._onConfigReset,
            onFiltersReset: this._onFiltersReset,
            isHistorizedMode: this.props.isHistorizedMode
        };

        this._toolbar.updateProps(toolbarProps);
    }

    @computed
    private get dataGridStyle(){
        const dataGridStyle: DataGridStyle = {};
        if (this._maxHeight) {
            dataGridStyle.maxHeight = this._maxHeight;
        } else {
            dataGridStyle.height = '100%';
        }
        return dataGridStyle;
    }

    @computed
    private get wrapperStyle(): React.CSSProperties{
        return { height: this._fillRestHeight ? this._gridHeight : '100%' } ;
    }

    render() {
        if (!this._isCssLoaded) {
            return (
                <Loading loading small/>
            );
        }

        const {
            isPrintMode,
            dataSource,
            onRowClick,
            onRowPrepared,
            allowServerSideFiltering,
            selectionMode,
            onSelectionChanged,
            disableVirtualization,
            disableExporting,
            disableGrouping,
            disableSorting,
            disableColumnChooser,
            customToolBarRender,
            disableFilterRow,
            disableHeaderFilter,
            repaintChangesOnly,
            highlightChanges,
            highlightFocusedRow,
            onFocusedRowChanged,
            dataGridRef,
            enableHoverState,
        } = this.props;

        let className = `dx-custom-data-grid${isPrintMode ? ' print-mode' : ''}`;
        if (!highlightFocusedRow) {
            className += ' dx-datagrid-focus-highlight-disabled';
        }

        return (
            <div ref={this._gridWrapper} style={this.wrapperStyle}>
                <DataGrid
                    style={this.dataGridStyle}
                    className={className}
                    ref={dataGridRef || this._gridRef}
                    dataSource={dataSource}
                    columnAutoWidth={true}
                    width={'100%'}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    allowColumnReordering={!isPrintMode}
                    rowAlternationEnabled={true}
                    onToolbarPreparing={(e) => this._createToolbar(e)}
                    stateStoring={this._stateStoringOptions}
                    remoteOperations={allowServerSideFiltering}
                    wordWrapEnabled={true}
                    onRowClick={onRowClick}
                    onRowPrepared={onRowPrepared}
                    onCellPrepared={(e) => this._onCellPrepared(e)}
                    onSelectionChanged={onSelectionChanged}
                    onFocusedRowChanged={onFocusedRowChanged}
                    columnResizingMode={'widget'}
                    repaintChangesOnly={repaintChangesOnly}
                    highlightChanges={highlightChanges}
                    focusedRowEnabled={highlightFocusedRow}
                    hoverStateEnabled={enableHoverState}
                >
                    <Grouping contextMenuEnabled={true}/>
                    <ColumnChooser enabled={!disableColumnChooser} mode="select" title="Column Visibility"/>
                    <GroupPanel visible={!isPrintMode && !disableGrouping}/>
                    <HeaderFilter visible={!disableHeaderFilter} searchMode="equals"/>
                    <FilterRow visible={!disableFilterRow}/>
                    <SearchPanel visible={!isPrintMode}/>
                    <Export enabled={!isPrintMode && !disableExporting}/>
                    <Sorting mode={disableSorting ? 'none' : 'multiple'}/>
                    <Scrolling mode={disableVirtualization ? 'standard' : 'virtual'} rowRenderingMode={disableVirtualization ? 'standard' : 'virtual'} preloadEnabled/>
                    <Paging enabled={false}/>
                    <Selection mode={selectionMode} showCheckBoxesMode="always"/>

                    {this._renderColumns(this._customColumns)}
                    {customToolBarRender && <Template name="customToolBar" render={customToolBarRender}/>}
                </DataGrid>
            </div>
        );
    }

    private _onCellPrepared(cellPreparedEvent: OnCellPreparedArgs<T>) {
        if (cellPreparedEvent.rowType === 'header' && cellPreparedEvent.column?.command === 'select' && cellPreparedEvent.cellElement) {
            const getGridInstance = () => (this.props.dataGridRef || this._gridRef).current?.instance;
            DxGridHelper.replaceSelectAllClickHandler(cellPreparedEvent.cellElement, getGridInstance);
        }
        this.props.onCellPrepared?.(cellPreparedEvent);
    }

    @action.bound
    private _onConfigReset() {
        this._resetColumnsWidthToDefault(this._customColumns);
        this._customColumns = [...this._customColumns];
    }

    @action.bound
    private _onFiltersReset(gridState: GridState) {
        this._setColumnsWidth(this._customColumns, gridState.columns);
        this._customColumns = [...this._customColumns];
    }

    private _resetColumnsWidthToDefault(columns: DataGridColumn<T>[]) {
        for (const column of columns) {
            column.width = column.defaultWidth;
            if (column.columns && column.columns.length > 0) {
                this._resetColumnsWidthToDefault(column.columns);
            }
        }
    }

    private _setColumnsWidth(columns: DataGridColumn<T>[], gridColumnsState: ColumnData[]) {
        for (const column of columns) {
            const colState = gridColumnsState.find((x) => x.name === column.name);
            if (colState) {
                column.width = colState.width;
            }
            if (column.columns && column.columns.length > 0) {
                this._setColumnsWidth(column.columns, gridColumnsState);
            }
        }
    }

    @action.bound
    private _createToolbar(e: Toolbar) {
        this._toolbar.createToolbar(e, this.props.isPrintMode);
    }

    private _setColumnNames(columns: DataGridColumn<T>[]) {
        for (const column of columns) {
            column.name = this._getColumnName(column);
            if (column.columns && column.columns.length > 0) {
                this._setColumnNames(column.columns);
            }
        }
    }

    private _getColumnName(column: DataGridColumn<T>): string {
        const key = column.dataFieldName ? `${column.dataFieldName.toString()}_${column.caption.replace(' ', '_')}` : `${column.caption.replace(' ', '_')}_${column.columnType}`;
        return key + (column.columns ? `_${column.columns.map((c) => c.dataFieldName).join('_')}` : '');
    }

    private _renderColumns(columnDescriptions: DataGridColumn<T>[]): JSX.Element[] {
        const elements: JSX.Element[] = [];
        columnDescriptions.map((x) => {
            if (x.columnType === DataGridColumnType.Band) {
                elements.push(this._renderBandColumn(x));
            } else {
                elements.push(this._renderDataColumn(x));
            }
        });

        return elements;
    }

    private _renderBandColumn(x: DataGridBandColumn<T>): JSX.Element {
        const keyValue = this._getColumnName(x);

        const shouldBeExported = x.displayMode?.has(DataGridColumnDisplayMode.Export);
        return (
            <Column key={keyValue} caption={x.caption} alignment={x.contentAlignment} allowSearch={false} allowExporting={shouldBeExported}>
                {x.columns && this._renderColumns(x.columns)}
            </Column>
        );
    }

    private _renderDataColumn(x: DataGridColumn<T>): JSX.Element {
        const isVisibleByDisplayMode = x.displayMode && x.displayMode.has(this.props.isPrintMode ? DataGridColumnDisplayMode.Print : DataGridColumnDisplayMode.UI);
        const shouldBeExported = x.displayMode?.has(DataGridColumnDisplayMode.Export);
        let filterExpr: ((filterValue: CellData<T>, selectedFilterOperation: string, target: string) => unknown) | undefined;

        if (this.props.allowServerSideFiltering) {
            if (x.dataFieldType === DataGridColumnFieldType.date || x.dataFieldType === DataGridColumnFieldType.dateTime) {
                filterExpr = (filterValue, selectedFilterOperation) => this._dateFilterExpression(filterValue, selectedFilterOperation, x);
            } else if (x.calculateFilterExpression) {
                filterExpr = x.calculateFilterExpression;
            } else {
                filterExpr = (filterValue, selectedFilterOperation) => this._defaultFilterExpression(filterValue, selectedFilterOperation, x);
            }
        }
        const allowSearch = x.dataFieldName ? (x.dataFieldType === DataGridColumnFieldType.string ? x.allowSearch : false) : false;
        const groupInterval = !x.groupInterval && x.dataFieldType === DataGridColumnFieldType.dateTime ? 'day' : x.groupInterval;

        return (
            <Column
                name={x.name}
                key={x.name}
                caption={x.caption}
                dataField={x.dataFieldName?.toString() || ''}
                dataType={x.dataFieldType}
                allowGrouping={x.allowGrouping}
                allowHiding={x.allowHiding}
                allowSearch={allowSearch}
                allowHeaderFiltering={x.allowDropdownFiltering}
                allowFiltering={x.allowSearchFiltering}
                filterOperations={x.allowedSearchFilterOperations}
                allowSorting={x.allowSorting}
                allowResizing={x.allowResizing}
                allowExporting={shouldBeExported}
                alignment={x.contentAlignment}
                cssClass={x.customCssClass}
                customizeText={x.customizeCellText}
                cellRender={x.cellRenderTemplate}
                headerFilter={{dataSource: x.headerFilterOptions, groupInterval}}
                headerCellRender={x.headerCellRender ? x.headerCellRender : this.onCellRender}
                calculateFilterExpression={filterExpr}
                visible={!x.isHidden && isVisibleByDisplayMode}
                width={this.props.isPrintMode ? (x.printWidth ? x.printWidth : x.width) : x.width}
                sortingMethod={x.enableCultureSpecificSorting ? this._cultureSpecificSorting : null}
                groupIndex={x.groupIndex}
                groupCellTemplate={x.groupCellTemplate}
                sortOrder={x.sortOrder}
            >
                {(x.dataFieldType === DataGridColumnFieldType.date || x.dataFieldType === DataGridColumnFieldType.dateTime) && (
                    <Format
                        formatter={(date: Date) => {
                            if (x.dataFieldType === DataGridColumnFieldType.date) {
                                return this._customDateFormat(date);
                            } else {
                                return DateTimeService.format(date, DateTime.viewFullFormat);
                            }
                        }}
                        parser={(value: string) => {
                            if (x.dataFieldType === DataGridColumnFieldType.date) {
                                return DateTimeService.parseUiDate(value);
                            } else {
                                return DateTimeService.parseUiDateTime(value);
                            }
                        }}
                    />
                )}
            </Column>
        );
    }
    
    private onCellRender = (data: HeaderCell<T>) => {
        const column = this._customColumns.find(x => x.dataFieldName === data.column?.dataField);
        return <p className={this.props.headerTextClassName} title={column?.tooltip || data.column?.caption}>{data.column?.caption}</p>;
    };

    private static _formatDate(value: Date) {
        const year = value.getFullYear();
        const month = value.getMonth() + 1;
        const day = value.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    }

    private static _formatDateTime(value: Date) {
        const date = this._formatDate(value);
        const h = value.getHours();
        const m = value.getMinutes();
        return `${date} ${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
    }

    private static _parseFilterValueToDate(filterValue: unknown) {
        let date: Date;
        if (Object.prototype.toString.call(filterValue) === '[object Date]') {
            date = filterValue as Date;
        } else {
            date = new Date(Date.parse(filterValue as string));
        }
        return date;
    }

    private _dateFilterExpression(filterValue: unknown, selectedFilterOperation: string, column: DataGridColumn<T>): unknown {
        let date1: Date | null = null;
        let date2: Date | null = null;
        let operation = selectedFilterOperation;
        if (Array.isArray(filterValue)) {
            if (filterValue[0]) {
                date1 = DxDataGrid._parseFilterValueToDate(filterValue[0]);
            }
            if (filterValue.length > 1 && filterValue[1]) {
                date2 = DxDataGrid._parseFilterValueToDate(filterValue[1]);
            }
        } else if (!filterValue) {
            return [`${column.dataFieldName as string}`, '=', null];
        } else if ((filterValue + '').match(/^\d+$/g)) {
            date1 = DxDataGrid._parseFilterValueToDate(filterValue);
            date2 = new Date(date1.getFullYear() + 1, date1.getMonth(), date1.getDate() - 1);
            operation = 'between';
        } else if ((filterValue + '').match(/^\d+[\/]\d+$/g)) {
            date1 = DxDataGrid._parseFilterValueToDate(filterValue + '/1');
            date2 = new Date(date1.getFullYear(), date1.getMonth() + 1, date1.getDate() - 1);
            operation = 'between';
        } else {
            date1 = DxDataGrid._parseFilterValueToDate(filterValue);
            if (column.dataFieldType === DataGridColumnFieldType.dateTime && date1) {
                const filterExpression: Array<string[] | string> = [];
                if (Object.prototype.toString.call(filterValue) === '[object Date]') {
                    const newValue1 = DxDataGrid._formatDateTime(date1);
                    filterExpression.push([`${column.dataFieldName as string}`, selectedFilterOperation || '=', newValue1]);
                } else {
                    const newValue1 = DxDataGrid._formatDate(date1);
                    filterExpression.push([`${column.dataFieldName as string}`, '>=', newValue1]);
                    filterExpression.push('and');
                    const newValue2 = DxDataGrid._formatDate(new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 1));
                    filterExpression.push([`${column.dataFieldName as string}`, '<', newValue2]);
                }
                return filterExpression;
            }
        }
        if (operation === 'between') {
            let newValue1: string = '';
            let newValue2: string = '';
            const filterExpression: Array<string[] | string> = [];

            if (date1) {
                newValue1 = DxDataGrid._formatDate(date1);
                filterExpression.push([`${column.dataFieldName as string}`, '>=', newValue1]);
            }
            if (date2) {
                if (date1) filterExpression.push('and');
                newValue2 = DxDataGrid._formatDate(date2);
                filterExpression.push([`${column.dataFieldName as string}`, '<=', newValue2]);
            }
            return filterExpression;
        } else {
            if (date1) {
                const newValue = DxDataGrid._formatDate(date1);
                return [[`${column.dataFieldName as string}`, selectedFilterOperation || 'contains', newValue]];
            }
        }
    }

    private _defaultFilterExpression(filterValue: unknown, selectedFilterOperation: string, column: DataGridColumn<T>): unknown {
        return [[`${column.dataFieldName as string}`, selectedFilterOperation || 'contains', filterValue]];
    }

    private _customDateFormat(date: Date) {
        return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}.${date.getFullYear()}`;
    }

    private _cultureSpecificSorting(value1: string, value2: string) {
        if (!value1 && value2) return -1;
        if (!value1 && !value2) return 0;
        if (value1 && !value2) return 1;
        return value1.localeCompare(value2);
    }

    private _getNotExportingColumns(columns: DataGridColumn<T>[]): DataGridColumn<T>[] {
        const result: DataGridColumn<T>[] = [];
        for (const col of columns) {
            if (!col.displayMode?.has(DataGridColumnDisplayMode.Export)) {
                result.push(col);
            }
            if (col.columns) {
                result.push(...this._getNotExportingColumns(col.columns));
            }
        }

        return result;
    }

    @action.bound
    private _calcGridHeight() {
        if (this._gridWrapper.current) {
            let margin = 48;

            const isModal = this._gridWrapper.current.closest('.modal');
            if (isModal) {
                margin = 300;
            }

            const offsetTop = this._gridWrapper.current.offsetTop;
            this._gridHeight = document.documentElement.clientHeight - offsetTop - margin;
        }
    }

    private _correctColumnsWidth(columns: DataGridColumn<T>[], gridColumnsState: ColumnData[]) {
        const resultColumns: DataGridColumn<T>[] = [];
        for (const col of columns) {
            const stateColumn = this._findGridStateColumn(gridColumnsState, this._getColumnName(col));
            const resColumn: DataGridColumn<T> = Object.assign({}, col);
            if (stateColumn?.width) {
                resColumn.width = stateColumn.width;
            }

            resultColumns.push(resColumn);

            if (col.columns && col.columns.length > 0) {
                resColumn.columns = this._correctColumnsWidth(col.columns, gridColumnsState);
            }
        }
        return resultColumns;
    }

    private _findGridStateColumn(gridColumnsState: ColumnData[], searchName: string) {
        return gridColumnsState.find((s: ColumnData) => s.name === searchName);
    }

    private _updateColumnVisibility(gridState: GridState) {
        const hiddenInPrintMode = new Set(this._customColumns.filter((x) => x.displayMode && !x.displayMode.has(DataGridColumnDisplayMode.Print)).map((x) => this._getColumnName(x)));
        const visibleOnlyInPrintMode = new Set(this._customColumns.filter((x) => x.displayMode && x.displayMode.has(DataGridColumnDisplayMode.Print) && !x.displayMode.has(DataGridColumnDisplayMode.UI)).map((x) => this._getColumnName(x)));
        gridState.columns.forEach((x) => {
            if (hiddenInPrintMode.has(x.name)) {
                x.visible = false;
            } else if (visibleOnlyInPrintMode.has(x.name)) {
                x.visible = true;
            }
            //if column has UI display mode then do nothing because user can set column as invisible and we should't show such column in print mode
        });
    }

    
    @computed
    private get _isCssLoaded() {
        return dxCssService.status === DevExtremeCssStatuses.Loaded;
    }
}
