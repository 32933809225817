import { ApiUrls } from '@app/AppConstants';
import { appStore } from '@app/AppStore';
import ApiService from '@app/services/ApiService';

export class SignalRConnectionWrapper {
    public suppressReconnection?: boolean = false;
    private _hubConnection: signalR.HubConnection | null;
    private _onReconnect: () => void;

    constructor (hubConnection: signalR.HubConnection, onReconnect: () => void) {
        this._hubConnection = hubConnection;
        this._onReconnect = onReconnect;

        this._hubConnection.onclose(async () => {
            if (this.suppressReconnection) return;

            if (appStore.isAuthorized()) {
                try {
                    await ApiService.get(ApiUrls.PingUrl + '?signalr-reconnect', null);
                } catch {
                    // globalAjaxLoaderStore.showAppLoader('Connection to server was lost, reconnecting...');
                }
            }
            this._onReconnect();
        });
    }

    public async abortConnection () {
        this.suppressReconnection = true;
        await this._hubConnection?.stop();
    }
}