import React from 'react';

import { observer } from 'mobx-react';
import { action } from 'mobx';

import { modalService } from '@app/components/Modal/Modal';
import { CurrencyConversionDialog } from '@app/components/Dialogs/CurrencyConversionDialog';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';

type CurrencyConversionCellProps = {
    fromCurrency: string;
    toCurrency: string;
    rate?: number;
    onChange: () => void;
};

@observer
export class CurrencyConversionCell extends React.Component<CurrencyConversionCellProps, {}> {
    render() {
        const { rate } = this.props;
        return <td onClick={this._onEdit}>{rate ? Number(rate.toFixed(2)) : '~'}</td>;
    }

    @action.bound
    private _onEdit = async () => {
        const { fromCurrency, toCurrency, rate } = this.props;

        void modalService.show(CurrencyConversionDialog, {
            title: `Currency Conversion ${fromCurrency} - ${toCurrency}`,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            rate: rate,
            onSave: this._saveEdit
        });
    };

    @action.bound
    private _saveEdit = async (rate: number | string) => {
        const { fromCurrency, toCurrency, onChange } = this.props;

        await ApiService.postData(`${ApiUrls.ConfigurationConversionsUrl}`, {
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            rate: typeof(rate) === 'string' ? rate : rate.toFixed(8)
        });
        onChange();
    };
}
