import { DataGridColumn } from './DxDataGridColumn';

export interface IGridConfigState {
    gridStateJson: string;
}

export type CellData = {
    value?: unknown;
    valueText?: string;
    target?: string;
    groupInterval?: string | number
};

export enum DataGridColumnContentAlignment {
    left = 'left',
    center = 'center',
    right = 'right'
}

export enum DataGridColumnFieldType {
    number = 'number',
    string = 'string',
    date = 'date',
    boolean = 'boolean',
    object = 'object',
    dateTime = 'datetime'
}

export enum SearchFilterOperation {
    contains = 'contains',
    notcontains = 'notcontains',
    startswith = 'startswith',
    endswith = 'endswith',
    equal = '=',
    notequal = '<>',
    less = '<',
    greater = '>',
    lessorequal = '<=',
    greaterorequal = '>=',
    between = 'between'
}

export enum DataGridSelectionMode {
    single = 'single',
    multiple = 'multiple',
    none = 'none'
}

export enum DataGridColumnDisplayMode {
    UI = 'UI',
    Export = 'Export',
    Print = 'Print'
}

export class FilterOptionsItem {
    key: string;
    text: string;
    value: string[][] | string;
}

export type HeaderFilterPostProcessOptions = {
    dataSource?: { postProcess?: (data: FilterOptionsItem[]) => FilterOptionsItem[] };
};

export type ExportingHandlerArgs = {
    cancel?: boolean;
};

export type OnInitializedParams<T extends {}> = {
    component?: T;
    element?: HTMLElement;
};

export type CellInfo<TModel extends {}> = {
    data?: TModel;
    value?: unknown;
    rowIndex?: number;
};

export type HeaderCell<TModel extends {}> = {
    column?: DataGridColumnInfo;
};

export type OnRowPreparedArgs<TModel extends {}> = {
    data?: TModel;
    rowElement?: HTMLElement;
};

export type OnCellPreparedArgs<TModel extends {}> = {
    data?: TModel;
    column?: DataGridColumnInfo;
    cellElement?: Element;
    rowType?: string;
};

export type OnRowClickArgs<TModel extends {}> = {
    data: TModel;
    rowType?: string;
}

export type DataGridColumnInfo = {
    caption?: string;
    dataField?: string;
    command?: string;
};

export type OnRowPreparedHandler<TModel extends {}> = (e: OnRowPreparedArgs<TModel>) => unknown;
export type OnCellPreparedHandler<TModel extends {}> = (e: OnCellPreparedArgs<TModel>) => unknown;
export type OnRowClickHandler<TModel extends {}> = (e: OnRowClickArgs<TModel>) => unknown;

export type CustomizeExcellCellArgs<TModel extends {}> = {
    dataFieldName: string | null;
    data: TModel;
};

export type OnSelectionChangedArgs<TModel extends {}> = {
    selectedRowsData?: Array<TModel>;
};

export type OnSelectionChangedHandler<TModel extends {}> = (args: OnSelectionChangedArgs<TModel>) => unknown;

export type OnFocusedRowChangedArgs<T> = {
    row?: DataGridRowObject<T>;
};

export type DataGridRowObject<T> = {
    data?: T;
    rowType?: string;
};

export type OnFocusedRowChangedHandler<T> = (args: OnFocusedRowChangedArgs<T>) => unknown;

export type onDataRowReceivedHandler<T> = (data: T) => void;

export type ColumnFilterValue = string[][] & { columnIndex: number };

export type OnExportingHandler<T extends {}> = (args: ExportingHandlerArgs, columns: DataGridColumn<T>[]) => void;

export enum FilterType {
    Include = 'include',
    Exclude = 'exclude'
}

export class ColumnData {
    caption?: string;
    dataField?: string;
    filterType?: FilterType;
    filterValue?: string | number;
    filterValues?: ColumnFilterValue[] | string[];
    visible: boolean;
    visibleIndex: number;
    name: string;
    width?: number;
    groupIndex?: number;
}

export class ColumnDataExt extends ColumnData {
    displayMode?: DataGridColumnDisplayMode[];
}

export type GridState = {
    columns: ColumnDataExt[];
    searchText: string;
    focusedRowKey: string;
    selectedRowKeys: string[];
};

export enum FilterOperation {
    Equal = '=',
    NotEqual = '<>',
    Contains = 'contains'
}

export interface IGridFilter {
    filterType: FilterType;
    field: string;
    fieldType: DataGridColumnFieldType;
    values: {
        operation: FilterOperation;
        value: string;
    }[];
}

export type HeaderFilterPredefinedOption = {
    filterType: FilterType;
    values: {
        operation: FilterOperation;
        value: string;
    }[];
};

export type RowRemovingArg<T> = {
    model?: unknown;
    data?: T;
    key?: T;
    cancel?: boolean | Promise<void>;
};
