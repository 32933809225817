import React from 'react';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '../../Modal/Modal';
import { observer } from 'mobx-react';
import { applyDecorators } from '@app/helpers/Decorator';
import { action, observable, runInAction } from 'mobx';
import { Button, Col, Container, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { FormInput, FormSelect } from '../../FormControls';
import { BaseFormModel } from '../../BaseFormModel';
import { displayName, isDate, isDateAfter, isDateBefore, isFloatNumber, isGraterOrEqualThan, isGraterThan, isLessOrEqualThan, isRequired } from '@app/appConstants/Validation';
import ApiService from '@app/services/ApiService';
import { ApiUrls, Currency } from '@app/AppConstants';
import { AirportGroupProductPrice, AirportPriceOverwriteRule, CommissionModel, CreateAirportPriceOverwriteRuleModel } from '@app/models/WebApiModels';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import DateTimeService from '@app/services/DateTimeService';
import { Icon } from '../../Icon';
import { DatePickerValidatedControl } from '../../DateTimeControls/DateTimeControls';
import { SelectFilterItem } from '@app/models/SelectFilterItem';

import cls from './_priceConfigurationDialog.module.scss';
import { Currencies } from '@app/pages/ConfigurationPage/GeneralConfigurationPage/components/Currencies';

type PriceConfigurationDialogProps = {
    tab: PriceConfigurationTab;
    localCurrency?: string;
    baseCurrency: string;
    productName?: string;
    airportCode: string;
    productCode: string;
    zrhRelated: boolean;
    onChanged?: () => void;
    onCommissionChange?: () => void;
};

export enum PriceConfigurationTab {
    PriceOverrules,
    CommissionOverrules
}

@observer
export class PriceConfigurationDialog extends React.Component<PriceConfigurationDialogProps> implements IModalDialogContent<void> {
    private _loader = new PromiseCompletion();

    @observable private _props: PriceConfigurationDialogProps;
    @observable private _overwrites: AirportPriceOverwriteRule[] = [];
    @observable private _commissions: CommissionModel[] = [];
    @observable private _overruleForm: OverruleFormModel = new OverruleFormModel();
    @observable private _commissionForm: CommissionFormModel = new CommissionFormModel();
    @observable private _selectedTab: PriceConfigurationTab = PriceConfigurationTab.PriceOverrules;

    constructor(props: PriceConfigurationDialogProps) {
        super(props);
        applyDecorators(this);

        this._props = props;
        this._overruleForm.currency = this.props.zrhRelated ? Currency.ZRH : this.props.localCurrency ?? this.props.baseCurrency;
        this._selectedTab = this.props.tab;
    }

    componentDidMount(): void {
        void this._reloadOverwrites();
        void this._reloadCommissions();
    }

    public getModalOptions: (window: ModalWindow<void>) => ModalDialogOptions<void> = (window: ModalWindow<void>) => {
        return {
            title: this._props.productName,
            width: '640px',
            buttons: [
                {
                    type: ModalButtonType.Ok,
                    onClick: async () => {
                        window.close();
                    },
                    color: 'danger'
                }
            ]
        };
    };

    render() {
        return (
            <Container>
                <Nav className={cls.nav}>
                    <NavItem className={[cls.navItem, this._selectedTab === PriceConfigurationTab.PriceOverrules ? cls.active : ''].join(' ')}>
                        <NavLink onClick={() => runInAction(() => (this._selectedTab = PriceConfigurationTab.PriceOverrules))} className={cls.navLink}>
                            Price
                        </NavLink>
                    </NavItem>
                    <NavItem className={[cls.navItem, this._selectedTab === PriceConfigurationTab.CommissionOverrules ? cls.active : ''].join(' ')}>
                        <NavLink onClick={() => runInAction(() => (this._selectedTab = PriceConfigurationTab.CommissionOverrules))} className={cls.navLink}>
                            Commission
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this._selectedTab}>
                    <TabPane tabId={PriceConfigurationTab.PriceOverrules}>{this._renderPricesTab()}</TabPane>
                    <TabPane tabId={PriceConfigurationTab.CommissionOverrules}>{this._renderCommissionsTab()}</TabPane>
                </TabContent>
            </Container>
        );
    }

    private _renderPricesTab() {
        return (
            <Row className="mb-4 mt-2">
                <div className="mb-4">{this._renderPriceRules()}</div>
                <div style={{ paddingTop: '12px', borderTop: 'solid 1px #ddd' }}>{this._renderPriceForm()}</div>
            </Row>
        );
    }

    private _renderCommissionsTab() {
        return (
            <Row className="mb-4 mt-2">
                <div className="mb-4">{this._renderCommissionRules()}</div>
                <div style={{ paddingTop: '12px', borderTop: 'solid 1px #ddd' }}>{this._renderCommissionForm()}</div>
            </Row>
        );
    }

    private _renderPriceRules() {
        return (
            <>
                {this._overwrites.map((rule) => (
                    <React.Fragment key={rule.id}>{this._renderPriceRule(rule)}</React.Fragment>
                ))}
            </>
        );
    }

    private _renderPriceRule(rule: AirportPriceOverwriteRule) {

        return (
            <Row>
                <Col sm={4}>
                    {rule.amount} {rule.currency}
                    {rule.calculated ? ` (calculated)` : ''}:
                </Col>
                <Col sm={6}>{`${rule.dateFromUtc ? DateTimeService.toUiDate(rule.dateFromUtc) : '##.##.##'} - ${rule.dateToUtc ? DateTimeService.toUiDate(rule.dateToUtc) : '##.##.##'}`}</Col>
                {!rule.calculated && (
                    <Col sm={1}>
                        <Icon name="trash-can" style={{ cursor: 'pointer' }} onClick={() => void this._deleteRule(rule.id)} />
                    </Col>
                )}
            </Row>
        );
    }

    private _renderCommissionRules() {
        return (
            <>
                {this._commissions.map((rule) => (
                    <React.Fragment key={rule.id}>{this._renderCommissionRule(rule)}</React.Fragment>
                ))}
            </>
        );
    }

    private _renderCommissionRule(rule: CommissionModel) {
        return (
            <Row>
                <Col sm={4}>{(rule.percent ?? 0) * 100}</Col>
                <Col sm={6}>{`${rule.dateFromUtc ? DateTimeService.toUiDate(rule.dateFromUtc) : '##.##.##'} - ${rule.dateToUtc ? DateTimeService.toUiDate(rule.dateToUtc) : '##.##.##'}`}</Col>
                <Col sm={1}>
                    <Icon name="trash-can" style={{ cursor: 'pointer' }} onClick={() => void this._deleteCommissionRule(rule.id)} />
                </Col>
            </Row>
        );
    }

    private _renderPriceForm() {
        let options = (this._props.zrhRelated ? [Currency.ZRH] : this._props.baseCurrency !== this._props.localCurrency ? [this._props.baseCurrency, this.props.localCurrency] : [this._props.baseCurrency])
            .filter((x) => x)
            .map(
                (x) =>
                    ({
                        label: x,
                        value: x
                    } as SelectFilterItem)
            );

        return (
            <Row>
                <Col sm={4} style={{ marginRight: '10px' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <FormInput formModel={this._overruleForm} name="amount" smallValidationError />
                        </Col>
                        <Col>{options.length > 1 ? <FormSelect formModel={this._overruleForm} name="currency" options={options} /> : <Label>{options[0].label}</Label>}</Col>
                    </Row>
                </Col>
                <Col>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <DatePickerValidatedControl formModel={this._overruleForm} name="dateFrom" pickerMode="date" />
                        </Col>
                        -
                        <Col>
                            <DatePickerValidatedControl formModel={this._overruleForm} name="dateTo" pickerMode="date" />
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}>
                    <Button color="danger" disabled={!this._overruleForm.isFormValid} onClick={() => void this._addNewPriceRule()}>
                        <Icon name="add" />
                    </Button>
                </Col>
            </Row>
        );
    }

    private _renderCommissionForm() {
        return (
            <Row>
                <Col sm={4} style={{ marginRight: '10px' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <FormInput formModel={this._commissionForm} name="percent" />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <DatePickerValidatedControl formModel={this._commissionForm} name="dateFrom" pickerMode="date" />
                        </Col>
                        -
                        <Col>
                            <DatePickerValidatedControl formModel={this._commissionForm} name="dateTo" pickerMode="date" />
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}>
                    <Button color="danger" disabled={!this._commissionForm.isFormValid} onClick={() => void this._addNewCommissionRule()}>
                        <Icon name="add" />
                    </Button>
                </Col>
            </Row>
        );
    }

    @action.bound
    private async _addNewPriceRule() {
        await ApiService.postData(
            ApiUrls.PriceUrl + '/airport',
            {
                airportCode: this.props.airportCode,
                productCode: this.props.productCode,
                amount: this._overruleForm.amount,
                currency: this._overruleForm.currency,
                dateFromUtc: this._overruleForm.dateFrom ? DateTimeService.parseUiDate(this._overruleForm.dateFrom) : undefined,
                dateToUtc: this._overruleForm.dateTo ? DateTimeService.parseUiDate(this._overruleForm.dateTo) : undefined,
                zrhRelated: this.props.zrhRelated
            } as CreateAirportPriceOverwriteRuleModel,
            {
                completion: this._loader
            }
        );

        await this._reloadOverwrites();
        this.props.onChanged?.();
    }

    @action.bound
    private async _addNewCommissionRule() {
        await ApiService.postData(
            ApiUrls.CommissionsUrl,
            {
                percent: this._commissionForm.percent,
                airportCode: this._props.airportCode,
                productCode: this._props.productCode,
                dateFromUtc: this._commissionForm.dateFrom ? DateTimeService.parseUiDate(this._commissionForm.dateFrom) : undefined,
                dateToUtc: this._commissionForm.dateTo ? DateTimeService.parseUiDate(this._commissionForm.dateTo) : undefined
            } as CommissionModel,
            {
                completion: this._loader
            }
        );

        await this._reloadCommissions();
        this.props.onChanged?.();
    }

    @action.bound
    private async _deleteRule(ruleId: string) {
        await ApiService.deleteData(ApiUrls.PriceUrl + `/airport/${ruleId}?zrhRelated=${this.props.zrhRelated}`, null, {
            completion: this._loader
        });

        await this._reloadOverwrites();
        this.props.onChanged?.();
    }

    @action.bound
    private async _deleteCommissionRule(ruleId: string) {
        await ApiService.deleteData(ApiUrls.CommissionsUrl + '/' + ruleId, null, {
            completion: this._loader
        });

        await this._reloadCommissions();
        this.props.onChanged?.();
    }

    @action.bound
    private async _reloadOverwrites() {
        const { data } = await ApiService.getTypedData<AirportGroupProductPrice>(ApiUrls.AirportUrl + `/${this.props.airportCode}/products/${this.props.productCode}?zrhRelatedOverwrites=${this.props.zrhRelated}`, null, {
            completion: this._loader
        });
        this._overwrites = data.overwrites ?? [];
    }

    @action.bound
    private async _reloadCommissions() {
        const { data } = await ApiService.getTypedData<CommissionModel[]>(ApiUrls.CommissionsUrl + `/${this.props.airportCode}/${this.props.productCode}`, null, {
            completion: this._loader
        });

        this._commissions = data;
    }
}

class CommissionFormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @isRequired()
    @isFloatNumber()
    @isGraterOrEqualThan(0)
    @isLessOrEqualThan(100)
    @displayName('Percent')
    @observable
    percent?: number = 0;

    @observable
    @isDate()
    @isDateBefore('dateTo')
    dateFrom?: string = '';

    @observable
    @isDate()
    @isDateAfter('dateFrom')
    dateTo?: string = '';
}

class OverruleFormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @observable
    @isFloatNumber()
    @isRequired()
    @isGraterThan(0)
    amount: number;

    @observable
    @isRequired()
    currency: string;

    @observable
    @isDate()
    @isDateBefore('dateTo')
    dateFrom?: string = '';

    @observable
    @isDate()
    @isDateAfter('dateFrom')
    dateTo?: string = '';
}
