export class Routing {
    public static readonly Home: string = '/';
    public static readonly Result: string = '/result';
    public static readonly Configuration: string = '/configuration';
}

export class ApiUrls {
    public static readonly AirportUrl: string = '/airport';
    public static readonly CommissionsUrl: string = '/commission';
    public static readonly ConfigurationUrl: string = '/configuration';
    public static readonly CurrencyUrl: string = '/currency';
    public static readonly PaymentUrl: string = '/payment';
    public static readonly PriceUrl: string = '/price';
    public static readonly ProductUrl: string = '/product';
    public static readonly PingUrl: string = '/api/dev/ping';
    public static readonly ConfigurationConversionsUrl: string = '/configuration/conversions';

    public static readonly EmailsUrl = '/api/email';
    public static readonly EmailBodyUrl = '/api/email/body';
    public static readonly EmailAttachmentsUrl = '/api/email/attachments';
    public static readonly EmailAttachmentDownloadUrl = '/api/email/attachment';

    public static readonly JobList: string = '/api/job/list';
    public static readonly JobStopUrl: string = '/api/job/stop';
    public static readonly JobStartUrl: string = '/api/job/start';
    public static readonly JobParametersUrl: string = '/api/job/parameters';
    public static readonly JobTriggerCreateUrl: string = '/api/job/create';
    public static readonly JobTriggerDeleteUrl: string = '/api/job/delete';
    public static readonly CheckTriggerNameUrl: string = '/api/job/check-trigger-name';
    public static readonly JobCronValidation: string = '/api/job/cron/validation';
}

export class SignalREvents {
    public static readonly jobStatusChanged: string = 'jobStatusChanged';
    public static readonly jobProgressMaxValueChanged: string = 'jobProgressMaxValueChanged';
    public static readonly jobProgressValueChanged: string = 'jobProgressValueChanged';
    public static readonly sendJobTimeline: string = 'sendJobTimeline';
}

export class StoredKeys {
    public static readonly Token: string = 'currentToken';
    public static readonly User: string = 'currentUser';
    public static readonly ActiveTab: string = 'tabIndex';
}

export class TransactionTypes {
    public static readonly Datatrans = 1;
    public static readonly Sts = 2 // For mobile app only, do not use
}

export class ParameterType {
    public static readonly DateTime: string = 'DateTime';
    public static readonly Date: string = 'Date';
    public static readonly Boolean: string = 'Boolean';
    public static readonly MultiLine: string = 'Multiline';
    public static readonly SingleLine: string = 'String';
    public static readonly Integer: string = 'Integer';
    public static readonly Enum: string = 'Enum';
}

export class Roles {
    public static readonly Station = "0";
    public static readonly Ground = "1";
    public static readonly Commercial = "2";
    public static readonly Admin = "3";
}

export class Currency {
    public static readonly ZRH = "CHF"
}