export class ComponentUrls {
    public static readonly HubUrl: string = '/hubs/notification';
    public static readonly QsImpersonatedPersonId: string = 'impersonate-p';
    public static readonly QsViewPersonId: string = 'view-p';
    public static readonly QsImpersonatedRoles: string = 'impersonate-r';
    public static readonly QsToken: string = 'token';
}

export class SignalRGroups {
    public static readonly Job: string = ':Internal:JOB';
}