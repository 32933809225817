import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup, Label, Row } from 'reactstrap';

import { applyDecorators } from '@app/helpers/Decorator';
import { ButtonColor, IModalDialogContent, ModalButtonType, ModalWindow } from '../Modal/Modal';
import { BaseFormModel } from '../BaseFormModel';
import { displayName, isFloatNumber, isGraterThan, isRequired } from '@app/appConstants/Validation';
import { FormInput } from '../FormControls';


type CurrencyConversionDialogProps = {
    title?: string;
    fromCurrency: string;
    toCurrency: string;
    rate?: number;
    message?: string | string[] | JSX.Element;
    onSave?: (rate: number) => void | Promise<void>;
};

@observer
export class CurrencyConversionDialog extends React.Component<CurrencyConversionDialogProps> implements IModalDialogContent<void> {
    @observable private _form: FormModel;
    @observable private _props: CurrencyConversionDialogProps;

    constructor(props: CurrencyConversionDialogProps) {
        super(props);
        applyDecorators(this);

        this._props = props;

        const { rate } = this.props;

        this._form = new FormModel();
        this._form.rate = rate;
    }

    public getModalOptions = (window: ModalWindow<void>) => {
        const isFormValid = this._form.isFormValid;

        return {
            title: this._props.title,
            width: '640px',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Confirm,
                    onClick: async () => {
                        if (this._props.onSave) {
                            await this._props.onSave(this._form.rate!);
                        }
                        window.close(ModalButtonType.Confirm);
                    },
                    color: 'danger' as ButtonColor, 
                    isDisabled: !this._form.rate || !isFormValid
                }
            ]
        };
    };

    render() {
        const { fromCurrency, toCurrency } = this.props;

        return (
            <div>
                <Row>
                    <Label>
                        <strong>From:</strong> {fromCurrency}
                    </Label>
                </Row>
                <Row>
                    <Label>
                        <strong>To:</strong> {toCurrency}
                    </Label>
                </Row>
                <Row>
                    <FormGroup>
                        <Label>Conversion rate:</Label>
                        <FormInput name="rate" formModel={this._form} />
                    </FormGroup>
                </Row>
            </div>
        );
    }
}

class FormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @isRequired()
    @isFloatNumber()
    @displayName('Conversion rate')
    @isGraterThan(0)
    @observable
    rate?: number = 0;
}
