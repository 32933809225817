import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { Badge } from 'reactstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import { applyDecorators } from '@app/helpers/Decorator';
import { extensions } from '@app/services/Extensions';

pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.js';

type PDFDocumentProxy = {
    numPages: number;
    getData: () => Promise<BinaryData>;
};

type PdfViewerProps = {
    pdfData: BinaryData | null;
    onLoadError?: () => void;
    onRenderPages?: (index: number, renderCallback: (index: number) => JSX.Element) => void;
};

@observer
export default class PdfViewer extends React.Component<PdfViewerProps> {
    @observable private _pagesNumber: number = 0;
    @observable private _pageWidth: number = 0;
    @observable private _loadingError: Error | null = null;
    @observable private _pdfData: BinaryData | null;
    private _pdfWrapper: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    constructor(props: PdfViewerProps) {
        super(props);
        this._pdfData = props.pdfData;
        applyDecorators(this);
    }

    componentDidMount() {
        this._setSize();
        window.addEventListener('resize', this._onWindowResize);
    }

    // componentDidUpdate(prevProps: PdfViewerProps) {
    //     this._pdfData = this.props.pdfData;
    //     if (!prevProps.pdfData || !this.props.pdfData || !Buffer.from(prevProps.pdfData, 0).equals(Buffer.from(this.props.pdfData, 0))) {
    //         this._pagesNumber = 0;
    //     }
    // }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onWindowResize);
    }

    private _onWindowResize = () => {
        extensions.executeTimeout(this._setSize, 500, this);
    };

    private _setSize() {
        if (!this._pdfWrapper.current) return;
        this._pageWidth = this._pdfWrapper.current.getBoundingClientRect().width;

        const scrollbarWidthPx = 19;
        const containerPaddingPx = 3;
        const overflowWidth = scrollbarWidthPx + containerPaddingPx;
        this._pageWidth -= overflowWidth;
    }

    @computed
    private get source() {
        return this._pdfData ? { data: this._pdfData } : null;
    }

    render() {
        const { onRenderPages } = this.props;
        return (
            <div className={'pdf-container'} ref={this._pdfWrapper}>
                <Document file={this.source} noData={this.renderNoData()} loading={this.renderNoData()} onLoadSuccess={this._onLoadSuccess} onSourceError={this._onLoadError} onLoadError={this._onLoadError}>
                    {Array.from(new Array(this._pagesNumber), (_, index) => {
                        return onRenderPages ? onRenderPages(index, this.renderPage) : this.renderPage(index);
                    })}
                </Document>
                {this._loadingError && <Badge color="danger">{this._loadingError.message}</Badge>}
            </div>
        );
    }

    renderPage = (index: number) => {
        return <Page key={`page_${index + 1}`} pageNumber={index + 1} width={this._pageWidth || 400} />;
    };

    renderNoData() {
        return (
            <div className="alert alert-success" role="alert">
                Loading preview...
            </div>
        );
    }

    @action.bound
    private async _onLoadSuccess(doc: PDFDocumentProxy) {
        this._pagesNumber = doc.numPages;
        this._loadingError = null;
    }

    @action.bound
    private async _onLoadError(error: Error) {
        this._pagesNumber = 0;
        this._loadingError = error;
        this.props.onLoadError?.();
    }
}
