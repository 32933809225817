import React from 'react';
import { observer } from 'mobx-react';

import { applyDecorators } from '@app/helpers/Decorator';
import { TabItem, Tabs } from '@app/components';
import { GeneralConfigurationPage } from './GeneralConfigurationPage/GeneralConfigurationPage';
import { ReportsConfigurationPage } from './ReportsConfigurationPage/ReportsConfigurationPage';
import GroupsConfigurationPage from './GroupsConfigurationPage/GroupsConfigurationPage';

import 'react-datepicker/dist/react-datepicker.css';
import StationsConfigurationPage from './StationsConfigurationPage/StationsConfigurationPage';
import ProductsConfigurationPage from './ProductsConfigurationPage/ProductsConfigurationPage';
import EmailViewer from '../EmailViewer/EmailViewer';
import JobList from '../Job/JobList';
import { AzureAuth } from '@app/classes/AzureAuth';

@observer
export class ConfigurationPage extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        applyDecorators(this);
    }


    render(): React.ReactNode {

        let tabs: TabItem[] = [];
        if (AzureAuth.isCommercial) {
            tabs = [
                {
                    title: 'Products',
                    body: <ProductsConfigurationPage />,
                    order: 1
                },
                {
                    title: 'Groups',
                    body: <GroupsConfigurationPage />,
                    order: 2
                },
                {
                    title: 'Stations',
                    body: <StationsConfigurationPage />,
                    order: 3
                },
                {
                    title: 'General',
                    body: <GeneralConfigurationPage />,
                    order: 5
                }, 
                {
                    title: 'Email Viewer',
                    body: <EmailViewer />,
                    order: 6
                },
                {
                    title: 'Job List', 
                    body: <JobList />,
                    order: 7
                }
            ];
        }
        if (AzureAuth.isGround) {
            tabs.push({
                title: 'Reports',
                body: <ReportsConfigurationPage />,
                order: 4
            });
        }
        return <Tabs tabs={tabs.sort((a, b) => a.order - b.order)} />;
    }
}
