import { EmptyProps } from '@app/helpers/Decorator';
import React from 'react';
import ReactDOM from 'react-dom';

type ShadowDomProps = {
    className?: string, 
    children: JSX.Element | JSX.Element[]
};

export default class ShadowDom extends React.Component<ShadowDomProps> {
    private _ref: React.RefObject<HTMLDivElement> = React.createRef();
    private _shadowRoot?: ShadowRoot;

    constructor(props: ShadowDomProps) {
        super(props);
    }

    componentDidMount() {
        this._shadowRoot = this._ref.current?.attachShadow({ mode: 'open' });
        this.forceUpdate();
    }

    render() {
        return <div className={this.props.className} ref={this._ref}>{this._shadowRoot && ReactDOM.createPortal(this.props.children, this._shadowRoot as unknown as Element)}</div>;
    }
}
