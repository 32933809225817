import React from 'react';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import { AppRouter } from './App';
import { AppLoader } from './components';
import { BrowserRouter } from 'react-router-dom';
import { loaderStore } from './stores/LoaderStore';

import { Modals } from './components/Modal/Modal';
import { NotificationContainer } from './components/ToastNotification';

import './scss/App.scss';

window.global ||= window;

const rootContainer = document.getElementById('root') as Element;
const root = createRoot(rootContainer);

const AppWithCallbackAfterRender = () => {
    useEffect(() => {
        const loader = document.getElementById('react-app-loader');
        if (loader) {
            loaderStore.appLoaderElement = loader;
            loader.addEventListener(
                'transitionend',
                () => {
                    if (loader.classList.contains('hide')) {
                        loader.classList.add('invisible');
                    }
                },
                true
            );
            loaderStore.hideAppLoader();
        }
    });
    return (
        <BrowserRouter>
            <AppRouter />
            <AppLoader />
            <Modals />
            <NotificationContainer />
        </BrowserRouter>
    );
};

root.render(<AppWithCallbackAfterRender />);

window.addEventListener('load', async () => {
    if (navigator.serviceWorker) {
        try {
            const reg = await navigator.serviceWorker.register(`${window.location.origin}/sw.js`);
            console.log('SW:Registration successful with scope: ', reg.scope);
        } catch (e) {
            console.log('SW:Registration failed: ', e);
        }
    }
});
