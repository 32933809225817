import { observable, action, computed, makeObservable } from 'mobx';

import { ApiV1Job, JobStatusMessage, JobProgressValue, JobStatus, JobProgressMaxValue, TriggerExtended, JobMisfireInstruction } from '../Models/JobListModels';
import { signalRService } from '@app/services/SignalRService';
import { ApiUrls, SignalREvents } from '@app/AppConstants';
import ApiService from '@app/services/ApiService';
import DateTimeService from '@app/services/DateTimeService';
import { MatchComparer, stringSearchService } from '@app/services/StringSearchService';
import { SignalRGroups } from '@app/appConstants/SignalR';

export default class JobListStore {
    @observable jobs: ApiV1Job[] = [];
    @observable dataLoaded: boolean = false;
    @observable exceptionMessage: string = '';
    @observable jobsFilter: string = '';

    constructor() {
        makeObservable(this);
        signalRService.subscribeToGroup<JobStatusMessage>(SignalREvents.jobStatusChanged, this._onJobStatusChangedHandler, SignalRGroups.Job);
        signalRService.subscribeToGroup<JobProgressMaxValue>(SignalREvents.jobProgressMaxValueChanged, this._onJobProgressMaxValueChangedHandler, SignalRGroups.Job);
        signalRService.subscribeToGroup<JobProgressValue>(SignalREvents.jobProgressValueChanged, this._onJobProgressValueChangedHandler, SignalRGroups.Job);

        this._initJobs();
        this.jobsFilter = localStorage.getItem('JobsListFilter') || '';
    }

    @action.bound
    public unload() {
        signalRService.unsubscribeFromGroup<JobStatusMessage>(SignalREvents.jobStatusChanged, this._onJobStatusChangedHandler);
        signalRService.unsubscribeFromGroup<JobProgressMaxValue>(SignalREvents.jobProgressMaxValueChanged, this._onJobProgressMaxValueChangedHandler);
        signalRService.unsubscribeFromGroup<JobProgressValue>(SignalREvents.jobProgressValueChanged, this._onJobProgressValueChangedHandler);
    }

    @computed
    public get topExecutions() {
        return this.jobs
            .filter((j) => j.lastStatus === 'Pending')
            .map((j) => j.name)
            .slice(0, 3);
    }

    @computed
    public get topFailedJobs() {
        return this.jobs
            .filter((j) => j.lastStatus === JobStatus.Fail)
            .map((j) => j.name)
            .slice(0, 3);
    }

    @action
    public setFilter(value: string) {
        this.jobsFilter = value;
        localStorage.setItem('JobsListFilter', value);
    }

    @computed
    get textComparer() {
        const comparator: MatchComparer = (v, t, s) => {
            return stringSearchService.prepareString(v)?.indexOf(stringSearchService.prepareString(t) ?? '', s) ?? -1;
        };
        return comparator;
    }

    @computed
    get searchTerms() {
        const searchStr = this.jobsFilter;
        return searchStr.split(/[ ,]+/).filter((x) => !!x);
    }

    public filterJob(job: ApiV1Job) {
        let result = true;

        if (this.jobsFilter) {
            const filters = this.jobsFilter.toLowerCase().split(';');
            result = false;

            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];

                let matchByFilters = true;
                const filterParts = filter.split(' ');
                for (let j = 0; j < filterParts.length; j++) {
                    let filterPart = filterParts[j];

                    let isNegative = filterPart.startsWith('-');
                    if (isNegative) {
                        filterPart = filterPart.substring(1);
                        if (job.name.toLowerCase().indexOf(filterPart) !== -1 || (job.description && job.description.toLowerCase().indexOf(filterPart) !== -1)) {
                            matchByFilters = false;
                        }
                    } else {
                        if (job.name.toLowerCase().indexOf(filterPart) === -1 && (!job.description || (job.description && job.description.toLowerCase().indexOf(filterPart) === -1))) {
                            matchByFilters = false;
                        }
                    }
                }

                if (matchByFilters) {
                    result = true;
                }
            }
        }

        return result;
    }

    @action
    private async _initJobs() {
        this.dataLoaded = false;
        this.exceptionMessage = '';
        const { data } = await ApiService.get<{ status: string; startupException: string; jobs: ApiV1Job[] }>(ApiUrls.JobList);

        if (data.status === 'Started') {
            this.jobs = data.jobs || [];
            this.dataLoaded = true;
        } else if (data.status === 'FailedToStart') {
            this.exceptionMessage = data.startupException;
            this.dataLoaded = true;
        } else if (data.status !== 'FailedToStart') {
            window.setTimeout(() => {
                this._initJobs();
            }, 5000);
        }
    }

    private _findTrigger(triggerId: string) {
        for (let i = 0; i < this.jobs.length; i++) {
            const job = this.jobs[i];
            for (let j = 0; j < job.triggers.length; j++) {
                const trigger = job.triggers[j];
                if (trigger.name === triggerId) return trigger as TriggerExtended;
            }
        }
        return null;
    }

    @action.bound
    private _onJobStatusChangedHandler(_: string, jobStatus: JobStatusMessage) {
        const job = this.jobs.find((j) => j.name === jobStatus.name);
        let trigger = this._findTrigger(jobStatus.triggerId);
        if (trigger) {
            trigger.status = jobStatus.status;
            trigger.currentMessage = jobStatus.progressMessage ?? jobStatus.lastErrorMessage;
        } else {
            trigger = {
                name: jobStatus.triggerId,
                isActive: false,
                status: jobStatus.status,
                isManual: true,
                useZrhTimeZone: false,
                misfireLogic: JobMisfireInstruction.Default
            };
            job?.triggers.push(trigger);
        }

        if (job && (jobStatus.status === JobStatus.Fail || jobStatus.status === JobStatus.Success) && trigger.isManual) {
            job.triggers = job?.triggers.filter((x) => x.name !== trigger?.name);
        }

        if (job && DateTimeService.compare(job.timestampDateTimeUtc, jobStatus.timestampDateTimeUtc) < 0) {
            job.lastErrorMessage = jobStatus.lastErrorMessage;
            job.lastStatus = jobStatus.status;
            if (jobStatus.jobExecutionInfo) {
                const jobExecutionInfo = jobStatus.jobExecutionInfo;
                job.lastRunDateTimeUtc = jobExecutionInfo.lastRunDateTimeUtc;
                job.nextRunDateTimeUtc = jobExecutionInfo.nextRunDateTimeUtc;
                job.lastRunTriggerName = jobExecutionInfo.lastRunTriggerName || '';
                job.nextRunTriggerName = jobExecutionInfo.nextRunTriggerName || '';
                job.lastExecutionInfoMessage = jobExecutionInfo.lastInfoMessage || '';
            }
            job.timestampDateTimeUtc = jobStatus.timestampDateTimeUtc;
        }
    }

    @action
    public updateJob(job: ApiV1Job) {
        const index = this.jobs.findIndex((x) => x.id === job.id);
        if (index !== -1 && DateTimeService.compare(this.jobs[index].timestampDateTimeUtc, job.timestampDateTimeUtc) < 0) {
            this.jobs[index] = job;
        } else if (index !== -1) {
            this.jobs[index].triggers = job.triggers;
        }
    }

    @action.bound
    private _onJobProgressMaxValueChangedHandler(_: string, data: JobProgressMaxValue) {
        const trigger = this._findTrigger(data.triggerId);
        if (trigger) {
            trigger.progressMax = data.maxValue;
        }
    }

    @action.bound
    private _onJobProgressValueChangedHandler(_: string, data: JobProgressValue) {
        const trigger = this._findTrigger(data.triggerId);
        if (trigger) {
            trigger.progressMax = data.maxValue;
            trigger.progressCurrent = data.value;
        }
    }
}
