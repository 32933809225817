/* eslint-disable @typescript-eslint/no-explicit-any */

import { computed } from 'mobx';
import DateTimeService from '../services/DateTimeService';
import { DateTime } from './DateTime';

/*Validation Rules*/
export function isRequired(msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                return obj[name] ? null : msg || `Field "${getDisplayName(obj, name)}" cannot be empty`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRequiredWhenExist(related: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                return obj[related] && (obj[name] ? null : msg || `Field "${getDisplayName(obj, name)}" cannot be empty`);
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRequiredWhenNotExist(related: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                return !obj[related] && (obj[name] ? null : msg || `Field "${getDisplayName(obj, name)}" cannot be empty`);
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRequiredWhen(compareName: string, compareValue: any, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const shouldValidate = obj[compareName] === compareValue;
                return obj[name] || !shouldValidate ? null : msg || `Field "${getDisplayName(obj, name)}" cannot be empty`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDate(msg?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.(\d{4})\s*$/;
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDate(obj[name])));
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" should have format ${DateTime.viewDateFormat}`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateAfter(compareName: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                const compareDate = DateTimeService.parse(obj[compareName], format ? format : DateTime.viewDateFormat);
                const isValid = !DateTimeService.isValidDate(originDate) || !DateTimeService.isValidDate(compareDate) || originDate >= compareDate;
                return isValid ? undefined : msg || `"${getDisplayName(obj, name)}" should be later than "${getDisplayName(obj, compareName)}"`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateTimeAfter(compareName: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewFullFormat);
                const compareDate = DateTimeService.parse(obj[compareName], format ? format : DateTime.viewFullFormat);
                const isValid = !DateTimeService.isValidDate(originDate) || !DateTimeService.isValidDate(compareDate) || originDate >= compareDate;
                return isValid ? undefined : msg || `"${getDisplayName(obj, name)}" should be later than "${getDisplayName(obj, compareName)}"`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateBefore(compareName: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                const compareDate = DateTimeService.parse(obj[compareName], format ? format : DateTime.viewDateFormat);
                const isValid = !DateTimeService.isValidDate(originDate) || !DateTimeService.isValidDate(compareDate) || originDate <= compareDate;
                return isValid ? undefined : msg || `"${getDisplayName(obj, name)}" should be earlier than "${getDisplayName(obj, compareName)}"`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateTimeBefore(compareName: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewFullFormat);
                const compareDate = DateTimeService.parse(obj[compareName], format ? format : DateTime.viewFullFormat);
                const isValid = !DateTimeService.isValidDate(originDate) || !DateTimeService.isValidDate(compareDate) || originDate <= compareDate;
                return isValid ? undefined : msg || `"${getDisplayName(obj, name)}" should be earlier than "${getDisplayName(obj, compareName)}"`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isSameAs(compareName: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = obj[name] === obj[compareName];
                return isValid ? undefined : msg || `"${getDisplayName(obj, name)}" should be the same as "${getDisplayName(obj, compareName)}"`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isNotSameAs(compareName: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = obj[name] !== obj[compareName];
                return isValid ? undefined : msg || `"${getDisplayName(obj, name)}" should not be the same "${getDisplayName(obj, compareName)}"`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isTime(msg?: string) {
    const regEx = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" should have format ${DateTime.timeFormat.toUpperCase()}`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateTime(msg?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})[ ](([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDateTime(obj[name])));
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" should have format ${DateTime.viewFullFormat.toUpperCase()}`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function hasMinLength(length: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || obj[name].toString().length >= length;
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" should be great than or equal to ${length} characters`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function hasMaxLength(length: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || obj[name].toString().length <= length;
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" should be less than or equal to ${length} characters`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function notWhitespace(msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = obj[name].toString().length > 0 ? obj[name].toString().trim().length > 0 : true;
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" cannot consist only of white-space characters.`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRange(min: number, max: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const number = Number(obj[name]);
                const isValid = !obj[name] || (!isNaN(number) && number >= min && number <= max);
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" is out of range from ${min} to ${max}`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDateInRange(start: string, end: string, format?: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
                const startDate = DateTimeService.parse(obj[start], format ? format : DateTime.viewDateFormat);
                const endDate = DateTimeService.parse(obj[end], format ? format : DateTime.viewDateFormat);

                if (!DateTimeService.isValidDate(originDate)) return undefined;

                const isStartValid = start && DateTimeService.isValidDate(startDate) ? DateTimeService.isSameDate(originDate, startDate) || DateTimeService.isAfterDate(originDate, startDate) : true;
                const isEndValid = end && DateTimeService.isValidDate(endDate) ? DateTimeService.isSameDate(originDate, endDate) || DateTimeService.isBeforeDate(originDate, endDate) : true;

                if (!isStartValid) return msg || `"${getDisplayName(obj, name)}" should be later or equal to ${obj[start]}`;

                if (!isEndValid) return msg || `"${getDisplayName(obj, name)}" should be earlier or equal to ${obj[end]}`;

                return undefined;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isRegEx(regEx: any, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" is not valid.`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isIntegerNumber(msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                let isValid = obj[name] && !isNaN(Number(obj[name]));
                let errorMessage = `Field "${getDisplayName(obj, name)}" is not a number`;
                if (isValid && !Number.isInteger(Number(obj[name]))) {
                    isValid = false;
                    errorMessage = `Field "${getDisplayName(obj, name)}" should be integer value.`;
                }
                return isValid ? undefined : (msg || errorMessage);
            }
        };

        addValidation(target, name, validation);
    };
}

export function isFloatNumber(msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                let isValid = obj[name] && !isNaN(Number(obj[name]));
                if (isValid) return undefined;
                
                let errorMessage = `Field "${getDisplayName(obj, name)}" is not a number`;
                return msg ?? errorMessage;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isLessThan(val: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isNumber = obj[name] && !isNaN(Number(obj[name]));
                const isValid = isNumber && Number(obj[name]) < val;

                if (isValid) return undefined;
                
                let errorMessage = `Field "${getDisplayName(obj, name)}" should be number less than ${val}`;
                return msg ?? errorMessage;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isLessOrEqualThan(val: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isNumber = obj[name] && !isNaN(Number(obj[name]));
                const isValid = isNumber && Number(obj[name]) <= val;

                if (isValid) return undefined;
                
                let errorMessage = `Field "${getDisplayName(obj, name)}" should be number less than ${val}`;
                return msg ?? errorMessage;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isGraterThan(val: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isNumber = obj[name] && !isNaN(Number(obj[name]));
                const isValid = isNumber && Number(obj[name]) > val;

                if (isValid) return undefined;
                
                let errorMessage = `Field "${getDisplayName(obj, name)}" should be number grater than ${val}`;
                return msg ?? errorMessage;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isGraterOrEqualThan(val: number, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isNumber = obj[name] && !isNaN(Number(obj[name]));
                const isValid = isNumber && Number(obj[name]) >= val;

                if (isValid) return undefined;
                
                let errorMessage = `Field "${getDisplayName(obj, name)}" should be number grater than ${val}`;
                return msg ?? errorMessage;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isBiggerThanNull(msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                let isValid = !obj[name] || Number(obj[name]) <= 0;
                let errorMEssage;
                if (isValid && obj[name].length && Number(obj[name]) <= 0) {
                    isValid = false;
                    errorMEssage = `Field "${getDisplayName(obj, name)}" is less or equal than 0`;
                }
                return isValid ? undefined : msg || errorMEssage;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isDuration(msg?: string) {
    const regEx = /\d*:[0-5][0-9]$/;
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" should have format HH:MM`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isEmail(msg?: string) {
    const regEx = /([\w.-]+@([\w-]+)\.+\w{2,})/;
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                const isValid = !obj[name] || regEx.test(obj[name]);
                return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" is not a valid email`;
            }
        };

        addValidation(target, name, validation);
    };
}

export function isEmptyWhenExist(related: string, msg?: string) {
    return <any>function (target: any, name: string) {
        const validation = {
            fieldName: name,
            validateFunction: (obj: any) => {
                return obj[related] && (!obj[name] ? null : msg || `Field "${getDisplayName(obj, name)}" must be empty`);
            }
        };

        addValidation(target, name, validation);
    };
}

export function displayName(displayName: string) {
    return <any>function (target: any, name: string) {
        const __displayName = camelCase('____displayName_', name);
        target[__displayName] = displayName;
    };
}

export function addDateTimeValidator(target: any, name: string, msg?: string, title?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})[ ](([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;

    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const isValid = !obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDateTime(obj[name])));
            return isValid ? undefined : msg || `Field "${(title ? title : camelCase('', name))}" should have format DD.MM.YYYY HH:MM`;
        }
    };

    addValidation(target, name, validation);
}

export function addDateValidator(target: any, name: string, msg?: string, title?: string) {
    const regEx = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;

    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const isValid = !obj[name] || (regEx.test(obj[name]) && DateTimeService.isValidDate(DateTimeService.parseUiDate(obj[name])));
            return isValid ? undefined : msg || `Field "${(title ? title : camelCase('', name))}" should have format DD.MM.YYYY`;
        }
    };

    addValidation(target, name, validation);
}

export function addDateInRangeValidator(target: any, name: string, start: string, end: string, msg?: string | ((any: string) => string), format?: string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const originDate = DateTimeService.parse(obj[name], format ? format : DateTime.viewDateFormat);
            const startDate = DateTimeService.parse(start, format ? format : DateTime.viewDateFormat);
            const endDate = DateTimeService.parse(end, format ? format : DateTime.viewDateFormat);

            if (!DateTimeService.isValidDate(originDate)) return undefined;

            const isStartValid = start && DateTimeService.isValidDate(startDate) ? DateTimeService.isSameDate(originDate, startDate) || DateTimeService.isAfterDate(originDate, startDate) : true;
            const isEndValid = end && DateTimeService.isValidDate(endDate) ? DateTimeService.isSameDate(originDate, endDate) || DateTimeService.isBeforeDate(originDate, endDate) : true;

            if (msg && (!isStartValid || !isEndValid))
                return msg && typeof msg === 'string' ? msg : msg && (msg as any)(obj[name]);

            if (!isStartValid) return `"${getDisplayName(obj, name)}" should be later or equal to ${start}`;

            if (!isEndValid) return `"${getDisplayName(obj, name)}" should be earlier or equal to ${end}`;

            return undefined;
        }
    };

    addValidation(target, name, validation);
}

export function addNumberValidator(target: any, name: string, msg?: string, title?: string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const isValid = !obj[name] || !isNaN(Number(obj[name]));
            return isValid ? undefined : msg || `Field "${(title ? title : camelCase('', name))}" is not a number`;
        }
    };

    addValidation(target, name, validation);
}

export function addUrlValidator(target: any, name: string, msg?: string, title?: string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            let isValid: boolean = false;
            let errorMessage: string | undefined;
            try {
                if (obj[name]) {
                    const newUrl = new URL(obj[name]);
                    isValid = newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
                }
            } catch (err) {
                errorMessage = (err as Error)?.message;
            }

            if (!isValid && !errorMessage) {
                errorMessage = 'is not a valid URL protocol';
            }

            return isValid ? undefined : msg || `Field "${(title ? title : camelCase('', name))}": ${errorMessage}`;
        }
    };

    addValidation(target, name, validation);
}

export function addRequiredValidator(target: any, name: string, msg?: string, title?: string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            return obj[name] ? null : msg || `Field "${(title ? title : camelCase('', name))}" cannot be empty`;
        }
    };
    addValidation(target, name, validation);
}

export function addIsRangeValidator(target: any, name: string, min: number, max: number, msg?: string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            const number = Number(obj[name]);
            const isValid = !obj[name] || (!isNaN(number) && number >= min && number <= max);
            return isValid ? undefined : msg || `Field "${getDisplayName(obj, name)}" is out of range from ${min} to ${max}`;
        }
    };
    addValidation(target, name, validation);
}

export function addCustomValidator(target: any, name: string, validationFn: () => boolean, message: string) {
    const validation = {
        fieldName: name,
        validateFunction: (obj: any) => {
            return validationFn() ? undefined : message;
        }
    };
    addValidation(target, name, validation);
}

/*Logic*/
function addValidation(target: any, name: any, validationRule: any) {
    const __validators = '__validators';
    const __validationErrors = '__validationErrors';
    const __isValidForm = '__isValidForm';
    const __validateError = camelCase('__validateError_', name);
    const __errorFields = '__errorFields';

    if (!target.hasOwnProperty(__validators)) {
        const prototypeValue = target[__validators];
        Object.defineProperty(target, __validators, {
            configurable: true,
            enumerable: false,
            value: prototypeValue?.slice(0) ?? []
        });
    }

    if (!target.hasOwnProperty(__validationErrors)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                const errorList: any = [];
                const validators = this[__validators];
                validators.forEach((validator: any) => {
                    const error = validator.validateFunction(this);
                    if (error) {
                        errorList.push(error);
                    }
                });
                return errorList;
            }
        };
        defineComputedProperty(target, __validationErrors, descriptor);
    }

    if (!target.hasOwnProperty(__isValidForm)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                let isValid = true;
                const validators = this[__validators];
                if (!validators.length) return isValid;
                validators.forEach((validator: any) => {
                    const error = validator.validateFunction(this);
                    if (error) {
                        isValid = false;
                    }
                });
                return isValid;
            }
        };

        defineComputedProperty(target, __isValidForm, descriptor);
    }

    if (!target.hasOwnProperty(__validateError)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                const validators = this[__validators];
                const errorList: any = [];
                validators.forEach((validator: any) => {
                    if (validator.fieldName === name) {
                        const error = validator.validateFunction(this);
                        if (error) {
                            errorList.push(error);
                        }
                    }
                });
                return errorList;
            }
        };
        defineComputedProperty(target, __validateError, descriptor);
    }

    if (!target.hasOwnProperty(__errorFields)) {
        const descriptor = {
            configurable: true,
            enumerable: false,
            get: function getter(this: any) {
                const validators = this[__validators];
                const errorNames: any = [];
                validators.forEach((validator: any) => {
                    const error = validator.validateFunction(this);
                    if (error) {
                        errorNames.push(validator.fieldName);
                    }
                });
                return errorNames;
            }
        };
        defineComputedProperty(target, __errorFields, descriptor);
    }

    target[__validators].push(validationRule);
}

function defineComputedProperty(target: any, name: string, descriptor: PropertyDescriptor & ThisType<any>) {
    Object.defineProperty(target, name, descriptor);
    computed(target, name);
}

function camelCase(prefix: string, others: string) {
    return prefix + others[0].toUpperCase() + others.substr(1);
}

function getDisplayName(target: any, name: string) {
    const __displayName = camelCase('____displayName_', name);
    return target[__displayName] ? target[__displayName] : camelCase('', name);
}

export function setDisplayName(target: any, name: string, value: string) {
    const __displayName = camelCase('____displayName_', name);
    target[__displayName] = value;
}
