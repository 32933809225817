import React from 'react'
import { faAngleDown, faBasketShopping } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BasketDTO } from '@app/models/AppModels'

import cls from './_header.module.scss'

type HeaderProps = {
    title: string
    basket: BasketDTO[]
    onBasketClick: () => void
    onFlightClick: () => void
    canSwitchFlight: boolean
}

export default class Header extends React.Component<HeaderProps, {}> {
    render() {
        const count = this.props.basket.map(e => e.count).reduce((partialSum, a) => partialSum + a, 0) || 0
        const currency = this.props.basket[0]?.service.currency;
        let titleNode = <div className={cls.name}>{this.props.title}</div>
        if (this.props.canSwitchFlight) {
            titleNode = <div className={cls.dropdown} onClick={this.props.onFlightClick}>{this.props.title} <FontAwesomeIcon className={cls.icon}
                                                                                                       icon={faAngleDown} />
            </div>
        }
        const totalPrice = this.props.basket.map(b => b.service.price * b.count).reduce((partialSum, a) => partialSum + a, 0)

        return (
            <div className={cls.root}>
                {titleNode}
                <div className={cls.basket} onClick={count ? this.props.onBasketClick : undefined}>
                    {!!count &&
                        <>
                            <div className={cls.count}>{count}</div>
                            <div className={cls.total}>{currency} {totalPrice.toFixed(2)}</div>
                        </>
                    }
                    <FontAwesomeIcon className={cls.icon} icon={faBasketShopping} />
                </div>
            </div>
        )
    }
}

