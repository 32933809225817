import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { applyDecorators } from '@app/helpers/Decorator';
import { AirportDto } from '@app/models/WebApiModels';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@app/components/Modal/Modal';

import cls from './_connexFlight.module.scss';
import { TextControl } from '@app/components/UI/FormControls';

export type ConnexFlightDialogProps = {
    airports: AirportDto[];
};

@observer
export default class ConnexFlightDialog extends React.Component<ConnexFlightDialogProps, {}> implements IModalDialogContent<AirportDto> {
    private _window: ModalWindow<AirportDto>;

    @observable
    private _search?: string;

    constructor(props: ConnexFlightDialogProps) {
        super(props);
        applyDecorators(this);
    }

    getModalOptions(window: ModalWindow<AirportDto>) {
        this._window = window;

        return {
            title: 'Connex flight',
            width: '640px',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                }
            ]
        } as ModalDialogOptions<AirportDto>;
    }

    render() {
        return (
            <>
                <div className={cls.header}>
                    <TextControl showIcon={true} inpClass={cls.search} wrapClass={cls.searchWrap} label="Search" value={this._search} onValueChange={(val) => (this._search = val)} />
                </div>
                <div className={cls.itemsContainer}>
                    {this.props.airports
                        .filter((e) => (this._search ? e.name?.toLowerCase().indexOf(this._search.toLowerCase()) !== -1 || e.code?.toLowerCase().indexOf(this._search.toLowerCase()) !== -1 : !this._search))
                        .map((item) => (
                            <div className={cls.item} style={{ cursor: 'pointer' }} key={item.code} onClick={() => this._onItemClick(item)}>
                                {`${item.code} - ${item.name}`}
                            </div>
                        ))}
                </div>
            </>
        );
    }

    private _onItemClick(item: AirportDto) {
        this._window.close(ModalButtonType.Ok, item);
    }
}
