import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import { AdminLayout } from '@app/components/AdminLayout/AdminLayout';
import { AirportDto, EmailsInfo } from '@app/models/WebApiModels';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { ListItem } from '@app/components/List/List';
import ApiService from '@app/services/ApiService';
import { ApiUrls } from '@app/AppConstants';
import { ReportPeriod, ReportType } from '@app/models/AppModels';
import { TextControl } from '@app/components/UI/FormControls';
import { KeyboardCodes } from '@app/appConstants/KeyboardKeys';
import { loaderStore } from '@app/stores/LoaderStore';
import Badge from '@app/components/UI/Badge';

import cls from './_reports.module.scss';
import { NotificationHandler } from '@app/components/ToastNotification';

@observer
export class ReportsConfigurationPage extends React.Component<{}, {}> {
    private _dataLoader = new PromiseCompletion();
    private _pageLoader = new PromiseCompletion();

    @observable
    private _airports: AirportDto[] = [];

    @observable
    private _selected?: AirportDto;

    constructor(props: {}) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <AdminLayout list={this._listItems} onItemSelect={this._onAirportSelect} title={this._title} dataLoader={this._dataLoader} pageLoader={this._pageLoader}>
                {this._selected && <Details airport={this._selected} onSave={this._onAirportChange} />}
            </AdminLayout>
        );
    }

    componentDidMount() {
        void this._getAirportsList();
    }

    @computed
    private get _listItems() {
        return this._airports.map((a) => {
            return { id: a.code, label: `${a.code} - ${a.name}` } as ListItem;
        });
    }

    @computed
    private get _title() {
        return this._selected ? (
            <>
                {this._selected.code} &mdash; {this._selected.name}
            </>
        ) : (
            ``
        );
    }

    @action.bound
    private async _getAirportsList() {
        const params = {};
        const { data } = await ApiService.getTypedData<AirportDto[]>(ApiUrls.AirportUrl + '?configurableOnly=true', params, { completion: this._pageLoader });
        this._airports = data;
    }

    @action.bound
    private _onAirportChange = (airport: AirportDto) => {
        this._airports = this._airports.map((a) => (a.code === airport.code ? airport : a));
    };

    @action.bound
    private _onAirportSelect = (id: string) => {
        this._selected = this._airports.find((a) => a.code === id);
    };
}

type DetailsProps = {
    airport: AirportDto;
    onSave: (airport: AirportDto) => void;
};

@observer
export class Details extends React.Component<DetailsProps, {}> {
    @observable.ref
    private _config!: AirportDto;

    @observable
    private _hasChanges: boolean = false;

    constructor(props: DetailsProps) {
        super(props);
        makeObservable(this);

        this._config = props.airport;
    }

    componentWillUnmount(): void {
        if (this._hasChanges) {
            if (confirm('You have unsaved changes. Save them?')) this._onSave(this._config);
        }
    }

    getSnapshotBeforeUpdate() {
        return {
            hasChanges: this._hasChanges,
            config: this._config
        };
    }

    componentDidUpdate(prevProps: Readonly<DetailsProps>, prevState: Readonly<{}>, snapshot?: { hasChanges: boolean; config: AirportDto }): void {
        if (prevProps.airport.code !== this.props.airport.code) {
            this._hasChanges = false;
            this._config = this.props.airport;
            if (snapshot?.hasChanges && snapshot.config && confirm('You have unsaved changes. Save them?')) this._onSave(snapshot.config);
        }
    }

    render() {
        return (
            <>
                <div className={cls.group}>
                    <table className={cls.table}>
                        <thead>
                            <tr>
                                <th />
                                <th />
                                <th style={{ width: '25%' }}>PDF</th>
                                <th style={{ width: '25%' }}>CSV</th>
                                <th style={{ width: '25%' }}>XLS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this._renderRow('Daily', ReportPeriod.DailyReports, this._config.dailyReports)}
                            {this._renderRow('Weekly', ReportPeriod.WeeklyReports, this._config.weeklyReports)}
                            {this._renderRow('Monthly', ReportPeriod.MonthlyReports, this._config.monthlyReports)}
                        </tbody>
                    </table>
                </div>
                <div className={cls.controls}>
                    <Button onClick={() => this._onSave(this._config)} color="danger" disabled={!this._config.monthlyReports?.[ReportType.Pdf]?.to}>
                        Save
                    </Button>
                </div>
            </>
        );
    }

    private _renderRow(title: string, period: ReportPeriod, src?: { [key: string]: EmailsInfo }) {
        return (
            <>
                <tr className={cls.horizontalDelimiter}>
                    <td rowSpan={2} className={cls.firstColumn}>
                        {title}
                    </td>
                    <td className={cls.upperHint}>
                        <div className={cls.hintText}>To:</div>
                    </td>
                    {this._renderEmails(period, 'to', src)}
                </tr>
                <tr className={cls.horizontalSubDelimiter}>
                    <td className={cls.lowerHint}>
                        <div className={cls.hintText}>Cc:</div>
                    </td>
                    {this._renderEmails(period, 'cc', src)}
                </tr>
            </>
        );
    }

    private _renderEmails(period: ReportPeriod, field: keyof EmailsInfo, src?: { [key: string]: EmailsInfo }) {
        const keys = [ReportType.Pdf, ReportType.Csv, ReportType.Xls];
        return <>{keys.map((key) => this._renderEmailsInfo(src?.[key], period, key, field))}</>;
    }

    private _renderEmailsInfo(info: EmailsInfo | undefined, period: ReportPeriod, type: ReportType, field: keyof EmailsInfo) {
        const maxLength = Math.max(512 - (info?.[field]?.join(';')?.length ?? 0), 0);

        return (
            <>
                <td className={cls.emails}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', flexWrap: 'wrap' }}>
                        {info?.[field] ? (
                            <>
                                {info?.[field]?.map((x, i) => (
                                    <Badge key={period + type + field + i}>
                                        <div style={{ display: 'flex', padding: '0 8px', gap: '8px' }}>
                                            {x}{' '}
                                            <FontAwesomeIcon
                                                icon={faClose}
                                                style={{ cursor: 'pointer' }}
                                                color="red"
                                                onClick={() =>
                                                    this._updateEmailsInfo(
                                                        period,
                                                        type,
                                                        field,
                                                        info?.[field]!.filter((t) => t !== x)
                                                    )
                                                }
                                            />
                                        </div>
                                    </Badge>
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div style={{ padding: '4px' }}>
                        <TextControl
                            maxLength={maxLength}
                            style={{ padding: '0px' }}
                            clearOnEnter
                            onEnter={(val) => {
                                val = val.trim();
                                if (val) this._updateEmailsInfo(period, type, field, [...(info?.[field] ?? []), val]);
                            }}
                        />
                    </div>
                </td>
            </>
        );
    }

    @action
    private _updateEmailsInfo(period: ReportPeriod, type: ReportType, field: keyof EmailsInfo, value: string[]) {
        let src: { [key: string]: EmailsInfo };

        if (period === ReportPeriod.DailyReports) {
            if (!this._config.dailyReports) this._config.dailyReports = {};

            src = this._config.dailyReports;
        } else if (period === ReportPeriod.WeeklyReports) {
            if (!this._config.weeklyReports) this._config.weeklyReports = {};

            src = this._config.weeklyReports;
        } else {
            if (!this._config.monthlyReports) this._config.monthlyReports = {};

            src = this._config.monthlyReports;
        }

        if (!src[type]) src[type] = {};

        this._hasChanges = true;

        src[type][field] = !value || value.length === 0 ? undefined : value.filter((x, index) => value.indexOf(x) == index);
    }

    private _addEnterListener() {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.code === KeyboardCodes.ENTER) {
                document.removeEventListener('keydown', this._addEnterListener);
            }
        });
    }

    @action.bound
    private async _onSave(config: AirportDto) {
        await ApiService.postData(ApiUrls.AirportUrl, config, { completion: loaderStore.bodyLoader });
        NotificationHandler.showSuccess('Saved!');
        this._hasChanges = false;
        this.props.onSave(this._config);
    }
}
