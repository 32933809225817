import ApiService from '@app/services/ApiService';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { ShortProductInfo } from '@app/models/WebApiModels';
import { AdminLayout } from '@app/components/AdminLayout/AdminLayout';
import { ApiUrls } from '@app/AppConstants';

import cls from '../_configuration.module.scss';
import React from 'react';
import { action, observable } from 'mobx';
import { getColumnProducts } from '@app/helpers/Helpers';
import { applyDecorators } from '@app/helpers/Decorator';
import { observer } from 'mobx-react';
import ProductItem from './components/ProductItem';

@observer
export default class ProductsConfigurationPage extends React.Component<{}, {}> {
    private _dataLoader = new PromiseCompletion();

    @observable.ref private _products: ShortProductInfo[] = [];

    constructor(props: {}) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount() {
        void this._loadData();
    }

    render() {
        return (
            <AdminLayout title="" centerMode={true} dataLoader={this._dataLoader}>
                <div className={cls.list}>
                    <div className={cls.col}>{this._getItems(this._products, 'L1')}{this._getItems(this._products)}</div>
                    <div className={cls.col}>{this._getItems(this._products, 'L2')}</div>
                </div>
            </AdminLayout>
        );
    }

    @action.bound
    private async _loadData() {
        const { data } = await ApiService.getTypedData<ShortProductInfo[]>(ApiUrls.ProductUrl, null, { completion: this._dataLoader });
        this._products = data;
    }

    @action.bound
    private async _onServiceChanged() {
        await this._loadData();
    }

    private _getItems(products: ShortProductInfo[], col?: string) {
        if (!col) {
            return products.filter((x) => !x.sortOrder).map((s) => <ProductItem key={s.code} service={s} loader={this._dataLoader} onChange={() => void this._onServiceChanged()} />);
        } else {
            return getColumnProducts(products, col).map((s) => <ProductItem key={s.code} service={s} loader={this._dataLoader} onChange={() => void this._onServiceChanged()} />);
        }
    }
}
