import React from 'react';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '../../Modal/Modal';
import { FlightDto } from '@app/models/WebApiModels';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeService from '@app/services/DateTimeService';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import cls from "./_availableFlights.module.scss";
import { applyDecorators } from '@app/helpers/Decorator';

export type AvailableFlightsDialogProps = {
    flightList: FlightDto[];
    selectedFlight?: FlightDto;
};

@observer
export class AvailableFlightsDialog extends React.Component<AvailableFlightsDialogProps, {}> implements IModalDialogContent<FlightDto> {
    @observable
    private flight?: FlightDto;

    constructor(props: AvailableFlightsDialogProps) {
        super(props);
        applyDecorators(this);

        this.flight = this.props.selectedFlight ? this.props.selectedFlight : this.props.flightList[0];
    }

    getModalOptions = (window: ModalWindow<FlightDto>) => {
        return {
            title: 'Available Flights',
            width: '540px',
            bodyClassName: cls.container,
            disableAutoFocus: true,
            hideCloseButton: true,
            closeByEscape: false,
            buttons: [
                {
                    type: ModalButtonType.Ok,
                    color: 'danger',
                    onClick: () => {
                        window.close(ModalButtonType.Ok, this.flight);
                    }
                }
            ]
        } as ModalDialogOptions<FlightDto>;
    };

    render() {
        return (
            <div>
                {this.props.flightList.map((e) => (
                    <div key={e.id} className={`${cls.item} ${this.flight?.id === e.id ? cls.active : ''}`} onClick={() => this._selectFlight(e)}>
                        {this.flight?.id === e.id && <FontAwesomeIcon className={cls.icon} icon={faCheck} />} {e.airlineRcd}
                        {e.flightNumber} ({e.departureAirport} - {e.arrivalAirport}) - {e.departureDateTimeUtc ? DateTimeService.format(e.departureDateTimeUtc, 'DDMMMYY') : '??????'} - ETD {e.departureDateTimeUtc ? DateTimeService.format(e.departureDateTimeUtc, 'HH:mm') : '??:??'}
                    </div>
                ))}
            </div>
        );
    }

    @action.bound
    private _selectFlight(flight: FlightDto) {
        this.flight = flight;
    }
}
