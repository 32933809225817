import { observer } from 'mobx-react';
import React from 'react';
import { ButtonColor, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '../Modal/Modal';
import { action, observable } from 'mobx';
import { applyDecorators } from '@app/helpers/Decorator';
import { Container, Row } from 'reactstrap';

export type ListDialogProps<T> = {
    items: T[];
    title: string;
    isMulti?: boolean;
    renderItem: (item: T, isSelected: boolean) => JSX.Element;
    onItemClick?: (item: T) => void;
};

@observer
export class ListDialog<T> extends React.Component<ListDialogProps<T>, {}> implements IModalDialogContent<T | T[]> {
    private _props: ListDialogProps<T>;
    private _window: ModalWindow<T | T[]>;

    @observable
    private _selectedItems: T[] = [];

    constructor(props: ListDialogProps<T>) {
        super(props);
        applyDecorators(this);

        this._props = props;
    }

    public getModalOptions(window: ModalWindow<T | T[]>) {
        this._window = window;

        return {
            width: '640px',
            title: this._props.title,
            buttons: [
                {
                    type: ModalButtonType.Save,
                    color: 'danger' as ButtonColor,
                    onClick: () => {
                        window.close(ModalButtonType.Save, this._props.isMulti ? this._selectedItems : this._selectedItems[0]);
                    }
                },
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                }
            ]
        };
    }

    render() {
        return (
            <Container>
                {this.props.items.map((item) => (
                    <Row className="mb-2" onClick={() => this._onItemClick(item)}>
                        {this.props.renderItem(item, this._selectedItems.indexOf(item) !== -1)}
                    </Row>
                ))}
            </Container>
        );
    }

    @action.bound
    private _onItemClick(item: T) {
        if (this.props.onItemClick) {
            this.props.onItemClick(item);
            this._window.close();
            return;
        }

        if (!this._props.isMulti) this._selectedItems = [item];

        if (this._selectedItems.findIndex((x) => x === item) === -1) {
            this._selectedItems.push(item);
        } else {
            this._selectedItems = this._selectedItems.filter((x) => x !== item);
        }
    }
}
