/* tslint:disable:max-classes-per-file only-arrow-functions */

import * as React from 'react';
import { makeObservable } from 'mobx';
import { Progress, ButtonGroup, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Alert, ListGroupItem } from 'reactstrap';
import { observer } from 'mobx-react';

import { ApiV1Job, JobStatus } from '../../Models/JobListModels';
import JobItemStore from './JobItemStore';
import './job-item.scss';
import DateTimeService from '@app/services/DateTimeService';
import Status from '@app/components/Status/Status';
import { ToolTipItem } from '@app/components/ToolTipItem';
import { Icon } from '@app/components/Icon';
import ReactService from '@app/services/ReactService';


export type JobItemProps = {
    job: ApiV1Job;
    renderText?: (text: string) => JSX.Element;
    onParametersSaved?: (e: ApiV1Job) => void;
    onTriggerCreated?: (e: ApiV1Job) => void;
    onTriggerDeleted?: (e: ApiV1Job) => void;
    onJobStarted?: (e: ApiV1Job) => void;
};

@observer
export default class JobItem extends React.Component<JobItemProps> {
    private _store: JobItemStore;

    constructor(props: JobItemProps) {
        super(props);
        makeObservable(this);
        this._store = new JobItemStore(props);
    }

    componentDidUpdate(prevProps: JobItemProps) {
        const jobStatus = this.props.job.lastStatus;
        if(prevProps.job.lastStatus !== jobStatus && (jobStatus === JobStatus.Fail || jobStatus === JobStatus.Success)){
            this._store.setIsStopping(false);
        }
    }

    renderTriggerProgress() {
        const { triggers, lastRunDateTimeUtc, nextRunDateTimeUtc, lastRunTriggerName, nextRunTriggerName } = this.props.job;

        let pendingTrigger = triggers.find(tr => tr.status === JobStatus.Pending);
        if (!pendingTrigger || pendingTrigger.status !== JobStatus.Pending) {
            const lastRun = ((lastRunDateTimeUtc && DateTimeService.toUiDateTime(lastRunDateTimeUtc)) || 'never') + (lastRunTriggerName ? ` - ${lastRunTriggerName}` : '');
            const nextRun = ((nextRunDateTimeUtc && DateTimeService.toUiDateTime(nextRunDateTimeUtc)) || 'never') + (nextRunTriggerName ? ` - ${nextRunTriggerName}` : '');
            return (
                <div className="mt-3">
                    <div className="job-details">Last run: {lastRun} | Next run: {nextRun}</div>
                </div>
            );
        }
        return (
            <div className="mt-3">
                <Progress
                    striped={true}
                    animated={true}
                    color={pendingTrigger.currentMessage === 'complete' ? 'success' : ''}
                    max={pendingTrigger.progressMax ? pendingTrigger.progressMax : 1}
                    value={pendingTrigger.progressCurrent ? pendingTrigger.progressCurrent : 1}
                    className="progress-center-wrapper"
                >
                    <span className="progress-center">{pendingTrigger.currentMessage}</span>
                </Progress>
            </div>
        );
    }

    private _renderResultStatus() {
        const { job } = this.props;
        const pendingTriggers = job.triggers
            .filter(tr => tr.status === 'Pending')
            .map(tr => tr.name)
            .join(', ');

        const elementId = job.name + '_error_message';

        let color = 'secondary';
        if (job.lastStatus != null) {
            switch (job.lastStatus) {
                case 'Fail':
                    color = 'danger';
                    break;
                case 'Success':
                    color = 'success';
                    break;
                case 'Pending':
                    color = 'secondary';
                    break;
            }
        }

        if (!job.lastStatus) return '';

        return (
            <Status id={elementId} color={color} onClick={this.toggleLastError}>
                {job.lastStatus === 'Pending' && <ToolTipItem text={pendingTriggers} targetId={elementId} />}
                {job.lastStatus}
            </Status>
        );
    }

    private _renderActiveStatus() {
        const { job } = this.props;

        const activeTriggers = job.triggers.filter((tr) => tr.nextRunDateTime && tr.isActive);
        const trigger = activeTriggers.length ? activeTriggers[activeTriggers.length - 1] : null;

        const elementId = trigger ? trigger.name : job.id;
        const color = trigger && trigger.isActive ? 'success' : 'secondary';
        return (
            <Status id={elementId} color={color} pill clsPrefix={'active-status'}>
                {trigger &&
                    <div className="tooltip bs-tooltip-right">
                        {activeTriggers.map(t => {
                            return (
                                <div className="tooltip-inner" key={t.name}>
                                    {t.name && <div>{t.name}</div>}
                                    {t.cronSchedule && <div>Cron: {trigger.cronSchedule}</div>}
                                    {t.nextRunDateTime && <div>Next: {DateTimeService.toUiDateTime(t.nextRunDateTime)}</div>}
                                    <br />
                                </div>);
                        })}
                        <span className="arrow" />
                    </div>
                }
            </Status>
        );
    }

    renderTriggerError() {
        const { job } = this.props;
        if (!job.lastErrorMessage) return null;
        const lastErrorMessage = <>{job.lastErrorMessage.split('\n').map((x, index) => <div key={index}>{x}</div>)}</>;
        return (
            <Collapse isOpen={this._store.errorToggle} className="mt-3">
                <Alert color="danger">
                    {lastErrorMessage}
                </Alert>
            </Collapse>
        );
    }

    renderActions() {
        const { job } = this.props;
        const isRunning = job.triggers.find(tr => tr.status === this._store.statusPending);
        return (
            <ButtonGroup className="job-actions" size="sm">
                <ButtonDropdown dir="up" isOpen={this._store.isOpenDropdown} toggle={this._store.toggleDropdown}>
                    <DropdownToggle outline className="fa fa-gears menu-toggle" />
                    <DropdownMenu className="menu">
                        {job.triggers &&
                            job.triggers.length > 0 &&
                            job.triggers.map((t, index) => (
                                <DropdownItem onClick={this.showJobParams} key={index} className={t.nextRunDateTime && t.isActive ? '' : 'inactive'}>
                                    <div className={'btn-xs'}>{t.name}</div>
                                </DropdownItem>
                            ))}
                        {job.triggers && this.props.job.triggers.length > 0 && <DropdownItem divider />}
                        <DropdownItem onClick={this.showNewTriggerJobParams} key={job.name + '_Newtrigger'}>
                            <div className={'btn-xs'}>{'New trigger for ' + job.name}</div>
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
                {isRunning ? (
                    <Button size="sm" color="warning" onClick={this.stopJob} disabled={this._store.isStopping}>
                        <span>{this._store.isStopping ? 'Stopping..' : 'Stop now'}</span>
                    </Button>
                ) : (
                    <Button size="sm" color="success" onClick={this.runJob}>
                        <span>Run now</span>
                    </Button>
                )}
            </ButtonGroup>
        );
    }

    private _renderInfoMessage() {
        const { job } = this.props;
        const elementId = 'info_messages_' + job.id;

        if (!job.lastExecutionInfoMessage) return '';
        const messages =
            <div className="tooltip-wide">
                {job.lastExecutionInfoMessage.split('\n').map((m, i) => <React.Fragment key={'info_message_' + i}><div className="mb-2 my-2">{m}</div></React.Fragment>)}
            </div>;

        return (
            <>
                <ToolTipItem text={messages} targetId={elementId} />
                <Icon name="info-circle" id={elementId} className="status" />
            </>
        );
    }

    public render() {
        const { job: { name, description }, renderText } = this.props;
        const regEx = /({site-root}\/[a-z-]+)/gi;
        const isRegExMatched = description && new RegExp(regEx).test(description);

        return (
            <ListGroupItem className="job-list-item">
                <div className="title-row">
                    <div className="info">
                        <div>
                            {this._renderActiveStatus()}
                            <span className="job-name">{renderText ? renderText(name) : name}&nbsp;</span>
                            {this._renderResultStatus()}
                            {this._renderInfoMessage()}
                        </div>
                        <div>
                            {description && (
                                <span className="job-description">
                                    {isRegExMatched
                                        ? ReactService.replaceString(description, regEx, (s) => (
                                            <a key="{name}" href={s.replace('{site-root}', '')}>
                                                {s.replace('{site-root}', '')}
                                            </a>
                                        ))
                                        : renderText ? renderText(description) : description
                                    }
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
                {this.renderTriggerProgress()}
                {this.renderTriggerError()}
            </ListGroupItem>
        );
    }

    toggleLastError = (event: React.MouseEvent<HTMLSpanElement>) => {
        const isError = event.currentTarget.id.indexOf('error_message') > -1;
        isError && this._store.toggleLastError();
    };

    runJob = () => this._store.runJob();

    stopJob = () => this._store.stopJob();

    showJobParams = (event: React.MouseEvent<HTMLDivElement>) => {
        const id = (event.target as HTMLDivElement).innerText;
        this._store.showJobParams(id);
    };

    showNewTriggerJobParams = () => this._store.showJobParams();
}
