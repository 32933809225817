import React from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { AirportGroupProductPrice, CurrencyDto, ShortProductInfo } from '@app/models/WebApiModels';
import { ApiUrls } from '@app/AppConstants';
import ApiService from '@app/services/ApiService';
import ReactSelect from '@app/components/UI/ReactSelect';
import { Button } from '@app/components';
import { AdminLayout } from '@app/components/AdminLayout/AdminLayout';
import { applyDecorators } from '@app/helpers/Decorator';
import { modalService } from '@app/components/Modal/Modal';
import { ListDialog, ListDialogProps } from '@app/components/Dialogs/ListDialog';
import ProductItem from './components/ProductItem';

import cls from '../_configuration.module.scss';
import AddProductDialog from '@app/components/Dialogs/AddProduct/AddProductDialog';

@observer
export default class GroupsConfigurationPage extends React.Component<{}, {}> {
    private _dataLoader: PromiseCompletion = new PromiseCompletion();
    private _pageLoader: PromiseCompletion = new PromiseCompletion();

    constructor(props: {}) {
        super(props);
        applyDecorators(this);
    }

    @observable
    private _currencies: CurrencyDto[] = [];

    @observable.ref
    private _groups: number[] = [];

    @observable.ref
    private _products: AirportGroupProductPrice[] = [];

    @observable
    private _allProducts: ShortProductInfo[] = [];

    @observable
    private _selected?: number;

    @observable
    private _selectedCurrency?: string;

    componentDidMount() {
        void this._loadData();
    }

    render() {
        return (
            <AdminLayout
                list={this._groups.map((a) => {
                    return { id: a.toString(), label: a.toString() };
                })}
                onItemSelect={(e) => this._onGroupSelect(e)}
                title={this._selected?.toString() ?? ''}
                titleActions={this._renderToolbar()}
                dataLoader={this._dataLoader}
                pageLoader={this._pageLoader}
            >
                {this._products.length ? this._products.map((p) => this._renderItem(p)) : null}
            </AdminLayout>
        );
    }

    private _renderToolbar() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <ReactSelect
                    onSelect={(val) => (this._selectedCurrency = val?.toString())}
                    options={this._currencies.map((x) => ({
                        label: x.code!,
                        value: x.code!
                    }))}
                    value={this._selectedCurrency}
                />
                {this.addList.length && (
                    <Button
                        isInline={true}
                        isSmall={true}
                        className={cls.add}
                        label="+ Add Service"
                        onClick={() => {
                            void this._showProductsDialog();
                        }}
                    />
                )}
            </div>
        );
    }

    private _renderItem(p: AirportGroupProductPrice) {
        if (!this._selected || !this._selectedCurrency) return null;
        return <ProductItem key={p.productCode} service={p} priceGroup={this._selected} loader={this._dataLoader} onChange={this._onProductChange} targetCurrency={this._selectedCurrency} />;
    }

    @computed
    get addList() {
        return this._allProducts.filter((p) => this._products.findIndex((l) => l.productCode === p.code) === -1);
    }

    @action.bound
    private _onGroupSelect(id: string) {
        this._selected = Number(id);
        void this._loadProducts();
    }

    @action.bound
    private async _onProductChange() {
        await this._loadProducts();
    }

    @action
    private async _showProductsDialog() {
        const addedItems = await modalService.show<ListDialogProps<ShortProductInfo>, ShortProductInfo[]>(ListDialog<ShortProductInfo>, {
            items: this.addList,
            renderItem: (item, isSelected) => (
                <div key={item.code} className={`${cls.product} ${item.code && isSelected ? cls.checked : ''}`}>
                    {item.displayName}
                </div>
            ),
            onItemClick: (item) => this._openAddGroupProductDialog(item),
            title: 'Available Products',
            isMulti: true
        });

        if (!addedItems.result) return;
    }

    @action.bound
    private async _openAddGroupProductDialog(item: ShortProductInfo) {
        if (!this._selected) return;

        await modalService.show(AddProductDialog, { product: item, priceGroup: this._selected });
        await this._onProductChange();
    }

    @action.bound
    private async _loadData() {
        await this._loadAllProducts();
        await this._loadCurrencies();
        await this._loadPriceGroups();
    }

    @action.bound
    private async _loadCurrencies() {
        const { data } = await ApiService.getTypedData<CurrencyDto[]>(ApiUrls.CurrencyUrl + '?baseOnly=true', null, { completion: this._dataLoader });
        this._currencies = data;
    }

    @action.bound
    private async _loadPriceGroups() {
        const { data } = await ApiService.getTypedData<number[]>(`${ApiUrls.PriceUrl}/groups`, null, { completion: this._pageLoader });
        this._groups = data;
    }

    @action.bound
    private async _loadAllProducts() {
        const { data } = await ApiService.getTypedData<ShortProductInfo[]>(ApiUrls.ProductUrl, null, { completion: this._pageLoader });
        this._allProducts = data;
    }

    @action.bound
    private async _loadProducts() {
        if (!this._selected) return;

        const { data } = await ApiService.getTypedData<AirportGroupProductPrice[]>(`${ApiUrls.ProductUrl}/${this._selected}/products`, null, { completion: this._dataLoader });
        this._products = data;
        if (!this._selectedCurrency && data) {
            const sample = data[0];
            const prices = sample.prices;
            if (prices && prices[0]) this._selectedCurrency = prices[0].currency;
        }
    }
}
