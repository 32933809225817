import { ApiUrls } from '@app/AppConstants';
import { isFloatNumber, isGraterThan, isRequired } from '@app/appConstants/Validation';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { BaseFormModel } from '@app/components/BaseFormModel';
import { FormInput, FormSelect } from '@app/components/FormControls';
import { ButtonColor, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@app/components/Modal/Modal';
import { applyDecorators } from '@app/helpers/Decorator';
import { SelectFilterItem } from '@app/models/SelectFilterItem';
import { CurrencyDto, ShortProductInfo } from '@app/models/WebApiModels';
import ApiService from '@app/services/ApiService';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export type AddProductDialogProps = {
    product: ShortProductInfo;
    priceGroup: number;
};

@observer
export default class AddProductDialog extends React.Component<AddProductDialogProps, {}> implements IModalDialogContent<void> {
    private _loader = new PromiseCompletion();
    private _formModel = new FormModel();
    private _window: ModalWindow<void>;

    @observable
    private _currencies: CurrencyDto[] = [];

    constructor(props: AddProductDialogProps) {
        super(props);
        applyDecorators(this);

        void this._loadData();
    }

    getModalOptions = (window: ModalWindow<void>) => {
        this._window = window;

        return {
            title: 'Add product',
            width: '640px',
            loader: this._loader,
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Save,
                    color: 'danger' as ButtonColor,
                    isDisabled: !this._formModel.isFormValid,
                    onClick: () => {
                        this._addProduct();
                    }
                }
            ]
        };
    };

    @action.bound
    private async _loadData() {
        const { data } = await ApiService.getTypedData<CurrencyDto[]>(ApiUrls.CurrencyUrl + '?baseOnly=true', null, { completion: this._loader });
        this._currencies = data;
        this._formModel.currency = this._currencies[0].code;
    }

    private async _addProduct() {
        await ApiService.postData(ApiUrls.PriceUrl + '/group', {
            priceGroup: this.props.priceGroup,
            productCode: this.props.product.code,
            amount: this._formModel.price,
            currency: this._formModel.currency
        });

        this._window.close();
    }

    render() {
        return (
            <Container>
                <Row className="mb-3">{`${this.props.product.code} - ${this.props.product.displayName}`}</Row>
                <Row>
                    <Col sm={2}>Price:</Col>
                    <Col sm={6}>
                        <FormInput formModel={this._formModel} name="price" />
                    </Col>
                    <Col sm={3}>
                        <FormSelect
                            formModel={this._formModel}
                            name="currency"
                            options={this._currencies.map(
                                (x) =>
                                    ({
                                        value: x.code,
                                        label: x.code
                                    } as SelectFilterItem)
                            )}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

class FormModel extends BaseFormModel {
    constructor() {
        super();
        applyDecorators(this);
    }

    @observable
    @isFloatNumber()
    @isRequired()
    @isGraterThan(0)
    price: string;

    @observable
    currency?: string;
}
