import * as React from 'react';
import { AxiosError } from 'axios';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { applyDecorators } from '@app/helpers/Decorator';
import DateTimeService from '@app/services/DateTimeService';
import { ModalWindow, ModalDialogOptions, IModalDialogContent, ModalButtonType } from '../Modal/Modal';

type ErrorDialogProps = {
    error?: string[] | string | AxiosError | JSX.Element;
};

@observer
export class ErrorDialog extends React.Component<ErrorDialogProps> implements IModalDialogContent<void> {
    @observable private _errorData: string | null = null;
    @observable private _props: ErrorDialogProps;

    constructor (props:ErrorDialogProps) {
        super(props);
        applyDecorators(this);

        this._props = props;
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const axiosError = this._props.error as AxiosError;
        const codeString = axiosError.response ? <span className="timeCode">Code: {axiosError.response.status}/ {axiosError.response.statusText}<br/></span> : '';
        return {
            title: this._props.error ? `Request can\'t be completed` : `Error`,
            buttons: [
                {
                    type: ModalButtonType.Ok,
                    color:'light',
                    onClick: () => {
                        window.close();
                    }
                }
            ],
            width: '500px',
            footerPrefix: <span className="timestamp">
                {codeString}
                {DateTimeService.format(DateTimeService.now(), 'YYYY-MM-DD HH:mm:ss')}
            </span>
        };
    }

    async componentDidMount(){
        const { error } = this.props;

        if (!error) return null;
        if (typeof error !== 'object') return null;
        if (Array.isArray(error)) return null;
        if (React.isValidElement(error)) return null;

        const axiosError = error as AxiosError;
        if (!axiosError.response) {
            return null;
        }

        const data = axiosError.response.data;
        if (data instanceof Blob){
            this._errorData = await new Response(data).text();
        } else if (data !== null && typeof data === 'object' && 'error' in data){
            this._errorData = data.error as string;
        } else {
            this._errorData = data as string;
        }
    }

    render() {
        return (
            <>
                {this._renderMessage()}
                {this._renderRequestMessage()}
            </>
        );
    }

    _renderMessage() {
        const { error } = this.props;

        if (typeof error === 'string') {
            return <p>{error}</p>;
        }

        if (Array.isArray(error)) {
            return (
                <>
                    {error.map((mess, index) => (
                        <p key={index}>{mess}</p>
                    ))}
                </>
            );
        }

        if (!React.isValidElement(error)) return null;
        if(typeof error === 'object') return <p>{JSON.stringify(error)}</p>;
        return error;
    }

    _renderRequestMessage() {
        const { error } = this.props;

        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError || !axiosError.response) {
            return null;
        }

        const errorData = this._errorData;

        return (
            <>
                {errorData && <div className="errorBody">{Array.isArray(errorData) ? errorData.join(' ') : typeof errorData === 'object' ? JSON.stringify(errorData) : errorData?.toString()}</div>}
                <div className="errorInfo">URL: {axiosError.config && axiosError.config.method + ' ' + axiosError.config.url}</div>
            </>
        );
    }
}
