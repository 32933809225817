import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import Select, { ActionMeta, components, MultiValue, OptionProps, SingleValue, StylesConfig } from 'react-select';
import { applyDecorators } from '@app/helpers/Decorator';

export type ReactSelectOption = { value: string; label: string; hint?: string };
export type ReactSelectValue = string | null;
export type ReactSelectOptions = ReactSelectOption[] | null;
export type ReactSelectCallback = (value: ReactSelectValue, options: ReactSelectOptions) => void;

export enum ReactSelectSize {
    Regular,
    Small
}

type ReactSelectProps<T> = {
    placeholder?: string;
    value?: ReactSelectValue;
    multi?: boolean;
    isClearable?: boolean;
    closeOnSelect?: boolean;
    isSearchable?: boolean;
    backspaceRemovesValue?: boolean;
    hideSelectedOptions?: boolean;
    tabSelectsValue?: boolean;
    selectMaxSpace?: boolean;
    options: T[];
    onSelect?: ReactSelectCallback;
    selectSize?: ReactSelectSize;
    styles?: StylesConfig<T, boolean>;
    isDisabled?: boolean;
    isLoading?: boolean;
    isOptionDisabled?: (option: T) => boolean;
    className?: string;
    inputValue?: string;
    onMenuOpen?: () => void;
    onInputChange?: (value: string) => void;
};

@observer
export default class ReactSelect<T extends ReactSelectOption> extends React.Component<ReactSelectProps<T>> {
    @observable private _store: ReactSelectStore;

    constructor(props: ReactSelectProps<T>) {
        super(props);
        applyDecorators(this);

        this._store = new ReactSelectStore(this.props.value);
    }

    componentDidUpdate(prevProps: ReactSelectProps<T>) {
        if (prevProps.value !== this.props.value) {
            this._store.updateValue(this.props.value);
        }
    }

    render() {
        const { options, multi, isClearable, closeOnSelect, placeholder, selectSize, isSearchable, backspaceRemovesValue, hideSelectedOptions, styles, isDisabled, className, isOptionDisabled, inputValue, onInputChange, isLoading, tabSelectsValue, selectMaxSpace } = this.props;
        const classList = ['select-filter-container', 'select-filter-single'];
        if (className) classList.push(className);
        if (selectSize === ReactSelectSize.Regular) classList.push('select-regular');
        if (selectMaxSpace) classList.push('select-filter-full');

        const Option = (props: OptionProps<T>) => {
            return (
                <div title={props.data.hint ?? ''}>
                    <components.Option {...props} />
                </div>
            );
        };

        return (
            <Select
                styles={styles}
                isDisabled={isDisabled}
                className={classList.join(' ')}
                classNamePrefix="select-filter"
                placeholder={placeholder}
                closeMenuOnSelect={closeOnSelect || !multi}
                isMulti={!!multi}
                isClearable={!!isClearable}
                hideSelectedOptions={!!hideSelectedOptions}
                backspaceRemovesValue={!!backspaceRemovesValue}
                isSearchable={!!isSearchable}
                onChange={(selected, action) => this._selectHandler(selected, action)}
                onMenuClose={() => this._closeHandler()}
                onMenuOpen={() => this._openHandler()}
                options={options}
                isLoading={isLoading}
                tabSelectsValue={tabSelectsValue}
                isOptionDisabled={isOptionDisabled}
                value={this._selectedOption}
                components={{ Option }}
                onInputChange={onInputChange}
                inputValue={inputValue}
            />
        );
    }

    @action.bound
    private _selectHandler(selected: SingleValue<T> | MultiValue<T>, { action }: ActionMeta<T>) {
        const { onSelect, multi } = this.props;
        let newValue: null | string = null;
        if (selected) {
            newValue = Array.isArray(selected) ? selected.map((el) => el.value).join(',') : (selected as T).value;
        }
        this._store.updateValue(newValue);
        const isClearAction = action === 'clear' || action === 'remove-value';
        if (!multi || isClearAction) {
            onSelect && onSelect(newValue, this._selectedOption);
        }
    }

    @action.bound
    private _closeHandler() {
        const { multi, onSelect } = this.props;
        const { currentValue, defaultValue, updateDefaultValue } = this._store;
        if (!multi) return;
        if (currentValue && currentValue !== defaultValue) {
            onSelect && onSelect(currentValue, this._selectedOption);
        } else if (!currentValue && defaultValue) {
            onSelect && onSelect(null, null);
        }
        updateDefaultValue(null);
    }

    @action.bound
    private _openHandler() {
        const { multi, value, onMenuOpen } = this.props;
        const { updateDefaultValue } = this._store;
        if (multi) {
            updateDefaultValue(value);
        }
        onMenuOpen && onMenuOpen();
    }

    private get _selectedOption() {
        const { currentValue } = this._store;
        const values = (currentValue && currentValue.split(',')) || [];
        const options = this.props.options.filter((el) => values.includes(el.value));
        return options.length ? options : null;
    }

    public static GenerateOptionsFrom<T>(obj: T[], valKey?: keyof T, labKey?: keyof T): ReactSelectOption[] {
        if (obj.length && typeof obj[0] === 'string') {
            return obj.map((e) => {
                const value = e as unknown as string;
                return { value, label: value };
            });
        } else {
            if (!valKey || !labKey) return [];
            return obj.map((e) => {
                const value = e[valKey] as unknown as string;
                const label = e[labKey] as unknown as string;
                return { value, label };
            });
        }
    }
}

class ReactSelectStore {
    @observable currentValue: ReactSelectValue = null;
    @observable defaultValue: ReactSelectValue = null;

    constructor(value?: ReactSelectValue) {
        applyDecorators(this);
        this.updateValue(value);
    }

    @action.bound
    updateValue(value?: ReactSelectValue) {
        if (this.currentValue !== value) {
            this.currentValue = value || null;
        }
    }

    @action.bound
    updateDefaultValue(value?: ReactSelectValue) {
        this.defaultValue = value || null;
    }
}
