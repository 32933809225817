import { JSX, PropsWithChildren } from 'react';
import cls from './_adminLayout.module.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { List, ListItem } from '../List/List';
import { PromiseCompletion } from '@app/classes/PromiseCompletion';
import { AppLoader } from '..';
import { Loading } from '../Loading';

type AdminLayoutProps = {
    list?: ListItem[];
    onItemSelect?: (selectedId: string) => void;
    title?: string | JSX.Element;
    titleActions?: JSX.Element;
    dataLoader?: PromiseCompletion;
    pageLoader?: PromiseCompletion;
    centerMode?: boolean;
};

@observer
export class AdminLayout extends React.Component<PropsWithChildren & AdminLayoutProps, {}> {
    render(): React.ReactNode {
        const { list, title, onItemSelect, children, dataLoader, pageLoader, titleActions, centerMode } = this.props;
        const contentClass = [cls.content];
        centerMode && contentClass.push(cls.middle);
        return (
            <div className={cls.root}>
                <Loading loading={pageLoader?.isPending} />
                <div className={cls.sidebar} hidden={!list?.length}>
                    {list && <List items={list} onSelect={onItemSelect} />}
                </div>
                <div className={cls.body}>
                    <div className={cls.wrapper}>
                        <div className={contentClass.join(' ')}>
                            {(title || titleActions) && (
                                <div className={cls.subTitle}>
                                    <div className={cls.title}>{title}</div>
                                    <div className={cls.actions}>{titleActions}</div>
                                </div>
                            )}
                            {children}
                        </div>
                        <Loading loading={dataLoader?.isPending} />
                    </div>
                </div>
            </div>
        );
    }
}
