import {CellData, CellInfo, DataGridColumnContentAlignment, DataGridColumnDisplayMode, DataGridColumnFieldType, HeaderCell, HeaderFilterPostProcessOptions, HeaderFilterPredefinedOption, SearchFilterOperation} from './DxDataGridTypes';

export enum DataGridColumnType {
    Data,
    Dynamic,
    Band
}

export class DataGridColumnDescriptor<T extends {}> {
    caption: string;
    tooltip?: string;
    dataFieldName: keyof T;
    dataFieldType?: DataGridColumnFieldType;
    allowGrouping?: boolean;
    allowSearch?: boolean;
    allowedSearchFilterOperations?: SearchFilterOperation[];
    allowDropdownFiltering?: boolean;
    allowSearchFiltering?: boolean;
    allowHiding?: boolean;
    allowSorting?: boolean;
    allowResizing?: boolean;
    contentAlignment?: DataGridColumnContentAlignment;
    customizeCellText?: (cellInfo: CellData) => string;
    cellRenderTemplate?: (props: CellInfo<T>) => React.ReactNode;
    headerCellRender?: (props: HeaderCell<T>) => React.ReactNode;
    customizeHeaderFilterOptions?: (options: HeaderFilterPostProcessOptions) => void;
    groupInterval?: string;
    calculateFilterExpression?: (filterValue: unknown, selectedFilterOperation: string, target: string) => string[][];
    isHidden?: boolean;
    isExcelHours?: boolean;
    columns?: DataGridColumn<T>[];
    width?: number;
    customCssClass?: string;
    printWidth?: number;
    displayMode?: DataGridColumnDisplayMode[];
    enableCultureSpecificSorting?: boolean;
    defaultHeaderFilterOption?: HeaderFilterPredefinedOption;
    groupIndex?: number;
    groupCellTemplate?: (element: HTMLElement, cell: CellInfo<T>) => void;
    sortOrder?: 'desc';
}

export class DataGridColumn<T extends {}> {
    caption: string;
    tooltip?: string;
    dataFieldName: keyof T;
    dataFieldType?: DataGridColumnFieldType;
    allowGrouping: boolean;
    allowSearch: boolean;
    allowDropdownFiltering: boolean;
    allowSearchFiltering?: boolean;
    allowedSearchFilterOperations?: SearchFilterOperation[];
    allowHiding: boolean;
    allowSorting: boolean;
    allowResizing: boolean;
    customCssClass?: string;
    contentAlignment?: DataGridColumnContentAlignment;
    customizeCellText?: (cellInfo: CellData) => string;
    cellRenderTemplate?: (props: CellInfo<T>) => React.ReactNode;
    headerCellRender?: (props: HeaderCell<T>) => React.ReactNode;
    headerFilterOptions?: (options: HeaderFilterPostProcessOptions) => void;
    groupInterval?: string;
    calculateFilterExpression?: (filterValue: unknown, selectedFilterOperation: string, target: string) => string[][];
    isHidden?: boolean;
    columns?: DataGridColumn<T>[];
    defaultWidth?: number;
    width?: number;
    printWidth?: number;
    displayMode?: Set<DataGridColumnDisplayMode>;
    enableCultureSpecificSorting?: boolean;
    columnType: DataGridColumnType;
    defaultHeaderFilterOption?: HeaderFilterPredefinedOption;
    name?: string;
    groupIndex?: number;
    groupCellTemplate?: (element: HTMLElement, cell: CellInfo<T>) => void;
    sortOrder?: 'desc';

    constructor(props: DataGridColumnDescriptor<T>) {
        this.caption = props.caption;
        this.tooltip = props.tooltip;
        this.dataFieldName = props.dataFieldName;
        this.dataFieldType = typeof props.dataFieldType === 'undefined' ? DataGridColumnFieldType.string : props.dataFieldType;
        this.allowGrouping = typeof props.allowGrouping === 'undefined' ? true : props.allowGrouping;
        this.allowSearch = typeof props.allowSearch === 'undefined' ? true : props.allowSearch;
        this.allowedSearchFilterOperations = props.allowedSearchFilterOperations;
        this.allowDropdownFiltering = typeof props.allowDropdownFiltering === 'undefined' ? true : props.allowDropdownFiltering;
        this.allowSearchFiltering = typeof props.allowSearchFiltering === 'undefined' ? true : props.allowSearchFiltering;
        this.allowHiding = typeof props.allowHiding === 'undefined' ? true : props.allowHiding;
        this.allowSorting = typeof props.allowSorting === 'undefined' ? true : props.allowSorting;
        this.allowResizing = typeof props.allowResizing === 'undefined' ? true : props.allowResizing;
        this.customCssClass = props.customCssClass;
        this.contentAlignment = typeof props.contentAlignment === 'undefined' ? DataGridColumnContentAlignment.left : props.contentAlignment;
        this.isHidden = typeof props.isHidden === 'undefined' ? false : props.isHidden;
        this.customizeCellText = props.customizeCellText;
        this.cellRenderTemplate = props.cellRenderTemplate;
        this.headerCellRender = props.headerCellRender;
        this.headerFilterOptions = props.customizeHeaderFilterOptions;
        this.groupInterval = props.groupInterval;
        this.calculateFilterExpression = props.calculateFilterExpression;
        this.columns = props.columns;
        this.width = props.width;
        this.defaultWidth = props.width;
        this.printWidth = props.printWidth;
        this.displayMode = new Set(props.displayMode ? props.displayMode : [DataGridColumnDisplayMode.UI, DataGridColumnDisplayMode.Print, DataGridColumnDisplayMode.Export]);
        this.enableCultureSpecificSorting = props.enableCultureSpecificSorting;
        this.columnType = DataGridColumnType.Data;
        this.defaultHeaderFilterOption = props.defaultHeaderFilterOption;
        this.groupIndex = props.groupIndex;
        this.groupCellTemplate = props.groupCellTemplate;
        this.sortOrder = props.sortOrder;
    }
}

export class DataGridDynamicColumn<T extends {}> extends DataGridColumn<T> {
    constructor(props: Omit<DataGridColumnDescriptor<T>, 'dataFieldName'> & { dataFieldName?: string }) {
        super(props as DataGridColumnDescriptor<T>);
        this.columnType = DataGridColumnType.Dynamic;
    }
}

export class DataGridBandColumn<T extends {}> extends DataGridColumn<T> {
    constructor(props: Omit<DataGridColumnDescriptor<T>, 'dataFieldName' | 'dataFieldType'>) {
        super(props as DataGridColumnDescriptor<T>);
        this.columnType = DataGridColumnType.Band;
        this.contentAlignment = typeof props.contentAlignment === 'undefined' ? DataGridColumnContentAlignment.center : props.contentAlignment;
    }
}
