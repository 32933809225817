import React from 'react';
import { observer } from 'mobx-react';

import { loaderStore } from '@app/stores/LoaderStore';

import cls from './_appLoader.module.scss';

type AppLoaderProps = {
    showLoader?: boolean;
    className?: string;
};

@observer
export class AppLoader extends React.Component<AppLoaderProps, {}> {
    render(): React.ReactNode {
        const { showLoader, className } = this.props;
        if (loaderStore.appLoader.isPending || loaderStore.processLoader.isPending || showLoader) {
            const classList = ['app-ldr-nd'];
            className && classList.push(className);
            loaderStore.processLoader.isPending && classList.push(cls.process);
            return (
                <div className={classList.join(' ')}>
                    <div className="app-ldr">
                        <div className="app-crl app-crl-1"></div>
                        <div className="app-crl app-crl-2"></div>
                        <div className="app-crl app-crl-3"></div>
                    </div>
                </div>
            );
        }
        return null;
    }
}
