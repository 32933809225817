import React from 'react';
import { JSX } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { applyDecorators } from '@app/helpers/Decorator';
import { LocalStorageGet, LocalStorageSet } from '@app/helpers/Helpers';
import { StoredKeys } from '@app/AppConstants';

import cls from './_tabs.module.scss'
import { AzureAuth } from '@app/classes/AzureAuth';

export type TabItem = {
    title: string,
    body: JSX.Element,
    order: number
}
type TabsProps = {
    tabs: TabItem[]
}

@observer
export class Tabs extends React.Component<TabsProps, {}> {
    @observable
    private _activeIndex: number = 0;

    constructor(props: TabsProps) {
        super(props);
        applyDecorators(this);
    }

    render(): React.ReactNode {
        
        const { tabs } = this.props;

        return (
            <div className={cls.root}>
                <div className={cls.nav}>
                    {tabs.map(({ title }, index) => {
                        return <div className={`${cls.item} ${index === this._activeIndex ? cls.active : ''}`} onClick={this._onTabClick(index)} key={index}>{title}</div>
                    })}
                    <div className={cls.out}>
                        <FontAwesomeIcon onClick={this._logOut} icon={faArrowRightFromBracket} />
                    </div>
                </div>
                <div className={cls.body}>
                    {tabs[this._activeIndex]?.body}
                </div>
            </div>
        )
    }

    componentDidMount(): void {
        this._activeIndex = LocalStorageGet<number>(StoredKeys.ActiveTab) ?? 0;
    }

    private _logOut() {
        AzureAuth.logOut();
    }

    @action.bound
    private _onTabClick = (index: number) => () => {
        this._activeIndex = index;
        LocalStorageSet(StoredKeys.ActiveTab, index)
    }
}


